import { getEnumName, capitalizeWord } from "../modules/util";
import State from "./State";
import { ExecutionType, Side, OrderType, OrderState, OrdRejectReason } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";

import ProtobufParser from "../modules/protobufParser";

export default class Execution {
  constructor(item) {
    if (item) {
      const executionObj = item.toObject();
      const orderObj = executionObj.order;

      this.id = executionObj.id;

      this.orderId = orderObj.id;
      this.orderType = new State({ id: orderObj.type, name: capitalizeWord(getEnumName(OrderType, orderObj.type)) });

      let side = new State({ id: orderObj.side, name: capitalizeWord(getEnumName(Side, orderObj.side)) });
      this.side = side;

      this.orderQty = orderObj.orderQty;
      this.symbol = orderObj.symbol;
      this.clordId = orderObj.clordId;
      this.timeInForce = orderObj.timeInForce;
      this.account = orderObj.account;
      this.cumQty = orderObj.cumQty;
      this.avgPx = orderObj.avgPx;
      this.leavesQty = orderObj.leavesQty;
      this.state = new State({ id: orderObj.side, name: capitalizeWord(getEnumName(OrderState, orderObj.state)) });
      this.participant = orderObj.participant;
      this.orderPx = orderObj.price;
      this.insertTime = orderObj.insertTime;
      this.stopPx = orderObj.stopPrice;
      this.minQty = orderObj.minQty;
      this.createTime = orderObj.createTime;
      this.allOrNone = orderObj.allOrNone;
      this.blockTradeIndicator = orderObj.blockTradeIndicator;

      this.crossId = orderObj.crossId;
      this.hostCrossId = orderObj.hostCrossId;
      this.submittingParticipant = orderObj.submittingParticipant;
      this.clientAccountId = orderObj.clientAccountId;
      this.clientParticipantId = orderObj.clientParticipantId;
      this.parentOrderId = orderObj.parentOrderId;
      this.symbolSubType = orderObj.symbolSubType;

      this.lastShares = executionObj.lastShares;
      this.lastPx = executionObj.lastPx;

      let execType = new State({ id: executionObj.type, name: capitalizeWord(getEnumName(ExecutionType, executionObj.type)) });
      this.execType = execType;

      this.isFill =
        execType.id === ExecutionType.EXECUTION_TYPE_FILL ||
        execType.id === ExecutionType.EXECUTION_TYPE_PARTIAL_FILL;

      this.isReject = execType.id === ExecutionType.EXECUTION_TYPE_REJECTED;

      this.text = executionObj.text;
      this.orderRejectReason = new State({ id: executionObj.orderRejectReason, name: capitalizeWord(getEnumName(OrdRejectReason, executionObj.orderRejectReason)) });
      this.transactTime = ProtobufParser.toDate(item.getTransactTime());

      return;
    }

    this.id = null;
    this.orderId = null;
    this.side = null;
    this.isFill = false;
    this.isReject = false;
    this.symbol = null;
    this.account = null;
    this.transactTime = null;
    this.cumQty = null;
    this.leavesQty = null;
    this.lastShares = null;
    this.lastPx = null;
    this.orderPx = null;
    this.stopPx = null;
    this.text = null;
    this.allOrNone = null;
    this.blockTradeIndicator = null;
  }
}
