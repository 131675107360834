import React from "react";
import { connect } from "react-redux";
import Dialog from "../core/dialog/Dialog";
import { EnhancedRFQOrderTicketForm } from "./forms/RequestForQuoteOrderForm";
import EnhancedQuoteOrderTicketForm from "./forms/QuoteOrderForm";
import EnhancedQuoteResponseTicketForm from "./forms/QuoteResponseOrderForm";
import QuoteService from "../../services/QuoteService";
import { SetViewContext } from "../../actions/app";
import OrderService from "../../services/OrderService";
import Notification from "../../modules/notifications";

const mapStateToProps = (state) => {
  return {
    accounts: state.trader.accounts,
    users: state.trader.users,
    usersToAccounts: state.trader.usersToAccounts,
    firms: state.trader.firms,
    firmsToUsers: state.trader.firmsToUsers,
    trader: state.trader.trader,
    dealers: state.quotes.dealers,
    requesters: state.quotes.requesters,
    symbol: state.quotes.appContext.symbol,
    context: state.quotes.appContext.context,
    viewQuoteTicket: state.quotes.appContext.viewQuoteTicket,
    instruments: state.trader.instruments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setViewContext: (payload) => {
    dispatch(SetViewContext(payload));
  },
});

const QuoteTicket = ({
  accounts,
  users,
  usersToAccounts,
  firms,
  firmsToUsers,
  dealers,
  trader,
  symbol,
  context,
  viewQuoteTicket,
  setViewContext,
  instruments,
}) => {
  switch (context) {
    case "instruments":
      return (
        <Dialog
          title="New Request For Quote"
          show={viewQuoteTicket}
          onClose={() => setViewContext({ viewQuoteTicket: false })}
        >
          <EnhancedRFQOrderTicketForm
            instruments={instruments}
            symbol={symbol}
            accounts={accounts}
            users={users}
            usersToAccounts={usersToAccounts}
            firms={firms}
            firmsToUsers={firmsToUsers}
            dealers={dealers}
            trader={trader}
            onCancel={() => setViewContext({ viewQuoteTicket: false })}
            doSubmit={(values) => {
              QuoteService.createRequestForQuote(values);
              setViewContext({ viewQuoteTicket: false });
            }}
          />
        </Dialog>
      );
    case "requestForQuotes":
      return (
        <Dialog
          title="New Quote"
          show={viewQuoteTicket}
          onClose={() => setViewContext({ viewQuoteTicket: false })}
        >
          <EnhancedQuoteOrderTicketForm
            onCancel={() => setViewContext({ viewQuoteTicket: false })}
            doSubmit={(values) => {
              QuoteService.createQuote(values);
              setViewContext({ viewQuoteTicket: false });
            }}
          />
        </Dialog>
      );
    case "quotes":
      return (
        <Dialog
          title="Respond to Quote"
          show={viewQuoteTicket}
          onClose={() => setViewContext({ viewQuoteTicket: false })}
        >
          <EnhancedQuoteResponseTicketForm
            doSubmit={(values) => {
              const cb = (err, response) => {
                if (response) {
                  Notification.info('Response submitted');
                }

                if (err) {
                  Notification.error(
                    `Error when submitting order: \n ${err.message}`
                  );
                }
              };
              OrderService.submitNewOrder(cb, symbol, values);
              setViewContext({ viewQuoteTicket: false });
            }}
            onPass={(quote) => {
              QuoteService.passQuote(quote);
              setViewContext({ viewQuoteTicket: false });
            }}
            onDelete={(quote) => {
              QuoteService.deleteQuote(quote);
              setViewContext({ viewQuoteTicket: false });
            }}
            accounts={accounts}
            users={users}
            usersToAccounts={usersToAccounts}
            instrument={instruments[symbol]}
            instruments={instruments}
            symbol={symbol}
          />
        </Dialog>
      );
    default:
      return <></>;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteTicket);
