import { QuoteReject as ApiQuoteReject } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/api_pb";

export default class QuoteReject {
  static get(item) {
    const object = item.toObject();
    return {
      rejectedItem: Object.keys(ApiQuoteReject.ParentCase).find(
        (key) => ApiQuoteReject.ParentCase[key] === object.rejectReason
      ),
      symbol: object.symbol,
      text: object.text,
      requestForQuote: object.requestForQuote,
      quote: object.quote,
      quoteAccept: object.quoteAccept,
      quoteCancel: object.quoteCancel,
      quotePass: object.quotePass,
    };
  }
}
