import { StatusCode } from "grpc-web";

import OrderService from "../services/OrderService";
import Notification from "../modules/notifications";
import {
  UPDATE_WORKING_ORDERS,
  UPDATE_TRADER,
  UPDATE_SESSION_ID,
} from "../constants/actions";
import Order from "../entities/Order";
import { ListInstrumentsForSymbols } from "../actions/instruments";

export function subscribeOrders(cbOrd, cbExec, cbRej) {
  return (dispatch, getState) => {
    const onData = function (response) {
      const sessionId = response.getSessionId();
      if (sessionId) {
        dispatch({
          type: UPDATE_SESSION_ID,
          payload: sessionId,
        });
      }

      const state = getState();
      const pendingSymbols = [];
      const collectPendingSymbols = (orders, instruments) => {
        orders.forEach((order) => {
          const symbol = order.toObject().symbol;
          if (!instruments[symbol]) {
            pendingSymbols.push(symbol);
          }
        });
      };

      if (response.hasSnapshot()) {
        const snapshot = response.getSnapshot().getOrdersList();
        collectPendingSymbols(snapshot, state.trader.instruments);

        dispatch(updateWorkingOrders(snapshot));
        if (cbOrd) cbOrd(snapshot);
      }

      if (response.hasUpdate()) {
        const executions = response.getUpdate().getExecutionsList();
        const orders = executions.map((exec) => exec.getOrder());

        collectPendingSymbols(orders, state.trader.instruments);
        dispatch(updateWorkingOrders(orders));

        if (cbOrd) cbOrd(orders);
        if (cbExec) {
          executions.forEach((exec) => {
            cbExec(exec);
          });
        }

        if (response.getUpdate().hasCancelReject()) {
          let rej = response.getUpdate().getCancelReject();
          Notification.error(
            `Submission Rejected: ${rej.getText()}`
          );
          if (cbRej) cbRej(rej);
        }
      }

      if (pendingSymbols && pendingSymbols.length > 0) {
        dispatch(ListInstrumentsForSymbols(pendingSymbols));
      }
    };

    const onStatus = function (status) {
      if (status.details === "context canceled") {
        dispatch(subscribeOrders(cbOrd, cbExec, cbRej));
        return;
      }
      switch (status.code) {
        case StatusCode.OK:
          break;
        case StatusCode.UNAUTHENTICATED:
          break;
        default:
          Notification.error(`Cannot subscribe to orders: ${status}`);
          break;
      }
    };

    const onEnd = function (end) {
      dispatch(subscribeOrders(cbOrd, cbExec, cbRej));
    };

    return OrderService.subscribe(onData, onStatus, onStatus, onEnd);
  };
}

export function updateWorkingOrders(orders) {
  const ordItems = orders.map((order) => new Order(order));
  return {
    type: UPDATE_WORKING_ORDERS,
    orders: ordItems,
  };
}

export function GetWhoAmI() {
  return (dispatch) => {
    OrderService.getWhoAmI((err, response) => {
      if (response) {
        const trader = {
          user: response.getUser(),
          userDisplayName: response.getUserDisplayName(),
          firm: response.getFirm(),
          firmDisplayName: response.getFirmDisplayName(),
          audience: response.getAudience(),
        };
        dispatch({
          type: UPDATE_TRADER,
          payload: trader,
        });
      }

      if (err) {
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning(
              "Could not find any accounts associated with this participant"
            );
            break;
          default:
            Notification.error(
              `Error when querying accounts: \n ${err.message}`
            );
            break;
        }
      }
    });
  };
}

export function cancelAllOrders() {
  return (dispatch, getState) => {
    const cb = (err, response) => {
      if (err) {
        Notification.error(`Error when submitting cancel: \n ${err.message}`);
      }
    };
    let state = getState();
    let workingOrders = Object.values(state.trader.workingOrders);
    Notification.info("Cancelling all working orders!");
    workingOrders.forEach((order) => {
      if (Order.isOpen(order.status.state.name))
        OrderService.submitCancelOrder(cb, order.symbol, order);
    });
  };
}
