import {isNullOrUndefined} from "../modules/util";

export const Env = {

  getLogoLoginPage: () => {
    let logoUrl = "/4-color-icon.png";
    let logoConfigUrl = Env.getEnv("REACT_APP_LOGO_LOGIN_PAGE");

    if (logoConfigUrl && logoConfigUrl.length > 0) {
      logoUrl = logoConfigUrl;
    }

    return logoUrl;
  },

  getLogoMainApp: () => {
    let logoUrl = "/4-color-icon.png";
    let logoConfigUrl = Env.getEnv("REACT_APP_LOGO_APP");

    if (logoConfigUrl && logoConfigUrl.length > 0) {
      logoUrl = logoConfigUrl;
    }

    return logoUrl;
  },

  getEnv: (variableName) => {
    const fromEnv = process.env[variableName];
    if (!!fromEnv && !isNullOrUndefined(fromEnv)) {
      return fromEnv.toString().trim();
    }
    const fromWindow = window[variableName];
    const fromWindowVal = !!fromWindow && !isNullOrUndefined(fromWindow) ? fromWindow.toString().trim() : "";
    return fromWindowVal === "__" + variableName + "__" ? "" : fromWindowVal;
  },

  getEnvBool: (variableName) => {
    const envVal = Env.getEnv(variableName);
    return !!envVal && envVal.toString().toLowerCase() === "true";
  }
}
