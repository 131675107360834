import PositionService from '../services/PositionService';
import { SET_AUTHORIZATION, UPDATE_POSITIONS } from '../constants/actions';
import { StatusCode } from "grpc-web";
import Notification from "../modules/notifications";
import { store } from '../store';

export function subscribePositions(cb) {
    const onData = function (response) {
        if (response.hasHeartbeat()) {
            return
        }

        if (response.hasSnapshot()) {
            let snap = response.getSnapshot()
            //drop the delimiter
            if (snap.getPositionsList().length !== 0) {
                cb(snap)
            }
            return
        }

        cb(response.getUpdate())
    }

    const onStatus = function (status) {
        if (status.details === "context canceled") {
            subscribePositions(cb);
            return
        }
        switch (status.code) {
            case StatusCode.OK:
                break;
            case StatusCode.UNAUTHENTICATED:
                break;
            case StatusCode.PERMISSION_DENIED:
                store.dispatch({
                    type: SET_AUTHORIZATION,
                    payload: { isPositionAuthorized: false, positionMessage: status.message },
                });
                break;
            default:
                Notification.error(`Positions subscription status: ${!!status.message ? status.message : status}`);
                break;
        }
    }

    const onEnd = (end) => {
        subscribePositions(cb);
    }

    return PositionService.subscribe(
        onData,
        onStatus,
        onStatus,
        onEnd,
    );
}

export function updatePositions(pbPositionsList) {
    const positions = pbPositionsList.map(p => {
        return {
            account: p.getAccount(),
            symbol: p.getSymbol(),
            netPosition: p.getNetPosition(),
            cost: p.getCost(),
            realized: p.getRealized(),
            qtyBought: p.getQtyBought(),
            qtySold: p.getQtySold(),
            bod: p.getBodPosition(),
            symbolSubType: p.getSymbolSubType()
        }
    })

    return {
        type: UPDATE_POSITIONS,
        positions: positions
    }
}