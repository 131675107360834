import { SHOW_WATCHLIST, HIDE_WATCHLIST, UPDATE_WATCHLIST, ADD_WATCHLIST_INSTRUMENTS, SET_PAGE_SIZE } from "../constants/actions";

export function showWatchList() {
  return {
    type: SHOW_WATCHLIST,
  };
}

export function hideWatchList() {
  return {
    type: HIDE_WATCHLIST,
  };
}

export function updateWatchList(symbols) {
  return {
    type: UPDATE_WATCHLIST,
    payload: symbols
  }
}
export function addAllInstruments(instruments) {
  return {
    type: ADD_WATCHLIST_INSTRUMENTS,
    payload: instruments
  }
}

export function setCurrentPageSize(size) {
  return {
    type: SET_PAGE_SIZE,
    payload: size
  }
}
