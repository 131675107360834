import { store } from "../store";
import ProcessAPIResponse from "./processAPIResponse";
import { getAccessToken, getRefreshToken } from "../services/TokenService";
import { replace } from "connected-react-router";
import { ACCESS_TKN_REFRESH_SUCCESS, LOGIN_RESET } from "../constants/actions";
import Notification from "./notifications";
import { StatusCode } from "grpc-web";
import jwt_decode from 'jwt-decode';
import {apiUrl} from "./util";

const {
  RefreshAccessTokenRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb.js");

const {
  BasicAuthAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb.js");

const auth_client = new BasicAuthAPIClient(
  apiUrl(),
  null,
  null
);

export const apiCall = (client, name, request, cb, subscriptionHooks = null) => {
  const setSubscriptionHooks = (call) => {
    if (!subscriptionHooks) {
      return;
    }
    call.on("data", subscriptionHooks.data);
    call.on("status", subscriptionHooks.status);
    call.on("end", subscriptionHooks.end);
    call.on("error", subscriptionHooks.error);
  };

  const performCall = () => {
    const call = client[name](
      request,
      { authorization: getAccessToken() },
      (err, response) => {
        if (err && err.code === StatusCode.UNAUTHENTICATED) {
          //This is getting triggered twice??
          refreshTokens().then(() => {
            setSubscriptionHooks(client[name](
              request,
              { authorization: getAccessToken() },
              (err, response) => {
                ProcessAPIResponse(err, response, cb);
              }
            ));
          });
        } else {
          ProcessAPIResponse(err, response, cb);
        }
      }
    );

    setSubscriptionHooks(call);
    return call;
  }

  const accTokenStr = getAccessToken()

  if (!accTokenStr){
    console.log("Access token undefined.");
    return;
  }

  const tokenDecoded = jwt_decode(accTokenStr);
  if (Date.now() >= (tokenDecoded.exp ? tokenDecoded.exp * 1000 : 0)) {
    refreshTokens().then(() => {
      performCall();
    });
    // TODO: Device a better logic.
    // There are components who need this variable to cancel a subscriptions
    return null;
  } else {
    return performCall();
  }
};

export const apiCallPromise = (client, name, request, toast) => {
  client[name](request, { authorization: getAccessToken() })
    .catch((err) => {
      switch (err.code) {
        case StatusCode.UNAUTHENTICATED:
          refreshTokens().then(() =>
            apiCallPromise(client, name, request, toast)
          );
          break;

        case StatusCode.UNAVAILABLE:
          Notification.error(
            "Cannot perform this operation. Please try again in a few minutes."
          );
          break;
        default:
          const StatusCodeReason = Object.keys(StatusCode).find(
            (key) => StatusCode[key] === err.code
          );
          Notification.error(`${StatusCodeReason} Error: ${err.message}`);
          break;
      }
    })
    .then((response) => {
      if (response) {
        Notification.success(toast);
      }
    });
};

export const refreshTokens = () => {
  return new Promise((resolve, reject) => {
    const refreshReq = new RefreshAccessTokenRequest();
    refreshReq.setRefreshToken(getRefreshToken());

    auth_client.refreshAccessToken(refreshReq, {}, (err, response) => {
      if (response) {
        var accToken = response.getAccessToken();
        store.dispatch({
          type: ACCESS_TKN_REFRESH_SUCCESS,
          payload: { accToken },
        });
        resolve();
      } else {
        store.dispatch({ type: LOGIN_RESET });
        store.dispatch(replace("/"));
        Notification.error(
          "Your credentials have expired. You have been logged out."
        );
        reject();
      }
    });
  });
};
