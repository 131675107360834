import React from "react";
import Dialog from "./core/dialog/Dialog";
import { hideDownloadTrades, downloadTrades } from "../actions/downloadTrades";
import { connect } from "react-redux";
import DateGroup from "./core/form/DateGroup";
import { Row, Container } from "react-bootstrap";
import { withFormik } from "formik";
import Notification from "../modules/notifications";
import "../App.css";
import moment from "moment-timezone";

const DownloadTrades = ({
  setFieldValue,
  values,
  onClose,
  handleSubmit,
  errors,
}) => {
  return (
    <Dialog
      title={"Download Reports"}
      show={true}
      onClose={onClose}
      dialogClassName="downloadTradesDialog"
    >
      <div className="m-3">
        <Container>
          <Row>
            <select
                id="action"
                name="action"
                placeholder="Action"
                className="form-control"
                value={values.action}
                onChange={e => {
                  setFieldValue("action", e.target.value);
                }}
            >
              <option key='trades' value='trades'>Trades</option>
              <option key='orders' value='orders'>Orders</option>
              <option key='executions' value='executions'>Executions</option>
            </select>
            <DateGroup
              id="fromDate"
              name="fromDate"
              label="From"
              isRequired={false}
              onChange={(e) => {
                setFieldValue("fromDate", e);
              }}
              value={values.fromDate}
              enableTime={true}
            />
            <DateGroup
              id="toDate"
              name="toDate"
              label="To"
              isRequired={false}
              onChange={(e) => {
                setFieldValue("toDate", e);
              }}
              value={values.toDate}
              enableTime={true}
            />
          </Row>
          <Row className="download-report-buttons">
            <div>
              <div>
                <button
                  type="submit"
                  className={"btn btn-primary btn-side btn-buy download-btn "}
                  onClick={handleSubmit}>Download
                </button>
                <span>&nbsp;</span>
                <button
                  className={"btn btn-primary btn-side btn-sell"}
                  onClick={onClose}>Cancel
                </button>
              </div>
            </div>
          </Row>
          <Row className="download-report-error">
            <div className="">
              {errors.fromDate ? (
                <label className="form-input-error username-error">
                  <i
                    style={{ fontSize: "2em" }}
                  />
                  {errors.fromDate}
                </label>
              ) : null}
            </div>

          </Row>
        </Container>

      </div>
    </Dialog>
  );
};

export const DownloadTradeWithFormik = withFormik({

  mapPropsToValues: (props) => {
    const metadata = props.metadata;
    const timezone = metadata && metadata.location ? metadata.location : "UTC";
    const nowMoment = moment.tz(new Date(), timezone);
    let convertedDate = nowMoment.toDate();
    return {
      action: 'trades',
      fromDate: new Date(convertedDate.setHours(0, 0, 0, 0)),
      toDate: new Date(convertedDate.setHours(23, 59, 59, 999)),
    };
  },
  handleSubmit: (values, { props }) => {
    props.startDownload(values.action, values.fromDate, values.toDate);
    Notification.info("Downloading " + values.action);
    props.onClose();
  },
  validate: (values) => {
    let errors = {};
    if (values.fromDate > values.toDate) {
      errors.fromDate = "From date should be less than To Date.";
    }

    return errors;
  },
  displayName: "Download report",
})(DownloadTrades);

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideDownloadTrades());
  },
  startDownload: (action, fromDate, toDate) => {
    dispatch(downloadTrades(action, fromDate, toDate));
  },
});

const mapStateToProps = (state) => {
  return {
    metadata: state.quotes.metadata,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadTradeWithFormik);
