import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import 'react-table/react-table.css';
import './DataGrid.css';

import _ from 'lodash';

class DataGrid extends Component {
  getTrProps = (state, rowInfo) => {
    return {
      style: {}
    };
  };

  constructor(props) {
    super(props);
    this.lastData = [];
  }


  render() {
    return (
      <ReactTable
        data={this.props.data}
        parent={this}
        filterable={this.props.filterable}
        defaultFilterMethod={(filter, row) => _.includes(String(row[filter.id]).toLowerCase(), String(filter.value).toLowerCase())}
        sortable={this.props.sortable}
        minRows={0}
        columns={this.props.columns}
        showPagination={this.props.showPagination}
        showPageSizeOptions={false}
        showPageJump={false}
        getTrProps={this.props.getTrProps ? this.props.getTrProps : this.getTrProps}
        getTdProps={this.props.getTdProps ? this.props.getTdProps : this.getTrProps}
        defaultPageSize={this.props.defaultPageSize}
        defaultSorted={[
          {
            id: `${this.props.defaultSortedField}`,
            desc: this.props.defaultSortedDesc
          }
        ]}
        noDataText={this.props.noDataText ? this.props.noDataText : 'No rows found'}
      >
        {this.props.children}
      </ReactTable>
    )
  }
}

DataGrid.defaultProps = {
  showPagination: true,
  sortable: true,
  defaultSortedDesc: false,
  defaultSortedField: 'id'
}

DataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  filterable: PropTypes.bool.isRequired,
  sortable: PropTypes.bool,
  defaultSortedDesc: PropTypes.bool,
  defaultSortedField: PropTypes.string,
  onRowClick: PropTypes.func
}

export default DataGrid;