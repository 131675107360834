import { push, replace } from "connected-react-router";
import { apiUrl, isOnline, showError } from "../modules/util";
import Notification from "../modules/notifications";

import {
  LOGIN_START,
  LOGIN_END,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_RESET,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ALL_NOTIFICATION,
  SET_AUTH_TOKENS,
} from "../constants/actions";
import { refreshTokens } from "../modules/apiCall";
import {Env} from "../constants/environment";

const {
  LoginRequest,
  RegisterRequest,
  ChangePasswordRequest,
  LogoutRequest,
  CheckPasswordRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_pb");
const {
  BasicAuthAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/auth/v1beta1/basic_auth_api_grpc_web_pb");

const REFRESH_INTERVAL_SEC = 300;

export function parseJwt(token, quiet = false) {
  try {
    const tokenSplit = token.split(".");
    const base64Url = tokenSplit[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    if (!quiet) {
      Notification.error(`Unable to parse token! ${error}`);
    }
    return {};
  }
}

export function assignTokens(refToken, accToken) {
  return (dispatch) => {
    setTokenAndRedirectAfterLogin(refToken, accToken, dispatch);
  };
}

export function setAuthTokens(refToken, accToken) {
  return (dispatch) => {
    const decoded = parseJwt(refToken);
    const expires = new Date(decoded.exp * 1000);
    dispatch({
      type: SET_AUTH_TOKENS,
      payload: { refToken, accToken, expires },
    });
  };
}

export function setTokenAndRedirectAfterLogin(refToken, accToken, dispatch) {
  setAuthTokens(refToken, accToken)(dispatch);
  dispatch(push("/trader"));
}

// Login
export function login(data) {
  return (dispatch) => {
    if (isOnline()) {
      dispatch({ type: LOGIN_START });
      dispatch({ type: CLEAR_ALL_NOTIFICATION });
      const auth_client = new BasicAuthAPIClient(apiUrl(), null, null);

      if (data.registering) {
        const register = new RegisterRequest();
        register.setToken(data.user.token);
        register.setPassword(data.user.password);

        auth_client.register(register, {}, (err, res) => {
          dispatch({ type: LOGIN_END });
          if (err) {
            dispatch({
              type: LOGIN_FAILURE,
              payload: { error: "Unable to register user." },
            });
            Notification.error("Error code " + err.code + ": " + err.message);
          } else {
            const accToken = "";
            const user = "";
            const expires = new Date();
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { accToken, user, expires },
            });
            Notification.success(
              "Successfully submitted registration for new user, you may now log in"
            );
            dispatch(push("/"));
          }
        });
        return;
      }

      const login = new LoginRequest();
      login.setUsername(data.user.username);
      login.setPassword(data.user.password);

      auth_client.login(login, {}, (err, res) => {
        if (err) {
          dispatch({ type: LOGIN_END });
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              error: "Username and password combination don't match.",
            },
          });
          Notification.error("Error code " + err.code + ": " + err.message);
        } else {
          assignTokens(res.getRefreshToken(), res.getAccessToken())(dispatch);
        }
      });
    } else {
      dispatch({ type: LOGIN_END });
      showError("No internet connection.");
    }
  };
}

export function logout(accToken, refToken) {
  return (dispatch) => {
    dispatch(reset()); // clears session
    dispatch({ type: CLEAR_ALL_NOTIFICATION });
    if (accToken && refToken) {
      const auth_client = new BasicAuthAPIClient(apiUrl(), null, null);
      const request = new LogoutRequest();
      request.setAccessToken(accToken);
      request.setRefreshToken(refToken);
      auth_client.logout(request, {});
    }
    const logoutUrlEnv = Env.getEnv("REACT_APP_LOGOUT_URL");
    if (!!logoutUrlEnv) {
      window.location.href = logoutUrlEnv;
    } else {
      dispatch(replace("/"));
    }
  };
}

export function changePassword(user, oldPassword, newPassword) {
  return (dispatch) => {
    const auth_client = new BasicAuthAPIClient(apiUrl(), null, null);
    const req = new ChangePasswordRequest();
    req.setUsername(user);
    req.setOldPassword(oldPassword);
    req.setNewPassword(newPassword);

    auth_client.changePassword(req, {}, (err, res) => {
      if (err) {
        Notification.error("Error code " + err.code + ": " + err.message);
      } else {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS });
        Notification.success("Password changed successfully!");
      }
    });
  };
}

// Check Password
export function checkPassword(password, cb) {
  return (dispatch) => {
    if (isOnline()) {
      const auth_client = new BasicAuthAPIClient(
          apiUrl(),
          null,
          null
      );

      const req = new CheckPasswordRequest();
      req.setPassword(password);

      auth_client.checkPassword(req, {}, (err, res) => {
        if (cb) {
          cb(err, res)
        }

        if (err) {
          Notification.error("Error code " + err.code + ": " + err.message);
        }
      });
    } else {
      showError("No internet connection.");
    }
  };
}

// reset auth redux state
function reset() {
  return { type: LOGIN_RESET };
}

export function initiateRefreshTokenLoop() {
  setInterval(() => {
    console.log('refresh call');
    refreshTokens();
  }, REFRESH_INTERVAL_SEC * 1000);
}
