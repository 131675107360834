import { getEnumName, capitalizeWord } from "../modules/util";
import State from "./State";
import {
  OrderType,
  Side,
  OrderState,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import OrderStatus from "./OrderStatus";
import ProtobufParser from "../modules/protobufParser";

export default class Order {
  constructor(item) {
    if (item) {
      const object = item.toObject();
      this.id = object.id;
      this.participant = object.participant;
      const insertTime = item.getInsertTime();
      const creationTime = item.getCreateTime();
      this.insertedOn = insertTime ? ProtobufParser.toDate(insertTime) : null;
      this.createdOn = creationTime
        ? ProtobufParser.toDate(creationTime)
        : null;
      //Attributes
      this.account = object.account;
      this.quantity = object.orderQty;
      this.minQty = object.minQty;
      this.price = object.price;
      this.stopPrice = object.stopPrice;

      //FIXMEOrder IDOrder IDOrder ID
      let side = new State();
      side.id = object.side;
      side.name = capitalizeWord(getEnumName(Side, side.id));
      this.side = side;

      let orderType = new State();
      orderType.id = object.type;
      orderType.name = capitalizeWord(getEnumName(OrderType, orderType.id));
      this.orderType = orderType;

      this.symbol = object.symbol;
      this.symbolSubType = object.symbolSubType;
      this.timeInForce = object.timeInForce;

      if (item.hasGoodTillTime()) {
        this.goodTillTime = ProtobufParser.toDate(item.getGoodTillTime());
      }

      this.type = object.type;
      this.sideEnum = object.side;

      this.status = new OrderStatus(item);
      this.clordId = object.clordId;
      this.allOrNone = object.allOrNone;
      this.blockTradeIndicator = object.blockTradeIndicator;
      this.triggerMethod = object.triggerMethod;
      return;
    }
    this.id = null;
    this.participant = null;
    this.status = null;
    this.insertedOn = null;
    this.createdOn = null;
    //Attributes
    this.account = null;
    this.quantity = null;
    this.minQty = null;
    this.price = null;
    this.stopPrice = null;
    this.side = null;
    this.symbol = null;
    this.timeInForce = null;
    this.type = null;
    this.allOrNone = null;
    this.blockTradeIndicator = null;
    this.triggerMethod = null;
  }

  static isOpen(stateName) {
    stateName = stateName ? stateName.toLowerCase() : stateName;
    let openStates = [
      getEnumName(OrderState, OrderState.ORDER_STATE_NEW).toLowerCase(),
      getEnumName(
        OrderState,
        OrderState.ORDER_STATE_PARTIALLY_FILLED
      ).toLowerCase(),
      getEnumName(OrderState, OrderState.ORDER_STATE_REPLACED).toLowerCase(),
      getEnumName(OrderState, OrderState.ORDER_STATE_PENDING_NEW).toLowerCase(),
      getEnumName(
        OrderState,
        OrderState.ORDER_STATE_PENDING_REPLACE
      ).toLowerCase(),
      getEnumName(
        OrderState,
        OrderState.ORDER_STATE_PENDING_CANCEL
      ).toLowerCase(),
      getEnumName(
        OrderState,
        OrderState.ORDER_STATE_PENDING_RISK
      ).toLowerCase(),
    ];
    return openStates.includes(stateName);
  }
}
