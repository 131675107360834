import React from "react";
import moment from "moment";

import "./TradingHours.css";
import {capitalizeWord, getEnumName} from "../../../modules/util";
import {InstrumentState} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import {DayOfWeek} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb";

const TradingHours = ({ scheduleList, metadata }) => {
  const timezone = metadata && metadata.location ? metadata.location : "UTC";
  return (
    <React.Fragment>
      <label>{"(" + timezone + ")"}</label>
      <div>
        <table cellPadding={0} cellSpacing={0} style={{width: "100%"}}>
          <tbody>
            {scheduleList &&
              scheduleList.map((schedule, index) => {
                const daysOfWeekList = Array.prototype.slice.call(schedule.daysOfWeekList).sort();
                const startDay = daysOfWeekList[0];
                const endDay =
                  daysOfWeekList[daysOfWeekList.length - 1];
                const { hours, minutes, seconds } = schedule.timeOfDay;
                const startTime = moment();
                startTime.hours(hours).minutes(minutes).seconds(seconds);
                const endTime = moment(startTime).add(
                  schedule.duration.seconds,
                  "seconds"
                );
                const sequential = daysOfWeekList.every(
                  (number, i) =>
                    i === 0 || daysOfWeekList[i - 1] === number - 1
                ) && daysOfWeekList.length > 1;

                return (
                  <tr key={index}>
                      <td className="hoursText">
                        <div>
                            <b>
                                {capitalizeWord(getEnumName(InstrumentState, schedule.state))}
                            </b>
                        </div>
                        {sequential && (
                          <span>
                              {capitalizeWord(getEnumName(DayOfWeek, startDay))} - {capitalizeWord(getEnumName(DayOfWeek, endDay))}
                          </span>
                        )}
                        {!sequential && (
                          <span>
                            {daysOfWeekList
                              .map((day) => {
                                return capitalizeWord(getEnumName(DayOfWeek, day));
                              })
                              .join(", ")}{" "}
                            &nbsp;
                          </span>
                        )}
                        <div>
                            {startTime.format("h:mm a z")} -{" "}
                            {endTime.format("h:mm a z")}&nbsp;
                        </div>
                      </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TradingHours;
