import { StatusCode } from "grpc-web";
import OrderService from "../services/OrderService";
import Notification from "../modules/notifications";
import { UPDATE_ACCOUNTS, UPDATE_USERS } from "../constants/actions";

export function ListAccounts() {
  return (dispatch) => {
    OrderService.listUsers((err, response) => {
      if (response) {
        let users = new Map();
        let firms = new Map();
        let firmsToUsers = new Map();
        const usersList = response.getUsersList();
        const usersDisplayNames = response.getDisplayNamesList();
        const firmsList = response.getFirmsList();
        const firmsDisplayNames = response.getFirmsDisplayNamesList();
        usersList.forEach((user, idx) =>
        {
          users.set(user, usersDisplayNames[idx]);
          const frm = firmsList[idx];
          firms.set(frm, firmsDisplayNames[idx]);
          let usersInFirm = firmsToUsers.has(frm) ? firmsToUsers.get(frm) : new Map();
          usersInFirm.set(user, true);
          firmsToUsers.set(frm, usersInFirm)
          OrderService.listAccounts(user,(err, response) => {
            if (response) {
              let accounts = new Map();

              const accountsList = response.getAccountsList();
              let accountsDisplayNames = response.getDisplayNamesList();
              if (
                  !accountsDisplayNames ||
                  accountsDisplayNames.length !== accountsList.length
              ) {
                accountsDisplayNames = accountsList;
              }
              accountsList.forEach((account, idx) =>
                  accounts.set(account, accountsDisplayNames[idx])
              );

              dispatch({
                type: UPDATE_ACCOUNTS,
                payload: accounts,
                user: user,
              });
            }

            if (err) {
              switch (err.code) {
                case StatusCode.NOT_FOUND:
                  Notification.warning(
                      "Could not find any accounts associated with this participant"
                  );
                  break;
                default:
                  Notification.error(
                      `Error when querying accounts: \n ${err.message}`
                  );
                  break;
              }
            }
          });
        });
        dispatch({
          type: UPDATE_USERS,
          payload: users,
          firms: firms,
          firmsToUsers: firmsToUsers,
        });
      }

      if (err) {
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning(
                "Could not find any users associated with this participant"
            );
            break;
          default:
            Notification.error(
                `Error when querying users: \n ${err.message}`
            );
            break;
        }
      }
    })
  };
}
