import { immerable } from "immer";
import { capitalizeWord, countDecimals, getEnumName } from "../modules/util";
import ProtobufParser from "../modules/protobufParser";
import moment from "moment-timezone";
import { InstrumentState as InstrumentStateApi } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/api_pb";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/instruments_pb";
import State from "./State";

export default class Instrument {
  [immerable] = true;
  constructor(item) {
    if (item) {
      const object = item.toObject();
      let state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(InstrumentStateApi, state.id));
      this.state = state;
      this.baseCurrency = item.getBaseCurrency();
      this.minimumTradeQty = item.getMinimumTradeQty();
      this.multiplier = item.getMultiplier();
      this.symbol = item.getSymbol();
      this.productId = item.getProductId();
      this.nonTradable = item.getNonTradable();
      this.tickSize = item.getTickSize();
      if (this.tickSize) {
        this.scale = Math.pow(10, countDecimals(this.tickSize));
      }
      this.qtyScale = item.getFractionalQtyScale();
      this.priceLimit = item.hasPriceLimit() ? item.getPriceLimit().toObject() : null;
      this.orderSizeLimit = item.hasOrderSizeLimit() ? item.getOrderSizeLimit().toObject() : null;
      this.description = object.description;
      this.startDate = item.hasStartDate() ? ProtobufParser.protoDateToDate(item.getStartDate()) : null;
      this.terminationDate = object.terminationDate ? ProtobufParser.objectDateToDate(object.terminationDate) : null;
      this.tradingScheduleList = object.tradingScheduleList;
      this.minimumUnaffiliatedFirms = object.minimumUnaffiliatedFirms;
      this.clearingHouse = object.clearingHouse;
      this.crossOrderRestingDuration = ProtobufParser.fromDuration(item.getCrossOrderRestingDuration());
      this.customAttributes = object.customAttributes;
      this.expirationDate = item.hasExpirationDate() ? ProtobufParser.protoDateToDate(item.getExpirationDate()) : null;
      this.jsonAttributes = item.hasJsonAttributes() ? JSON.parse(item.getJsonAttributes()) : {};
      this.hasFraAttributes = item.hasForwardRateAgreementSwapAttributes();
      this.hasIrsAttributes = item.hasInterestRateSwapAttributes();
      this.hasFutureAttributes = item.hasFutureAttributes();
      this.hasOptionAttributes = item.hasOptionAttributes();
      this.hasBasisSwapAttributes = item.hasBasisSwapAttributes();
      this.hasForwardStartingInterestRateSwapAttributes = item.hasForwardStartingInterestRateSwapAttributes();
      this.hasEventAttributes = item.hasEventAttributes();
      this.hasOvernightIndexSwapAttributes = item.hasOvernightIndexSwapAttributes();
      this.hasSinglePeriodSwapAttributes = item.hasSinglePeriodSwapAttributes();
      this.hasNonDeliverableForwardAttributes = item.hasNonDeliverableForwardAttributes();
      this.hasForexAttributes = item.hasForexAttributes();
      this.hasZeroCouponInflationSwapAttributes = item.hasZeroCouponInflationSwapAttributes();

      this.ifra = {
        calculationPeriodNumberOfDays: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "calculationPeriodNumberOfDays"),
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "effectiveDateBusinessDayConvention"),
        fixedBusinessDayConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "fixedBusinessDayConvention"),
        fixedDayCountConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "fixedDayCountConvention"),
        fixedFixingDateHolidayCalendarsList: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "fixedFixingDateHolidayCalendarsList"),
        floatBusinessDayConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatBusinessDayConvention"),
        floatDayCountConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatDayCountConvention"),
        floatFixingDate: formatDate(setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatFixingDate")),
        floatFixingDateBusinessDayConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatFixingDateBusinessDayConvention"),
        floatFixingDateHolidayCalendarsList: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatFixingDateHolidayCalendarsList"),
        floatFixingDatesDayType: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatFixingDatesDayType"),
        floatFixingDatesOffset: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatFixingDatesOffset"),
        floatingRateIndex: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatingRateIndex"),
        floatingRateIndexTenor: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "floatingRateIndexTenor"),
        forwardStartingPeriod: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "forwardStartingPeriod"),
        maturityDate: formatDate(setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "maturityDateBusinessDayConvention"),
        sideConvention: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "swapEffectiveDate")),
        tenor: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "tenor"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "unadjustedMaturityDate")),
        rollAndPaymentDateHolidayCalendarsList: setAttValueOrNull(object.forwardRateAgreementSwapAttributes, "rollAndPaymentDateHolidayCalendarsList"),
      }

      this.irsa = {
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "effectiveDateBusinessDayConvention"),
        effectiveDateOffset: setAttValueOrNull(object.interestRateSwapAttributes, "effectiveDateOffset"),
        firstFloatResetDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "firstFloatResetDate")),
        fixedBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "fixedBusinessDayConvention"),
        fixedCompoundingMethod: setAttValueOrNull(object.interestRateSwapAttributes, "fixedCompoundingMethod"),
        fixedDayCountConvention: setAttValueOrNull(object.interestRateSwapAttributes, "fixedDayCountConvention"),
        fixedFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "fixedFirstUnadjustedPaymentDate")),
        fixedPaymentDateRelativeTo: setAttValueOrNull(object.interestRateSwapAttributes, "fixedPaymentDateRelativeTo"),
        fixedPaymentFrequency: setAttValueOrNull(object.interestRateSwapAttributes, "fixedPaymentFrequency"),
        fixedRollConvention: setAttValueOrNull(object.interestRateSwapAttributes, "fixedRollConvention"),
        floatCompoundingMethod: setAttValueOrNull(object.interestRateSwapAttributes, "floatCompoundingMethod"),
        floatDayCountConvention: setAttValueOrNull(object.interestRateSwapAttributes, "floatDayCountConvention"),
        firstFloatFixingDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "firstFloatFixingDate")),
        floatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "floatFirstUnadjustedPaymentDate")),
        floatFixingDateBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "floatFixingDateBusinessDayConvention"),
        floatFixingDatesDayType: setAttValueOrNull(object.interestRateSwapAttributes, "floatFixingDatesDayType"),
        floatFixingDatesOffset: setAttValueOrNull(object.interestRateSwapAttributes, "floatFixingDatesOffset"),
        floatHolidayCalendarsList: setAttValueOrNull(object.interestRateSwapAttributes, "floatHolidayCalendarsList"),
        floatPaymentDateRelativeTo: setAttValueOrNull(object.interestRateSwapAttributes, "floatPaymentDateRelativeTo"),
        floatPaymentFrequency: setAttValueOrNull(object.interestRateSwapAttributes, "floatPaymentFrequency"),
        floatRateResetFrequency: setAttValueOrNull(object.interestRateSwapAttributes, "floatRateResetFrequency"),
        floatResetDateBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "floatResetDateBusinessDayConvention"),
        floatBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "floatBusinessDayConvention"),
        floatResetDateRelativeTo: setAttValueOrNull(object.interestRateSwapAttributes, "floatResetDateRelativeTo"),
        floatRollConvention: setAttValueOrNull(object.interestRateSwapAttributes, "floatRollConvention"),
        floatingRateIndex: setAttValueOrNull(object.interestRateSwapAttributes, "floatingRateIndex"),
        floatingRateIndexTenor: setAttValueOrNull(object.interestRateSwapAttributes, "floatingRateIndexTenor"),
        maturityDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.interestRateSwapAttributes, "maturityDateBusinessDayConvention"),
        rollAndPaymentDateHolidayCalendarsList: setAttValueOrNull(object.interestRateSwapAttributes, "rollAndPaymentDateHolidayCalendarsList"),
        sideConvention: setAttValueOrNull(object.interestRateSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "swapEffectiveDate")),
        tenor: setAttValueOrNull(object.interestRateSwapAttributes, "tenor"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.interestRateSwapAttributes, "unadjustedMaturityDate")),
      };

      this.fut = {
        maturityDate: formatDate(setAttValueOrNull(object.futureAttributes, "maturityDate")),
      };

      this.opt = {
        maturityDate: formatDate(setAttValueOrNull(object.optionAttributes, "maturityDate")),
        putOrCall: setAttValueOrNull(object.optionAttributes, "putOrCall"),
        strikePrice: setAttValueOrNull(object.optionAttributes, "strikePrice"),
      };

      this.forex = {
        baseCurrency: setAttValueOrNull(object.forexAttributes, "baseCurrency"),
        quoteCurrency: setAttValueOrNull(object.forexAttributes, "quoteCurrency"),
      }

      this.fsIrs = {
        tenor: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "tenor"),
        sideConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "swapEffectiveDate")),
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "effectiveDateBusinessDayConvention"),
        maturityDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "maturityDateBusinessDayConvention"),
        rollAndPaymentDateHolidayCalendarsList: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "rollAndPaymentDateHolidayCalendarsList"),
        fixedPaymentFrequency: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedPaymentFrequency"),
        fixedCompoundingMethod: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedCompoundingMethod"),
        fixedDayCountConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedDayCountConvention"),
        fixedBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedBusinessDayConvention"),
        floatCompoundingMethod: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatCompoundingMethod"),
        floatDayCountConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatDayCountConvention"),
        floatHolidayCalendarsList: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatHolidayCalendarsList"),
        floatPaymentFrequency: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatPaymentFrequency"),
        floatRateResetFrequency: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatRateResetFrequency"),
        floatResetDateBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatResetDateBusinessDayConvention"),
        firstFloatResetDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "firstFloatResetDate")),
        firstFloatFixingDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "firstFloatFixingDate")),
        floatingRateIndex: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatingRateIndex"),
        floatRollConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatRollConvention"),
        fixedRollConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedRollConvention"),
        floatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatFirstUnadjustedPaymentDate")),
        fixedFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedFirstUnadjustedPaymentDate")),
        floatPaymentDateRelativeTo: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatPaymentDateRelativeTo"),
        fixedPaymentDateRelativeTo: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "fixedPaymentDateRelativeTo"),
        floatFixingDatesDayType: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatFixingDatesDayType"),
        floatFixingDatesOffset: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatFixingDatesOffset"),
        floatingRateIndexTenor: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatingRateIndexTenor"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "unadjustedMaturityDate")),
        effectiveDateOffset: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "effectiveDateOffset"),
        floatResetDateRelativeTo: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatResetDateRelativeTo"),
        floatFixingDateBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatFixingDateBusinessDayConvention"),
        floatBusinessDayConvention: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatBusinessDayConvention"),
        floatFixingDateHolidayCalendarsList: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "floatFixingDateHolidayCalendarsList"),
        forwardStartingPeriod: setAttValueOrNull(object.forwardStartingInterestRateSwapAttributes, "forwardStartingPeriod")
      }

      this.basisSwap = {
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "effectiveDateBusinessDayConvention"),
        effectiveDateOffset: setAttValueOrNull(object.basisSwapAttributes, "effectiveDateOffset"),
        floatFirstUnadjustedEffectiveDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "floatFirstUnadjustedEffectiveDate")),
        leg1FirstFloatFixingDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg1FirstFloatFixingDate")),
        leg1FirstFloatResetDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg1FirstFloatResetDate")),
        leg1FloatBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatBusinessDayConvention"),
        leg1FloatCompoundingMethod: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatCompoundingMethod"),
        leg1FloatDayCountConvention: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatDayCountConvention"),
        leg1FloatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg1FloatFirstUnadjustedPaymentDate")),
        leg1FloatFixingDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatFixingDateBusinessDayConvention"),
        leg1FloatFixingDateHolidayCalendarsList: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatFixingDateHolidayCalendarsList"),
        leg1FloatFixingDatesDayType: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatFixingDatesDayType"),
        leg1FloatFixingDatesOffset: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatFixingDatesOffset"),
        leg1FloatPaymentDateRelativeTo: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatPaymentDateRelativeTo"),
        leg1FloatPaymentFrequency: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatPaymentFrequency"),
        leg1FloatRateResetFrequency: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatRateResetFrequency"),
        leg1FloatResetDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatResetDateBusinessDayConvention"),
        leg1FloatResetDateRelativeTo: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatResetDateRelativeTo"),
        leg1FloatRollConvention: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatRollConvention"),
        leg1FloatingRateIndex: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatingRateIndex"),
        leg1FloatingRateIndexTenor: setAttValueOrNull(object.basisSwapAttributes, "leg1FloatingRateIndexTenor"),
        leg2FirstFloatFixingDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg2FirstFloatFixingDate")),
        leg2FirstFloatResetDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg2FirstFloatResetDate")),
        leg2FloatBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatBusinessDayConvention"),
        leg2FloatCompoundingMethod: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatCompoundingMethod"),
        leg2FloatDayCountConvention: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatDayCountConvention"),
        leg2FloatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "leg2FloatFirstUnadjustedPaymentDate")),
        leg2FloatFixingDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatFixingDateBusinessDayConvention"),
        leg2FloatFixingDateHolidayCalendarsList: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatFixingDateHolidayCalendarsList"),
        leg2FloatFixingDatesDayType: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatFixingDatesDayType"),
        leg2FloatFixingDatesOffset: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatFixingDatesOffset"),
        leg2FloatPaymentDateRelativeTo: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatPaymentDateRelativeTo"),
        leg2FloatPaymentFrequency: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatPaymentFrequency"),
        leg2FloatRateResetFrequency: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatRateResetFrequency"),
        leg2FloatResetDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatResetDateBusinessDayConvention"),
        leg2FloatResetDateRelativeTo: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatResetDateRelativeTo"),
        leg2FloatRollConvention: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatRollConvention"),
        leg2FloatingRateIndex: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatingRateIndex"),
        leg2FloatingRateIndexTenor: setAttValueOrNull(object.basisSwapAttributes, "leg2FloatingRateIndexTenor"),
        maturityDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.basisSwapAttributes, "maturityDateBusinessDayConvention"),
        rollAndPaymentDateHolidayCalendarsList: setAttValueOrNull(object.basisSwapAttributes, "rollAndPaymentDateHolidayCalendarsList"),
        sideConvention: setAttValueOrNull(object.basisSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "swapEffectiveDate")),
        tenor: setAttValueOrNull(object.basisSwapAttributes, "tenor"),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.basisSwapAttributes, "unadjustedMaturityDate")),
      }


      this.ois = {
        tenor: setAttValueOrNull(object.overnightIndexSwapAttributes, "tenor"),
        tenorTimePeriod: setAttValueOrNull(object.overnightIndexSwapAttributes, "tenorTimePeriod"),
        sideConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "swapEffectiveDate")),
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "effectiveDateBusinessDayConvention"),
        maturityDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "maturityDateBusinessDayConvention"),
        forwardStartingPeriod: setAttValueOrNull(object.overnightIndexSwapAttributes, "forwardStartingPeriod"),
        forwardStartingPeriodTimePeriod: setAttValueOrNull(object.overnightIndexSwapAttributes, "forwardStartingPeriodTimePeriod"),
        fixedHolidayCalendarsList: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedHolidayCalendarsList"),
        fixedPaymentFrequency: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedPaymentFrequency"),
        fixedCompoundingMethod: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedCompoundingMethod"),
        fixedDayCountConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedDayCountConvention"),
        fixedBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedBusinessDayConvention"),
        floatCompoundingMethod: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatCompoundingMethod"),
        floatDayCountConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatDayCountConvention"),
        floatHolidayCalendarsList: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatHolidayCalendarsList"),
        floatPaymentFrequency: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatPaymentFrequency"),
        floatRateResetFrequency: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatRateResetFrequency"),
        floatResetDateBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatResetDateBusinessDayConvention"),
        firstFloatResetDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "firstFloatResetDate")),
        firstFloatFixingDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "firstFloatFixingDate")),
        floatingRateIndex: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatingRateIndex"),
        floatRollConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatRollConvention"),
        fixedRollConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedRollConvention"),
        floatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "floatFirstUnadjustedPaymentDate")),
        fixedFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedFirstUnadjustedPaymentDate")),
        floatPaymentDateRelativeTo: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatPaymentDateRelativeTo"),
        fixedPaymentDateRelativeTo: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedPaymentDateRelativeTo"),
        floatFixingDatesDayType: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatFixingDatesDayType"),
        floatFixingDatesOffset: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatFixingDatesOffset"),
        floatingRateIndexTenor: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatingRateIndexTenor"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.overnightIndexSwapAttributes, "unadjustedMaturityDate")),
        effectiveDateOffset: setAttValueOrNull(object.overnightIndexSwapAttributes, "effectiveDateOffset"),
        floatResetDateRelativeTo: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatResetDateRelativeTo"),
        floatFixingDateBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatFixingDateBusinessDayConvention"),
        floatBusinessDayConvention: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatBusinessDayConvention"),
        floatFixingDateHolidayCalendarsList: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatFixingDateHolidayCalendarsList"),
        fixedPaymentDateOffset: setAttValueOrNull(object.overnightIndexSwapAttributes, "fixedPaymentDateOffset"),
        floatPaymentDateOffset: setAttValueOrNull(object.overnightIndexSwapAttributes, "floatPaymentDateOffset"),
        paymentDateDayType: setAttValueOrNull(object.overnightIndexSwapAttributes, "paymentDateDayType")
      }

      this.sps = {
        tenor: setAttValueOrNull(object.singlePeriodSwapAttributes, "tenor"),
        sideConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "sideConvention"),
        tenorTimePeriod: setAttValueOrNull(object.singlePeriodSwapAttributes, "tenorTimePeriod"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "swapEffectiveDate")),
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "effectiveDateBusinessDayConvention"),
        maturityDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "maturityDateBusinessDayConvention"),
        fixedHolidayCalendarsList: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedHolidayCalendarsList"),
        fixedPaymentFrequency: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedPaymentFrequency"),
        fixedCompoundingMethod: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedCompoundingMethod"),
        fixedDayCountConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedDayCountConvention"),
        fixedBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedBusinessDayConvention"),
        floatCompoundingMethod: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatCompoundingMethod"),
        floatDayCountConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatDayCountConvention"),
        floatHolidayCalendarsList: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatHolidayCalendarsList"),
        floatPaymentFrequency: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatPaymentFrequency"),
        floatRateResetFrequency: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatRateResetFrequency"),
        floatResetDateBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatResetDateBusinessDayConvention"),
        firstFloatResetDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "firstFloatResetDate")),
        firstFloatFixingDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "firstFloatFixingDate")),
        floatingRateIndex: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatingRateIndex"),
        floatRollConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatRollConvention"),
        fixedRollConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedRollConvention"),
        floatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "floatFirstUnadjustedPaymentDate")),
        fixedFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedFirstUnadjustedPaymentDate")),
        floatPaymentDateRelativeTo: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatPaymentDateRelativeTo"),
        fixedPaymentDateRelativeTo: setAttValueOrNull(object.singlePeriodSwapAttributes, "fixedPaymentDateRelativeTo"),
        floatFixingDatesDayType: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatFixingDatesDayType"),
        floatFixingDatesOffset: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatFixingDatesOffset"),
        floatingRateIndexTenor: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatingRateIndexTenor"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.singlePeriodSwapAttributes, "unadjustedMaturityDate")),
        floatResetDateRelativeTo: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatResetDateRelativeTo"),
        floatFixingDateBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatFixingDateBusinessDayConvention"),
        floatBusinessDayConvention: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatBusinessDayConvention"),
        floatFixingDateHolidayCalendarsList: setAttValueOrNull(object.singlePeriodSwapAttributes, "floatFixingDateHolidayCalendarsList"),
      }

      this.zeroCouponInflationSwap = {
        tenor: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "tenor"),
        sideConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "sideConvention"),
        swapEffectiveDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "swapEffectiveDate")),
        effectiveDateBusinessDayConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "effectiveDateBusinessDayConvention"),
        maturityDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "maturityDate")),
        maturityDateBusinessDayConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "maturityDateBusinessDayConvention"),
        rollAndPaymentDateHolidayCalendarsList: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "rollAndPaymentDateHolidayCalendarsList"),
        fixedPaymentFrequency: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedPaymentFrequency"),
        fixedCompoundingMethod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedCompoundingMethod"),
        floatCompoundingMethod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatCompoundingMethod"),
        fixedDayCountConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedDayCountConvention"),
        floatDayCountConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatDayCountConvention"),
        floatHolidayCalendarsList: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatHolidayCalendarsList"),
        floatPaymentFrequency: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatPaymentFrequency"),
        floatingRateIndex: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatingRateIndex"),
        floatRollConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatRollConvention"),
        fixedRollConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedRollConvention"),
        floatFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatFirstUnadjustedPaymentDate")),
        fixedFirstUnadjustedPaymentDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedFirstUnadjustedPaymentDate")),
        floatPaymentDateRelativeTo: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatPaymentDateRelativeTo"),
        fixedPaymentDateRelativeTo: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedPaymentDateRelativeTo"),
        unadjustedEffectiveDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "unadjustedEffectiveDate")),
        unadjustedMaturityDate: formatDate(setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "unadjustedMaturityDate")),
        effectiveDateOffset: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "effectiveDateOffset"),
        floatBusinessDayConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "floatBusinessDayConvention"),
        fixedBusinessDayConvention: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fixedBusinessDayConvention"),
        forwardStartingPeriod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "forwardStartingPeriod"),
        tenorTimePeriod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "tenorTimePeriod"),
        forwardStartingPeriodTimePeriod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "forwardStartingPeriodTimePeriod"),
        inflationLag: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "inflationLag"),
        indexSource: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "indexSource"),
        interpolationMethod: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "interpolationMethod"),
        fallbackBondApplication: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "fallbackBondApplication"),
        inflationInstrumentName: setAttValueOrNull(object.zeroCouponInflationSwapAttributes, "inflationInstrumentName")
      }

      this.eventAttributes = [];
      if (this.hasEventAttributes) {
        let eventAttributes = object.eventAttributes.subTypesList;
        eventAttributes.forEach(event => {
          this.eventAttributes.push(event);
        });
      }
      this.nonDeliverableForwardAttributes = {
        tenor: setAttValueOrNull(object.nonDeliverableForwardAttributes, "tenor"),
        sideConvention: setAttValueOrNull(object.nonDeliverableForwardAttributes, "sideConvention"),
        forwardStartingPeriod: setAttValueOrNull(object.nonDeliverableForwardAttributes, "forwardStartingPeriod"),
        forwardStartingPeriodTimePeriod: setAttValueOrNull(object.nonDeliverableForwardAttributes, "forwardStartingPeriodTimePeriod"),
        valuationDate: formatDate(setAttValueOrNull(object.nonDeliverableForwardAttributes, "valuationDate")),
        fixingDate: formatDate(setAttValueOrNull(object.nonDeliverableForwardAttributes, "fixingDate")),
        settlementCurrency: setAttValueOrNull(object.nonDeliverableForwardAttributes, "settlementCurrency"),
        tenorTimePeriod: setAttValueOrNull(object.nonDeliverableForwardAttributes, "tenorTimePeriod"),
      }

      this.selected = false;
      return;
    }
    this.baseCurrency = null;
    this.minimumTradeQty = null;
    this.multiplier = null;
    this.symbol = null;
    this.productId = null;
    this.nonTradable = false;
    this.tickSize = null;
    this.priceLimit = null;
    this.orderSizeLimit = null;
    this.scale = null;
    this.qtyScale = null;
    this.selected = false;
    this.jsonAttributes = {};
  }

  startTime(metadata = null, preOpen = false) {
    const { startDate, tradingScheduleList } = this;
    const metadataLocation = metadata && metadata.location ? metadata.location : "UTC";

    let updated = false;
    const startDateMoment = moment.tz(startDate, metadataLocation);
    let startTime = moment.tz(
      [startDateMoment.year(), startDateMoment.month(), startDateMoment.date(), 0, 0, 0],
      metadataLocation
    );
    if (Array.isArray(tradingScheduleList) && tradingScheduleList.length > 0) {
      let tempStartDate = moment(startTime);
      for (let i = 0; i < 7 && !updated; i++) {
        const protoDayOfWeek = tempStartDate.day() === 0 ? 7 : tempStartDate.day();
        // eslint-disable-next-line
        tradingScheduleList.forEach(timeChunk => {
          if (
            (
              timeChunk.state !== InstrumentState.INSTRUMENT_STATE_PREOPEN ||
              ((timeChunk.state === InstrumentState.INSTRUMENT_STATE_PREOPEN) && preOpen)
            ) && timeChunk.daysOfWeekList.includes(protoDayOfWeek)) {
            const dtp = moment.tz([
              tempStartDate.year(),
              tempStartDate.month(),
              tempStartDate.date(),
              timeChunk.timeOfDay.hours,
              timeChunk.timeOfDay.minutes,
              timeChunk.timeOfDay.seconds,
            ], metadataLocation);
            if (!updated || dtp.isBefore(startTime)) {
              startTime = dtp;
              updated = true;
            }
          }
        });
        tempStartDate = tempStartDate.add(1, 'days');
      }
    }
    return startTime;
  }
}

const setAttValueOrNull = (obj, att) => {
  let val = null;
  if (obj)
    val = Reflect.get(obj, att)

  return (val ? val : null);
}

const formatDate = (date) => {
  if (date)
    return ProtobufParser.objectDateToDate(date);
  else
    return null;
}
