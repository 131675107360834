import {
  SHOW_WATCHLIST, HIDE_WATCHLIST, UPDATE_WATCHLIST,
  ADD_WATCHLIST_INSTRUMENTS, ADD_SYMBOLS,
  SET_WATCHLIST_INSTRUMENTS_LOADING, SET_PAGE_SIZE
} from "../constants/actions";
import produce from "immer";

const initialState = {
  show: false,
  instruments: [],
  currentPageSize: 10,
  showLoading: false,
  watchListData: {}
};

const watchList = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_WATCHLIST:
      draft.show = true;
      return;
    case HIDE_WATCHLIST:
      draft.show = false;
      return;
    case UPDATE_WATCHLIST:
      draft.instruments = [...payload]
      return
    case ADD_WATCHLIST_INSTRUMENTS:
      if(!draft.watchListData) draft.watchListData = {};

      payload.instrumentsList.forEach((instrument) => {
        if (!draft.watchListData[instrument.symbol])
          draft.watchListData[instrument.symbol] = {
            symbol:instrument.symbol,
            instrument:null
          };
        if (!draft.watchListData[instrument.symbol].instrument)
          draft.watchListData[instrument.symbol].instrument = instrument;
      });
      return
    case ADD_SYMBOLS:
      payload.symbolsList.forEach((symbol) => {
        if (!draft.watchListData[symbol]) {
          draft.watchListData[symbol] = {
            symbol,
            instrument: null
          }
        }
      })
      return
    case SET_WATCHLIST_INSTRUMENTS_LOADING:
      draft.showLoading = payload
      return
    case SET_PAGE_SIZE:
      draft.currentPageSize = payload;
      return
    default: return;
  }
}, initialState)

export default watchList;
