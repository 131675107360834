import React from 'react';
import PropTypes from 'prop-types';
import DropdownTreeSelect from '../../vendor/react-dropdown-tree-select/react-dropdown-tree-select';
import './styles.css'

const AccountSelector = ({ account, user, users, usersToAccounts, firms, firmsToUsers, accounts, userAccountCb }) => {
    if (!users || users.size <= 1) {
        const createAccountOptions = () => {
            let items = [];
            if(!!accounts){
                accounts.forEach((displayName, account) => items.push(<option key={account} value={account}>{displayName}</option>));
            }
            return items;
        };
        return (<select
            id="account"
            name="account"
            placeholder="Account"
            className="form-control"
            value={account}
            onChange={event => {
                const newAccount = event.target.value;
                let newUser = "";
                if (!!users && !!usersToAccounts) {
                    usersToAccounts.forEach((accts, usr) => {
                        if (accts.has(newAccount)) newUser = usr;
                    })
                }
                userAccountCb(newUser, newAccount);
            }}
        >
            {createAccountOptions()}
        </select>)
    }

    let data = [];
    firms.forEach((firmDisplayName, firm) => {
        let frmObj = {
            label: firmDisplayName,
            value: firm,
            disabled: true,
            children: [],
        }
        accounts.forEach((displayName, acct) => {
            let obj = {
                label: displayName,
                value: acct,
                disabled: true,
                children: [],
            }
            usersToAccounts.forEach((accts, usr) => {
                if (accts.has(acct) && firmsToUsers.get(firm).has(usr)) obj.children.push({
                    label: users.get(usr),
                    value: usr,
                    disabled: false,
                    checked: account === acct && user === usr,
                    dataset: acct,
                });
            })
            if (obj.children.length > 0) frmObj.children.push(obj);
        })
        if (frmObj.children.length > 0) data.push(frmObj);
    })

    return <DropdownTreeSelect
            id="account"
            name="account"
            mode="radioSelect"
            data={data}
            onChange={(_, selectedNodes) => {
                let newUser = "";
                let newAccount = "";
                if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
                    newUser = selectedNodes[0].value;
                    newAccount = selectedNodes[0].dataset;
                }
                userAccountCb(newUser, newAccount);
            }}
            className="tree-selector"
        />
};

AccountSelector.defaultProps = {
    account: "",
    user: "",
    users: null,
    usersToAccounts: null,
    firms: null,
    firmsToUsers: null,
    accounts: null,
    userAccountCb: null,
}

AccountSelector.propTypes = {
    account: PropTypes.string,
    user: PropTypes.string,
    users: PropTypes.object,
    usersToAccounts: PropTypes.object,
    firms: PropTypes.object,
    firmsToUsers: PropTypes.object,
    accounts: PropTypes.object,
    userAccountCb: PropTypes.func,
};

export default AccountSelector;