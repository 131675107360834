import React from 'react';
import { parsePrice, validatePrice } from "../../modules/util";
import "./styles.css";
import ButtonMain from "../core/form/ButtonMain";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from "i18next";
import { Tabs, Tab, Button } from "react-bootstrap";
import { instrumentTitle } from "./Instruments";
import { isPopup } from "../shared/AdminWrapper";
import ButtonSecondary from "../core/form/ButtonSecondary";
import {Env} from "../../constants/environment";

const BookEntry = ({
  isBid,
  scale,
  qtyScale,
  onPlaceOrder,
  entry
}) => {
  return (
    <div className='row book-entry no-gutters' onClick={(e) => onPlaceOrder(e, entry)}>
      <div className={'col text-center qty ' + (isBid ? 'order-1' : 'order-2')}>
        {parsePrice(entry.qty, qtyScale)}
      </div>
      <div className={'col text-center ' + (isBid ? 'order-2 bid-px' : 'order-1 offer-px')}>
        {validatePrice(entry.px) ? parsePrice(entry.px, scale) : null}
      </div>
    </div>
  );
}

BookEntry.propTypes = {
  isBid: PropTypes.bool,
  scale: PropTypes.number,
  qtyScale: PropTypes.number,
  entry: PropTypes.object,
  onPlaceOrder: PropTypes.func.isRequired,
};

const BookEntries = ({
  isBid,
  entries,
  onPlaceOrder,
  scale,
  qtyScale,
}) => {
  return (
    <div className={'col container-fluid book-entries mh-100 ' + (isBid ? 'book-entries-bids' : '')}>
      <div className="row no-gutters">
        <div className='col text-center book-heading'>
          <div className="book-heading-title">{isBid ? 'Bids' : 'Offers'}</div>
        </div>
      </div>

      <div className='row no-gutters book-subheading'>
        <div className={'col text-center qty ' + (isBid ? 'order-1' : 'order-2')}>QUANTITY</div>
        <div className={'col text-center ' + (isBid ? 'order-2' : 'order-1')}>PRICE</div>
      </div>

      {entries.map((entry, i) =>
        <BookEntry
          key={i}
          entry={entry}
          isBid={isBid}
          scale={scale}
          qtyScale={qtyScale}
          onPlaceOrder={onPlaceOrder}
        />
      )}
    </div>
  );
}

BookEntries.propTypes = {
  isBid: PropTypes.bool,
  scale: PropTypes.number,
  qtyScale: PropTypes.number,
  entries: PropTypes.array,
  onPlaceOrder: PropTypes.func.isRequired,
};

const OrderBook = ({
  data,
  symbol,
  subType,
  instrument,
  bookHidden,
  relatedInstruments,
  books,
  onPlaceOrder,
  onPlaceCross,
  canCross,
  symbolcb,
  symbolpopup,
}) => {
  let bids = [];
  let offers = [];
  const currPrice = getPriceFromBook(books[symbol]);
  const scale = instrument ? instrument.scale : 1;
  const qtyScale = instrument ? instrument.qtyScale : 1;
  const nonTradable = instrument ? !!instrument.nonTradable : true;

  const lowPriceLimit = instrument && instrument.priceLimit && instrument.priceLimit.lowSet && validatePrice(instrument.priceLimit.low) ? parsePrice(instrument.priceLimit.low, scale) : null;
  const highPriceLimit = instrument && instrument.priceLimit && instrument.priceLimit.highSet && validatePrice(instrument.priceLimit.high) ? parsePrice(instrument.priceLimit.high, scale) : null;
  let subPriceLabel = "";
  let subPriceSameLine = false;

  if (lowPriceLimit && instrument.priceLimit.low !== 0) {
    subPriceLabel += lowPriceLimit + " ≤ px"
  }

  if (highPriceLimit && instrument.priceLimit.high !== 0) {
    subPriceSameLine = !subPriceLabel
    subPriceLabel += (subPriceLabel ? " ≤ " : " / ") + highPriceLimit
  }

  for (let i = 0; i < data.length; i++) {

    if (!!subType && data[i].bidSubType !== subType) {
      continue;
    }

    if (validatePrice(data[i].pxBid)) {
      bids.push({ qty: data[i].qtyBid, px: data[i].pxBid });
    }

    if (validatePrice(data[i].pxOffer)) {
      offers.push({ qty: data[i].qtyOffer, px: data[i].pxOffer });
    }
  }
  return (
    <div className="AggregatedBookDataGrid h-100">
      {!(!!instrument && instrument.eventAttributes.length > 0) &&
        Env.getEnvBool("REACT_APP_SHOW_RELATED_INSTRUMENTS") && !isPopup() && <Tabs activeKey={symbol} id="related-symbols" onSelect={symbolcb}>
          {relatedInstruments.map(relatedSymbol => {
            const book = books[relatedSymbol];
            const price = getPriceFromBook(book);
            const title = instrumentTitle(relatedSymbol, price && book.scale ? parsePrice(price, book.scale) : 'N/A');
            return (<Tab eventKey={relatedSymbol} title={title}></Tab>)
          })}
        </Tabs>}

      {(!!instrument && !!instrument.eventAttributes.length > 0) && (
        <Tabs activeKey={`${instrument.symbol}::${subType}`} id="related-symbols" onSelect={symbolcb}>
          {(!!instrument && Array.isArray(instrument.eventAttributes)) && instrument.eventAttributes.map(attSubType => {
            const title = (<div>
              <b style={{ color: "var(--APP_COLOR_ORDERBOOK_TAB)" }}>{attSubType.symbol}</b>
            </div>);

            const symbolStr = `${instrument.symbol}::${attSubType.symbol}`
            return (<Tab eventKey={symbolStr} title={title}></Tab>)
          })}
        </Tabs>)}

      <div className="h-100 mt-3">
        <h3 className="orderbooktext">
          {symbol ? orderBookTitle(symbol, subType) : "Select " + i18n.t("instrument")}
        </h3>
        {!isPopup() && <Button variant="buttonActions" size="sm" onClick={() => symbolpopup(symbol)} title="Popout"
          style={{ position: "absolute", top: 5, right: 5 }}>
          <i className="fa fa-fw fa-expand orange-icon" />
        </Button>}
      </div>

      <div className="text-center">
        {orderBookLabel(validatePrice(currPrice) && scale ? parsePrice(currPrice, scale) : 'Px N/A', subPriceLabel, subPriceSameLine)}
        <ButtonMain
          type="button"
          onClick={onPlaceOrder}
          disabled={!symbol || nonTradable}
          text={"Place Order"}
        />
        {canCross() && <ButtonSecondary
          type="button"
          onClick={onPlaceCross}
          enabled={!!symbol && !nonTradable}
          text={"Cross"}
        />}
      </div>
      <div className="book container-fluid h-100 mt-3">
        {!bookHidden &&
          <div className="row no-gutters">
            <BookEntries entries={bids} isBid={true} scale={scale} qtyScale={qtyScale} onPlaceOrder={onPlaceOrder} />
            <BookEntries entries={offers} isBid={false} scale={scale} qtyScale={qtyScale} onPlaceOrder={onPlaceOrder} />
          </div>
        }
      </div>
    </div>
  )
};

OrderBook.propTypes = {
  symbol: PropTypes.string,
  instrument: PropTypes.object,
  bookHidden: PropTypes.bool,
  data: PropTypes.array,
  relatedInstruments: PropTypes.array,
  books: PropTypes.object,
  onPlaceOrder: PropTypes.func.isRequired,
  onPlaceCross: PropTypes.func.isRequired,
  symbolcb: PropTypes.func.isRequired,
  symbolpopup: PropTypes.func,
}

function mapStateToProps(state) {
  const { trader, quotes } = state
  const { symbol, subType, relatedInstruments } = trader
  const { instruments } = quotes

  let instrument = instruments[symbol];

  if (instrument === undefined) {
    return {
      symbol: symbol,
      subType: subType,
      instrument: null,
      bookHidden: false,
      data: [],
      relatedInstruments: [],
      books: {},
    }
  }

  return {
    symbol: symbol,
    subType: subType,
    instrument: instrument,
    bookHidden: !!trader.bookHidden,
    data: trader.book ? trader.book : [],
    relatedInstruments: relatedInstruments ? relatedInstruments : [],
    books: trader.instruments ? trader.instruments : {},
  };
}

function getPriceFromBook(book) {
  return !book ? null : book.last ? book.last : book.open ? book.open : book.close;
}

function orderBookTitle(symbol, subType) {
  const symbolDelimiter = Env.getEnv("REACT_APP_SYMBOL_DELIMITER");
  const lastSpaceIdx = symbol.lastIndexOf(!symbolDelimiter ? " " : symbolDelimiter);
  if (lastSpaceIdx < 0 || !Env.getEnvBool("REACT_APP_HIGHLIGHT_SYMBOL")) {
    return symbol + (!!subType ? " :: " + subType : "");
  }
  return (
    <div>
      {symbol.substring(0, lastSpaceIdx + 1)}
      {orderBookLabel(symbol.substring(lastSpaceIdx + 1))}
      {!!subType ? " :: " + subType : ""}

    </div>
  );
}

function orderBookLabel(str, subLabel, sameLine = false) {
  const style = { color: "#db614a", fontSize: 24, borderRight: 20, borderLeft: 20 };
  return (<p>
    {!sameLine && <div style={style}>{str}</div>}
    {!!sameLine && <span style={style}>{str}</span>}
    {subLabel}
  </p>)
}

export default connect(mapStateToProps, null)(OrderBook);