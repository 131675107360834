import React from "react";
import { connect } from "react-redux";
import DataGrid from "../core/data-grid/DataGrid";
import {
  convertDateToString,
  parsePrice,
  validatePrice,
} from "../../modules/util";
import "./styles.css";
import i18n from "i18next";
import { instrumentTitle } from "./Instruments";
import DataGridActions from "../core/data-grid/DataGridActions";
import { viewExecution } from "../../actions/trader";

const ExecDataGrid = ({
  executions,
  scale,
  qtyScale,
  symbolcb,
  activeOrders,
  accounts,
  onView,
  showExecution
}) => {
  const getTrProps = (state, rowInfo) => {
    return {
      onClick: () => {
        symbolcb(rowInfo.original.symbol);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      },
      style: {},
    };
  };
  const columns = [
    {
      Header: "ACCOUNT",
      id: "account",
      accessor: (d) =>
        !!accounts && accounts.has(d.account)
          ? accounts.get(d.account)
          : d.account,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "FILL TIME",
      id: "transacttime",
      accessor: (d) =>
        convertDateToString(d.transactTime, "YYYYMMDD-HH:mm:ss.SSS"),
      width: 150,
      maxWidth: 250,
    },
    {
      Header: i18n.t("symbol").toUpperCase(),
      id: "symbol",
      accessor: (d) => instrumentTitle(d.symbol),
      Cell: (e) => (
        <button onClick={() => symbolcb(e.value)} className="linkbutton">
          {e.value}
        </button>
      ),
      width: 200,
      maxWidth: 300,
    },
    {
      Header: 'SUB TYPE',
      id: 'subType',
      accessor: d => d.symbolSubType,
      maxWidth: 80
    },
    {
      Header: "SIDE",
      id: "side",
      accessor: (d) => d.side.name,
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: "FILL QTY",
      id: "fillqty",
      accessor: (d) => parsePrice(d.lastShares, qtyScale[d.symbol]),
      maxWidth: 80,
    },
    {
      Header: "FILL PX",
      id: "fillpx",
      accessor: (d) =>
        validatePrice(d.lastPx) ? parsePrice(d.lastPx, scale[d.symbol]) : null,
      maxWidth: 80,
    },
    {
      Header: "ORDER PX",
      id: "orderpx",
      accessor: (d) =>
        validatePrice(d.orderPx)
          ? parsePrice(d.orderPx, scale[d.symbol])
          : null,
      maxWidth: 80,
    },
    {
      Header: "ORDER ID",
      id: "orderid",
      accessor: (d) => d.orderId,
      minWidth: 150,
      maxWidth: 250,
    },
    {
      sortable: false,
      Cell: (row) => {
        return (
          <DataGridActions
            onView={() => {
              onView(row.original.id, row.original.account);
            }}
          />
        );
      },
      maxWidth: 50,
      className: "action-column",
    },
  ];
  return (
    <div className="ExecDataGrid">
      <DataGrid
        data={executions}
        columns={columns}
        filterable={true}
        getTdProps={getTrProps}
        getTrProps={getTrProps}
        defaultSortedField="transacttime"
        defaultSortedDesc={true}
        showPagination={false}
      ></DataGrid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onView: (id, account) => {
    dispatch(viewExecution(id, account));
  },
});

const mapStateToProps = (state) => {
  const { trader } = state;
  const { executions, scale, qtyScale, showExecution, accounts } = trader;

  return { executions, scale, qtyScale, showExecution, accounts };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecDataGrid);
