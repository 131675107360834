import storage from "redux-persist/lib/storage";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_RESET,
  ACCESS_TKN_REFRESH_SUCCESS,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  SET_AUTH_TOKENS,
  SET_AUTHORIZATION
} from "../constants/actions";

const initialState = {
  id: null,
  accToken: null,
  refToken: null,
  expires: null,
  error: null,
  isPositionAuthorized: true,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      state = Object.assign({}, state, {
        accToken: payload.accToken,
        refToken: payload.refToken,
        expires: payload.expires,
        error: null,
      });
      return state;

    case CHANGE_PASSWORD_START:
      state = Object.assign({}, state, {
        showChangePassword: true
      });
      return state;

    case CHANGE_PASSWORD_SUCCESS:
      state = Object.assign({}, state, {
        showChangePassword: false
      });
      return state;

    case LOGIN_FAILURE:
      state = Object.assign({}, state, {
        error: payload.error,
      });
      return state;

    case ACCESS_TKN_REFRESH_SUCCESS:
      state = Object.assign({}, state, {
        accToken: payload.accToken,
      });
      return state;

    case LOGIN_RESET:
      storage.removeItem("persist:root");
      storage.removeItem("persist:auth");
      storage.removeItem("persist:accounts");
      storage.removeItem("persist:instruments");
      state = Object.assign({}, state, initialState);
      return state;

    case SET_AUTH_TOKENS:
      state = Object.assign({}, state, {
        accToken: payload.accToken,
        refToken: payload.refToken,
        expires: payload.expires,
        error: null,
      });
      return state;

    case SET_AUTHORIZATION:
      state = Object.assign({}, state, {
        isPositionAuthorized: payload.isPositionAuthorized,
        positionMessage: payload.positionMessage,
      });
      return state;

    default:
      return state;
  }
};

export default authReducer;
