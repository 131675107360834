import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import {initiateRefreshTokenLoop} from "./actions/auth"
import {Env} from "./constants/environment";

class App extends Component {
  constructor() {
    super();

    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_BG', Env.getEnv("REACT_APP_THEME_COLOR_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_TOP_BAR_BG', Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_BRAND")) {
      document.documentElement.style.setProperty('--APP_COLOR_TOP_BAR_BRAND', Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_BRAND"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_TOP_BAR_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_TOP_BAR_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PANEL_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_PANEL_BG', Env.getEnv("REACT_APP_THEME_COLOR_PANEL_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PANEL_TITLE")) {
      document.documentElement.style.setProperty('--APP_COLOR_PANEL_TITLE', Env.getEnv("REACT_APP_THEME_COLOR_PANEL_TITLE"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_PANEL_TITLE_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_PANEL_TITLE_BG', Env.getEnv("REACT_APP_THEME_COLOR_PANEL_TITLE_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_INPUT_BOX_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_INPUT_BOX_BG', Env.getEnv("REACT_APP_THEME_COLOR_INPUT_BOX_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_HEADER")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_HEADER', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_HEADER"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SUB_HEADER")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_SUB_HEADER', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SUB_HEADER"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_BORDER")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_BORDER', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_BORDER"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SEL_ROW_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_SEL_ROW_BG', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SEL_ROW_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SEL_ROW_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_SEL_ROW_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_SEL_ROW_CONTENT"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ICON")) {
      document.documentElement.style.setProperty('--APP_COLOR_TABLE_ICON', Env.getEnv("REACT_APP_THEME_COLOR_TABLE_ICON"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_PRIMARY")) {
      document.documentElement.style.setProperty('--APP_COLOR_BUTTON_PRIMARY', Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_PRIMARY"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_SECONDARY")) {
      document.documentElement.style.setProperty('--APP_COLOR_BUTTON_SECONDARY', Env.getEnv("REACT_APP_THEME_COLOR_BUTTON_SECONDARY"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_TAB")) {
      document.documentElement.style.setProperty('--APP_COLOR_ORDERBOOK_TAB', Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_TAB"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_TAB_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_ORDERBOOK_TAB_BG', Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_TAB_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_PX")) {
      document.documentElement.style.setProperty('--APP_COLOR_ORDERBOOK_PX', Env.getEnv("REACT_APP_THEME_COLOR_ORDERBOOK_PX"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_DIALOG_INPUT_BOX_BG")) {
      document.documentElement.style.setProperty('--APP_COLOR_DIALOG_INPUT_BOX_BG', Env.getEnv("REACT_APP_THEME_COLOR_DIALOG_INPUT_BOX_BG"));
    }
    if (!!Env.getEnv("REACT_APP_THEME_COLOR_DIALOG_INPUT_BOX_CONTENT")) {
      document.documentElement.style.setProperty('--APP_COLOR_DIALOG_INPUT_BOX_CONTENT', Env.getEnv("REACT_APP_THEME_COLOR_DIALOG_INPUT_BOX_CONTENT"));
    }
  }

  render() {
    return (
      <ConnectedRouter history={this.props.history}><Routes /></ConnectedRouter>
    );
  }  
}

initiateRefreshTokenLoop();

App.propTypes = {
  history: PropTypes.object
};

export default App;