import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormLabel
} from 'react-bootstrap';
import DateTimePickerControl from '../form/DateTimePickerControl';

const DateGroup = ({
  id,
  name,
  label,
  enableTime,
  step,
  isRequired,
  value,
  dropUp,
  minDate,
  maxDate,
  enabled,
  defaultCurrentDate,
  errors,
  touched,
  onChange,
  onBlur,
  errClassName
}) => (<FormGroup id={id}>
    {label ? <FormLabel>{label}</FormLabel> : null}
    {isRequired && <label className="form-label-required"> Required</label>}
    <DateTimePickerControl
      value={value}
      dropUp={dropUp}
      minDate={minDate}
      maxDate={maxDate}
      enableTime={enableTime}
      step={step}
      enabled={enabled}
      onChange={onChange}
      onBlur={onBlur}
      defaultCurrentDate={defaultCurrentDate}
    />
    {((errors && errors[name]) || (touched && touched[name])) && <div className={errClassName ? errClassName : "form-error"}>
      {errors[name]}</div>}
  </FormGroup>)

DateGroup.defaultProps = {
  enableTime: false,
  isRequired: false,
  enabled: true,
  step: 30,
  value: null,
  defaultCurrentDate: null
}

DateGroup.propTypes = {
  enableTime: PropTypes.bool,
  value: PropTypes.any,
  isRequired: PropTypes.bool,
  enabled: PropTypes.bool,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default DateGroup;