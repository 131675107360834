import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import DataGridActions from '../core/data-grid/DataGridActions';
import { convertDateToString, parsePrice, validatePrice } from "../../modules/util";
import _ from 'lodash';
import { connect } from 'react-redux';
import i18n from "../../i18n";
import { instrumentTitle } from "./Instruments";
import {Env} from "../../constants/environment";

const OrderDataGrid = ({
  workingOrders,
  completedOrders,
  scale,
  qtyScale,
  accounts,
  onView,
  onEdit,
  onCancel,
}) => {
  let data = Object.values(workingOrders);
  if (Env.getEnvBool("REACT_APP_HIDE_COMPLETED_ORDERS")) {
    const completed = Object.values(completedOrders);
    data.push(...completed);
  }

  let columns = [
    {
      sortable: false,
      Cell: row => {
        return row.row.leavesqty > 0 ? (
          <DataGridActions
            onView={() => { onView(row.original) }}
            onEdit={() => { onEdit(row.original) }}
            onCancel={() => { onCancel(row.original.id) }}
          />
        ) : null;
      },
      className: 'action-column',
      minWidth: 120,
    },
    {
      Header: i18n.t('symbol').toUpperCase(),
      id: 'symbol',
      accessor: d => instrumentTitle(d.symbol),
      minWidth: 100,
      maxWidth: 250
    },
    {
      Header: 'SUB TYPE',
      id: 'subType',
      accessor: d => d.symbolSubType,
      maxWidth: 80
    },
    {
      Header: 'SIDE',
      id: 'side',
      accessor: d => d.side.name,
      maxWidth: 60
    },
    {
      Header: 'QTY',
      id: 'qty',
      accessor: d => parsePrice(d.quantity, qtyScale[d.symbol]),
      maxWidth: 60
    },
    {
      Header: 'PX',
      id: 'px',
      accessor: d => _.includes(d.orderType.name.toLowerCase(), 'limit') && validatePrice(d.price) ? parsePrice(d.price, scale[d.symbol]) : null,
      maxWidth: 70
    }
  ]
  if (!Env.getEnvBool("REACT_APP_HIDE_STOP_PX")) {
    columns.push(
      {
        Header: 'STOP PX',
        id: 'stopPx',
        accessor: d => _.includes(d.orderType.name.toLowerCase(), 'stop') && validatePrice(d.stopPrice) ? parsePrice(d.stopPrice, scale[d.symbol]) : null,
        maxWidth: 70
      }
    );
  }
  columns.push(
    {
      Header: 'TYPE',
      id: 'ordertype',
      accessor: d => d.orderType.name,
      maxWidth: 80
    },
    {
      Header: 'STATUS',
      id: 'status',
      accessor: d => d.status.state.name,
      maxWidth: 80
    },
    {
      Header: 'CUM QTY',
      id: 'cumqty',
      accessor: d => parsePrice(d.status.cumQty, qtyScale[d.symbol]),
      maxWidth: 80
    },
    {
      Header: 'LEAVES QTY',
      id: 'leavesqty',
      accessor: d => parsePrice(d.status.leavesQty, qtyScale[d.symbol]),
      maxWidth: 80
    },
    {
      Header: 'ACCOUNT',
      id: 'account',
      accessor: d => !!accounts && accounts.has(d.account) ? accounts.get(d.account) : d.account,
      maxWidth: 80
    },
    {
      Header: 'TIMESTAMP',
      id: 'timestamp',
      accessor: d => convertDateToString(d.insertedOn || d.createdOn, "YYYYMMDD-HH:mm:ss.SSS"),
      minWidth: 150,
      maxWidth: 250
    }
  );
  return (
    <div className="OrderDataGrid">
      <DataGrid
        data={data}
        columns={columns}
        filterable={true}
        defaultSortedField="timestamp"
        defaultSortedDesc={true}
        showPagination={false}
      >
      </DataGrid>
    </div>
  )
};

OrderDataGrid.propTypes = {
  workingOrders: PropTypes.object.isRequired,
  completedOrders: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  scale: PropTypes.object.isRequired,
  qtyScale: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { trader } = state
  const { workingOrders, completedOrders, scale, qtyScale, accounts } = trader
  return {
    workingOrders,
    completedOrders,
    scale,
    qtyScale,
    accounts,
  }
}
export default connect(mapStateToProps, null)(OrderDataGrid);