import React, { Component } from 'react'
import Dialog from "./core/dialog/Dialog";
import { Container } from "react-bootstrap";
import { hideNotifications, moveNotification } from '../actions/notifications'
import { connect } from 'react-redux'
import moment from 'moment'

function mapStateToProps(state) {
    return {
        notifications: state.notifications
    }
}

class NotificationList extends Component {
    onClose = () => {
        this.props.hideNotifications()
    }
    componentWillUnmount() {
        this.props.moveNotification()
    }
    renderNotificationList = () => {
        const { notifications } = this.props

        let allNotifications = [...notifications.new, ...notifications.read]

        return <div className={`notification-list-container ${allNotifications.length === 0 && 'd-flex justify-content-center align-items-center'}`}>
            {allNotifications.length !== 0 ? allNotifications.map((item, _) => {
                let type, className;
                if (item.type === 'error') {
                    type = 'fa-exclamation-circle'
                    className = 'Toastify__toast--error'
                }
                else if (item.type === 'warning') {
                    type = 'fa-exclamation'
                    className = 'Toastify__toast--warning'
                }
                else if (item.type === 'info') {
                    type = 'fa-exclamation-circle'
                    className = 'Toastify__toast--info'
                }
                else if (item.type === 'success') {
                    type = 'fa-check'
                    className = 'Toastify__toast--success'
                }

                return (
                    <div className={`alert ${className}`} key={item.timestamp}>
                        <div className="container">
                            <div>
                                <div class="alert-icon">
                                    <i class={`fa ${type}`}></i>
                                </div>
                                <b>{moment(item.timestamp).format("DD/MM/YYYY hh:mm A")}</b>
                            </div>
                            {item.message}
                        </div>
                    </div>
                )
            }) : <div className='text-center'>No notifications found.</div>
            }
        </div>
    }
    render() {
        return (
            <Dialog
                title={"Notifications"}
                show={true}
                onClose={this.onClose}
                dialogClassName={'notifications-dialog'}
            >
                <div>
                    <Container>
                        {this.renderNotificationList()}
                    </Container>
                </div>
            </Dialog>
        )
    }
}

export default connect(mapStateToProps, {
    hideNotifications,
    moveNotification
})(NotificationList)