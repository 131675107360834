import { StatusCode } from "grpc-web";
import MarketService from "../services/MarketService";
import Notification from "../modules/notifications";
import { ADD_QUOTE_INSTRUMENTS, SET_LOADING_INSTRUMENTS, UPDATE_RELATED_INSTRUMENTS, FETCH_METADATA_START, FETCH_METADATA_SUCCEED, FETCH_METADATA_FAILED, SET_LOADING_SYMBOLS, ADD_SYMBOLS, ADD_WATCHLIST_INSTRUMENTS, SET_WATCHLIST_INSTRUMENTS_LOADING } from "../constants/actions";
import Instrument from "../entities/Instrument";
import { addInstruments } from "../actions/trader";
import {Env} from "../constants/environment";

export function FetchMetadata() {
  return (dispatch) => {
    dispatch({ type: FETCH_METADATA_START });

    const cb = (err, response) => {
      if (response) {
        const currencies = JSON.parse(
          response.getMetadataMap().get("currencies")
        );
        const location = response.getMetadataMap().get("location");
        dispatch({
          type: FETCH_METADATA_SUCCEED,
          payload: { currencies: currencies, location: location },
        });
      }

      if (err) {
        dispatch({ type: FETCH_METADATA_FAILED });
        Notification.error(`Cannot get instrument metadata: ${err.message}`);
      }
    };

    MarketService.getInstrumentMetadata(cb);
  };
}

export function ListInstruments(pageToken) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_INSTRUMENTS,
      payload: true,
    });
    let cb = (err, response) => {
      if (response) {
        let instrumentsList = response
          .getInstrumentsList()
          .map((instrument) => {
            return new Instrument(instrument);
          });
        dispatch({
          type: ADD_QUOTE_INSTRUMENTS,
          payload: {
            instrumentsList: instrumentsList,
            pageToken: pageToken,
            nextPageToken: response.getNextPageToken,
          },
        });

        if (!response.getEof()) {
          MarketService.listInstruments(response.getNextPageToken(), '', '', cb);
        } else {
          dispatch({
            type: SET_LOADING_INSTRUMENTS,
            payload: false,
          });
        }
      }
      if (err) {
        dispatch({
          type: SET_LOADING_INSTRUMENTS,
          payload: false,
        });
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning("Could not find any instruments");
            break;
          default:
            Notification.error(
              `Error when querying for instruments, please refresh: \n ${err.message}`
            );
            break;
        }
      }
    };

    MarketService.listInstruments(pageToken, '', '', cb);
  };
}

export function GetRelatedInstruments(symbol) {
  return (dispatch) => {
    let cb = (firstCall) => (err, response) => {
      if (response) {
        let instrumentsList = response
          .getInstrumentsList()
          .map((instrument) => {
            return new Instrument(instrument);
          });
        if (firstCall && instrumentsList.length > 0 && instrumentsList[0].productId && Env.getEnvBool("REACT_APP_SHOW_RELATED_INSTRUMENTS")) {
          MarketService.listInstruments('', '', instrumentsList[0].productId, cb(false));
        } else {
          dispatch({
            type: UPDATE_RELATED_INSTRUMENTS,
            payload: instrumentsList.map(inst => inst.symbol),
          });
        }
      }
      if (err) {
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning("Could not find any related instruments");
            break;
          default:
            Notification.error(
              `Error when querying for related instruments, please refresh: \n ${err.message}`
            );
            break;
        }
      }
    };

    MarketService.listInstruments('', symbol, '', cb(true));
  };
}

export function ListSymbols() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LOADING_SYMBOLS,
      payload: true
    })
    let cb = (err, response) => {
      if (response) {
        if (response) {
          const symbolsList = response.getSymbolsList()
          dispatch({
            type: ADD_SYMBOLS,
            payload: { symbolsList }
          })
          dispatch(ListInstrumentsForWatchList(0, currentPageSize))
        }
      }
      if (err) {
        dispatch({
          type: SET_LOADING_SYMBOLS,
          payload: false,
        });
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning("Could not find any symbols");
            break;
          default:
            Notification.error(
              `Error when querying for symbols, please refresh: \n ${err.message}`
            );
            break;
        }
      }
    };
    const currentPageSize = getState().watchList.currentPageSize || 10
    MarketService.listSymbols(cb);
  }
}

export function ListSelectedInstruments() {
  return (dispatch, getState) => {
    const state = getState();
    const selected = state.watchList.instruments;

    if (selected.length > 0) {
      PerformListInstrumentsForSymbols(selected, dispatch);
    }
  };
}

export function ListInstrumentsForWatchList(pageNumber, pageSize) {
  return (dispatch, getState) => {
    const state = getState();
    const { watchListData } = state.watchList;
    const traderInstruments = state.trader.instruments;
    let symbolsCriteria = Object.keys(watchListData)
    const totalRecords = pageNumber * pageSize

    if (symbolsCriteria && symbolsCriteria.length >= totalRecords) {
      let startIndex = pageNumber === 0 ? 0 : (pageSize * pageNumber)
      let endIndex = pageNumber === 0 ? pageSize : (pageNumber + 1) * pageSize
      symbolsCriteria = symbolsCriteria.slice(startIndex, endIndex)
    }

    const loadedSymbols = Object.keys(traderInstruments);
    const symbols = [];
    symbolsCriteria.forEach(symbol => {
      if (loadedSymbols.indexOf(symbol) === -1) {
        symbols.push(symbol);
      }
    });

    if (symbols.length > 0) {
      PerformListInstrumentsForSymbols(symbols, dispatch);
    }
  }
}

export function ListInstrumentsForSymbols(symbols) {
  return (dispatch, getState) => {
    PerformListInstrumentsForSymbols(symbols, dispatch);
  }
}

function PerformListInstrumentsForSymbols(symbols, dispatch) {
    dispatch({
      type: SET_WATCHLIST_INSTRUMENTS_LOADING,
      payload: true,
    });

    let cb = (err, response) => {
      if (response) {
        let instrumentsList = response
          .getInstrumentsList()
          .map((instrument) => {
            return new Instrument(instrument);
          });
        dispatch({
          type: ADD_WATCHLIST_INSTRUMENTS,
          payload: {
            instrumentsList,
          },
        });

        dispatch(addInstruments(instrumentsList));

        dispatch({
          type: ADD_QUOTE_INSTRUMENTS,
          payload: { instrumentsList  }
        });

        if (!response.getEof()) {
          MarketService.listInstruments(response.getNextPageToken(), '', '', cb);
        } else {
          dispatch({
            type: SET_WATCHLIST_INSTRUMENTS_LOADING,
            payload: false,
          });
        }
      }
      if (err) {
        dispatch({
          type: SET_WATCHLIST_INSTRUMENTS_LOADING,
          payload: false,
        });
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning("Could not find any instruments");
            break;
          default:
            Notification.error(
              `Error when querying for instruments, please refresh: \n ${err.message}`
            );
            break;
        }
      }
    };

    MarketService.listInstruments('', symbols, '', cb);
}
