import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { logout } from "../../../actions/auth";
import { cancelAllOrders } from "../../../actions/orders";
import { CancelAllQuotes } from "../../../actions/quotes";
import confirm from "../../../modules/confirmDialog";
import { showWatchList } from "../../../actions/watchlist";
import { showDownloadTrades } from "../../../actions/downloadTrades";
import { CHANGE_PASSWORD_START } from "../../../constants/actions"
import { showNotifications } from "../../../actions/notifications";
import { Env } from "../../../constants/environment";

const mapDispatchToProps = (dispatch) => ({
  performLogout: (auth) => {
    dispatch(logout(auth.accToken, auth.refToken));
  },
  performChangePassword: (auth) => {
    dispatch({ type: CHANGE_PASSWORD_START });
  },
  cancelAllOrdersAndQuotes: () => {
    dispatch(cancelAllOrders());
    dispatch(CancelAllQuotes());
  },
  showWatchList: () => {
    dispatch(showWatchList());
  },
  downloadTrades: () => {
    dispatch(showDownloadTrades());
  },
  showNotifications: () => {
    dispatch(showNotifications())
  }
});

class Menu extends Component {
  onRemoveMyOrders() {
    confirm("Are you sure you want to remove all orders and quotes?", {
      title: "Remove all Orders & Quotes",
      okButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      () => {
        this.props.cancelAllOrdersAndQuotes();
      },
      () => { }
    );
  }

  render() {
    return (
      <Navbar variant="dark" expand="lg" fixed="top">
        <Navbar.Brand>
          <img
            alt=""
            src={Env.getLogoMainApp()}
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        {!Env.getEnvBool("REACT_APP_HIDE_TITLE") && (
          <Navbar.Brand>{document.title}</Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/trader" title="Orders">
              <i
                className="fa fa-book-open "
                style={{ fontSize: "23px", paddingTop: "5px" }}
              />
            </Nav.Link>
            <Nav.Link href="/quotes" title="Quotes">
              <i
                className="fa fa-search"
                style={{ fontSize: "23px", paddingTop: "5px" }}
              />
            </Nav.Link>
            <Nav.Link title="Instrument Watchlist"
              disabled={this.props.loadingInstruments}
              onClick={() => {
                this.props.showWatchList();
              }}
            >
              <i
                className="fa fa-eye"
                style={{ fontSize: "23px", paddingTop: "5px" }}
              />
            </Nav.Link>
            <Nav.Link title="Download Trades" disabled={this.props.loadingInstruments} onClick={() => { this.props.downloadTrades(); }}>
              <i className="fa fa-download"
                style={{ fontSize: "23px", paddingTop: "5px" }}
                aria-hidden="true"></i>
            </Nav.Link>
            <Nav.Link title="Notifications" onClick={() => { this.props.showNotifications(); }}>
              <i className="fa fa-bell"
                style={{ fontSize: "23px", paddingTop: "5px" }}
                aria-hidden="true">
                {
                  this.props.notifications.new.length !== 0 && <div className="new-notification-indicator"></div>
                }

              </i>

            </Nav.Link>

            <NavDropdown
              title={this.props.userDisplayName}
              id="basic-nav-dropdown"
              style={{ fontSize: "1.25em" }}
            >
              <NavDropdown.Item
                onClick={() => this.props.performLogout(this.props.auth)}
              >
                Logout
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => this.props.performChangePassword(this.props.auth)}
              >
                Change Password
              </NavDropdown.Item>
            </NavDropdown>
            <Navbar.Text style={{ paddingTop: "15px", paddingBottom: "0px" }}>
              {Env.getEnv("REACT_APP_VERSION")}
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand><span className="navbar-firm" >{this.props.firmDisplayName}</span></Navbar.Brand>
        <Button
          className="btn-main"
          variant="danger"
          onClick={() => {
            this.onRemoveMyOrders();
          }}
        >
          Remove My Orders & Quotes
        </Button>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userDisplayName: state.trader.trader
    ? state.trader.trader.userDisplayName
    : "",
  firmDisplayName: state.trader.trader
    ? state.trader.trader.firmDisplayName
    : "",
  loadingInstruments: state.quotes.loadingInstruments,
  notifications: state.notifications
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
