import React from "react";
import { connect } from "react-redux";
import { Field, ErrorMessage, withFormik } from "formik";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import { Button } from "react-bootstrap";
import {
  TimeInForce,
  OrderType,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import i18n from "i18next";
import {convertScaledPriceToInt, getFirmUserName, parsePrice, validScaledPrice} from "../../../modules/util";
import Quote from "../../../../src/entities/Quote";
import Notification from "../../../modules/notifications";
import {Env} from "../../../constants/environment";

const mapStateToProps = (state) => {
  return {
    accounts: state.trader.accounts,
    users: state.trader.users,
    usersToAccounts: state.trader.usersToAccounts,
    trader: state.trader.trader,
    requestForQuotes: state.quotes.requestForQuotes,
    quote: state.quotes.appContext.id
      ? state.quotes.quotes[state.quotes.appContext.id]
      : {},
    account:
      state.quotes.appContext.id && state.quotes.requestForQuotes
        ? Object.values(state.quotes.requestForQuotes).find(
            (item) =>
              item.name ===
              state.quotes.quotes[state.quotes.appContext.id].quoteRequestId
          ).account
        : null,
    user:
        state.quotes.appContext.id && state.quotes.requestForQuotes
            ? Object.values(state.quotes.requestForQuotes).find(
            (item) =>
                item.name ===
                state.quotes.quotes[state.quotes.appContext.id].quoteRequestId
            ).user
            : null,
  };
};

const QuoteOrderTicketForm = (props) => {
  const {
    errors,
    handleSubmit,
    quote,
    onPass,
    onDelete,
    requestForQuotes,
    accounts,
    users,
  } = props;
  const RFQID = quote.quoteRequestId.replace("requestForQuotes/", "");

  const canPass = !!requestForQuotes[RFQID] && !!users && users.has(requestForQuotes[RFQID].user);
  const canDelete = !!users && users.has(quote.user);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="id">
            Quote
          </label>
          <div key="account">
            Account: <b>{ accounts.get(quote["account"].toString())}</b>
          </div>
          <div key="expiredOn">
            Quote Expiration Time: <b>{quote["expiredOn"].toString()}</b>
          </div>
          <div key="firm">
            Firm: <b>{ getFirmUserName(quote["firm"], "firms") }</b>
          </div>
          <div key="id">
            Quote ID: <b>{quote["id"].toString()}</b>
          </div>
          <div key="createdOn">
            Quote Creation Time: <b>{quote["createdOn"].toString()}</b>
          </div>
          <div key="orderQty">
            {i18n.t("quantity")}: <b>{parsePrice(quote["orderQty"], props.values.qtyScale)}</b>
          </div>
          <div key="price">
            {i18n.t("price")}: <b>{parsePrice(quote["price"], props.instrument.scale)}</b>
          </div>
          <div key="quoteRequestId">
            RFQ ID: <b>{quote["quoteRequestId"].toString()}</b>
          </div>
          <div key="sideName">
            Side:{" "}
            <b>
              {i18n.t(
                quote["sideName"].toString().toLowerCase(),
                quote["sideName"].toString()
              )}
            </b>
          </div>
          <div key="statusName">
            Quote Status: <b>{quote["statusName"].toString()}</b>
          </div>
          <div key="symbol">
            Security Symbol: <b>{quote["symbol"].toString()}</b>
          </div>
          <div key="user">
            User: <b>{ getFirmUserName(quote["user"], "users") }</b>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="account">
            Account
          </label>
          <Field
              id="account"
              name="account"
              className="form-control-plaintext"
              type="text"
              value={!!accounts && accounts.has(props.values.account) ? accounts.get(props.values.account) : props.values.account}
              readOnly
          />
        </div>
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="user">
            User
          </label>
          <Field
              id="user"
              name="user"
              className="form-control-plaintext"
              type="text"
              value={!!users && users.has(props.values.user) ? users.get(props.values.user) : props.values.user}
              readOnly
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="quantity">
            {i18n.t("quantity")}
          </label>
          <Field
            id="quantity"
            name="quantity"
            placeholder={i18n.t("quantity")}
            type="text"
            className={
              errors.quantity
                ? `text-input error form-control`
                : `text-input form-control`
            }
          />
          <ErrorMessage
            name="quantity"
            component="div"
            className="form-input-error"
          />
        </div>
      </div>
      {!Env.getEnvBool("REACT_APP_HIDE_ALL_OR_NONE") && <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm form-check-label" htmlFor="allOrNone">All or None</label>
          <Field type ="checkbox" id="allOrNone" name="allOrNone" className="allOrNone-checkbox" disabled={ Env.getEnvBool("REACT_APP_LOCK_ALL_OR_NONE") } checked={props.values.allOrNone} />
        </div>
        <div className="form-group col"></div>
      </div>}
      <div className="form-row">
        <div className="col text-right">
          <Button variant={"outline-success"} type="submit">
            Accept
          </Button>
        </div>
        <div className="col-2" />
        {canDelete && (
          <div className="col">
            <Button variant={"outline-danger"} onClick={() => onDelete(quote)}>
              Cancel
            </Button>
          </div>
        )}
        {canPass && (
          <div className="col">
            <Button variant={"outline-danger"} onClick={() => onPass(quote)}>
              Pass
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

const formatValues = (values, props) => {
  let side = Side.SIDE_UNDEFINED;
  if (props.quote.sideId === Side.SIDE_BUY) {
    side = Side.SIDE_SELL;
  } else if (props.quote.sideId === Side.SIDE_SELL) {
    side = Side.SIDE_BUY;
  }
  
  return {
    quantity: values.quantity,
    qtyScale: values.qtyScale,
    name: props.quote.name,
    clordId: props.quote.clordId,
    side: side,
    account: values.account,
    user: values.user,
    timeInForce: TimeInForce.TIME_IN_FORCE_IMMEDIATE_OR_CANCEL,
    orderType: OrderType.ORDER_TYPE_LIMIT,
    price: props.quote.price,
    priceScale: props.instrument.scale,
    quote: props.quote.name,
    allOrNone: values.allOrNone || false
  };
};

const EnhancedQuoteResponseOrderTicketForm = withFormik({
  mapPropsToValues: (props) => {
      const qtyScale = props.symbol && props.instruments[props.symbol]
          ? props.instruments[props.symbol].qtyScale
          : 0;
      return {
        quote: props.quote,
        quantity: props.quote ? parsePrice(props.quote.orderQty, qtyScale) : 0,
        dealerAccount: props.quote ? props.quote.account : null,
        minimumTradeQty:
          props.symbol && props.instruments[props.symbol]
            ? props.instruments[props.symbol].minimumTradeQty
            : 0,
        account: props.account || null,
        user: props.user || null,
        allOrNone: Env.getEnvBool("REACT_APP_ALL_OR_NONE_DEFAULT"),
        qtyScale: qtyScale,
      }
  },
  validate: (values) => {
    let errors = {};

    if (!values.quantity || Number.isNaN(parseFloat(values.quantity)) || !values.quote) {
      errors.quantity = `${i18n.t("quantity")} is required`;
    } else if (parseFloat(values.quantity) <= 0) {
      errors.quantity = `${i18n.t("quantity")} must be > 0`;
    } else if (!validScaledPrice(values.quantity, values.qtyScale)) {
      errors.quantity = `${i18n.t("quantity")} has too many decimal places`;
    } else if (convertScaledPriceToInt(values.quantity, values.qtyScale) % values.minimumTradeQty > 0) {
      errors.quantity = `${i18n.t("quantity")} must be multiple of ${parsePrice(values.minimumTradeQty, values.qtyScale)}`;
    } else if (convertScaledPriceToInt(values.quantity, values.qtyScale) > values.quote.orderQty) {
      errors.quantity = `${i18n.t("quantity")} must be <= ${parsePrice(values.quote.orderQty, values.qtyScale)}`;
    }

    return errors;
  },
  handleSubmit: (values, { props }) => {
    if (Quote.isExpiredOrRejectedOrDeleted(props.quote.statusName)) {
      Notification.error(`This quote is ${props.quote.statusName}`)
      return;
    }
    props.doSubmit(formatValues(values, props));
  },
  displayName: "QuoteOrderTicketForm", // helps with React DevTools
})(QuoteOrderTicketForm);

export default connect(
  mapStateToProps,
  null
)(EnhancedQuoteResponseOrderTicketForm);
