import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

const AccordionMain = ({ title, children }) => {
  const [currentKey, setCurrentKey] = useState(null);

  return (
    <Accordion onSelect={(e) => setCurrentKey(e)}>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <div style={{ fontWeight: "bold" }}>
            {title}
            <span style={{ float: "right" }}>
              {currentKey === "0" ? "-" : "+"}
            </span>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AccordionMain;
