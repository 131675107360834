import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dashboard from 'react-dazzle';
import i18n from "i18next";

import { SubscribeBook, subscribeBook } from "../actions/books";
import Instruments from '../components/book/Instruments';
import Positions from '../components/book/Positions';
import OrderDataGrid from '../components/book/OrderDataGrid';
import ExecDataGrid from '../components/book/ExecDataGrid';
import { TraderDocumentTitle } from '../constants/strings';
import OrderBook from "../components/book/OrderBook";
import { ListAccounts } from "../actions/accounts";
import { subscribeOrders, GetWhoAmI } from '../actions/orders';
import Order from '../entities/Order';
import Execution from '../entities/Execution';
import OrderTicket from "../components/OrderTicket";
import AmendTicket from "../components/AmendTicket";
import ViewPosition from "../components/ViewPosition";
import CancelConfirm from "../components/CancelConfirm";
import OrderService, { ExecutionType } from "../services/OrderService";
import BookService from "../services/BookService";
import Notification from "../modules/notifications";
import { setSymbol, updateBook, addInstrument, updateInstrument, addInstruments } from '../actions/trader';
import { subscribePositions, updatePositions } from '../actions/positions';
import { updateCompletedOrders, loadCompletedOrders } from '../actions/completedOrders';
import CompletedOrdersDataGrid from "../components/book/CompletedOrdersDataGrid";
import { updateExecutions } from '../actions/executions';
import SwapDetails from "../components/quote/SwapDetails";
import { FetchMetadata, GetRelatedInstruments, ListSymbols, ListSelectedInstruments, ListInstrumentsForWatchList } from "../actions/instruments";
import { SubscribeQuotes } from "../actions/quotes";
import { SetGridContext } from "../actions/app";
import { setCurrentPageSize } from "../actions/watchlist"
import ViewExecution from "../components/ViewExecution";
import ViewOrderExecution from "../components/ViewOrderExecution"
import { isPopup } from "../components/shared/AdminWrapper";
import WatchList from "../components/WatchList";
import DownloadTrades from '../components/DownloadTrades';
import ChangePassword from "../components/shared/ChangePassword"
import NotificationList from '../components/NotificationList';
import { refreshTokens } from "../modules/apiCall";
import {Env} from "../constants/environment";

const queryString = require('query-string');

function mapStateToProps(state) {
  return {
    relatedInstruments: state.trader.relatedInstruments,
    instruments: state.trader.instruments,
    viewSwapDetail: state.quotes.appContext.viewSwapDetail,
    accounts: state.trader.accounts,
    users: state.trader.users,
    usersToAccounts: state.trader.usersToAccounts,
    firms: state.trader.firms,
    firmsToUsers: state.trader.firmsToUsers,
    watchList: state.watchList,
    downloadTrades: state.downloadTrades,
    sessionId: state.trader.sessionId,
    showChangePassword: state.auth.showChangePassword,
    notifications: state.notifications,
    scale: state.trader.scale,
    qtyScale: state.trader.qtyScale,
    isPositionAuthorized: state.auth.isPositionAuthorized,
    positionMessage: state.auth.positionMessage,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchMetadata: () => {
    dispatch(FetchMetadata());
  },
  performSetSymbol: (symbol, subType) => {
    dispatch(setSymbol(symbol, subType))
  },
  subscribeBook: (payload) => {
    dispatch(SubscribeBook(payload));
  },
  getRelatedInstruments: (symbol) => {
    dispatch(GetRelatedInstruments(symbol))
  },

  setGridContext: (payload) => {
    dispatch(SetGridContext(payload));
  },

  performUpdateBook: (book, bookHidden) => {
    dispatch(updateBook(book, bookHidden))
  },

  performAddInstrument: (symbol, scale, qtyScale, multiplier, baseCurrency) => {
    dispatch(addInstrument(symbol, scale, qtyScale, multiplier, baseCurrency))
  },
  performAddInstruments: (instruments) => {
    dispatch(addInstruments(instruments))
  },

  performUpdateInstrument: (symbol, book, status) => {
    dispatch(updateInstrument(symbol, book, status))
  },

  performUpdatePositions: (positions) => {
    dispatch(updatePositions(positions))
  },
  performUpdateCompletedOrders: (orders) => {
    dispatch(updateCompletedOrders(orders));
  },
  performUpdateExecutions: (executions) => {
    dispatch(updateExecutions(executions));
  },
  performLoadCompletedOrders: () => {
    dispatch(loadCompletedOrders());
  },
  subscribeQuotes: () => {
    return dispatch(SubscribeQuotes());
  },
  subscribeOrders: (cbSnapshot, cbUpdate, cbRej) => {
    return dispatch(subscribeOrders(cbSnapshot, cbUpdate, cbRej));
  },
  listAccounts: () => {
    dispatch(ListAccounts());
  },
  getWhoAmI: () => {
    dispatch(GetWhoAmI());
  },
  listSymbols: () => {
    dispatch(ListSymbols())
  },
  listSelectedInstruments: () => {
    dispatch(ListSelectedInstruments())
  },
  setPageSize: (size) => {
    dispatch(setCurrentPageSize(size))
  },
  listInstrumentsForWatchList: (pageNumber, pageSize) => {
    dispatch(ListInstrumentsForWatchList(pageNumber, pageSize))
  }
});

class Trader extends Component {
  constructor(props) {
    super(props);
    this.placingOrder = false;
    this.placingCross = false;
    this.executionSubInstance = null;
    this.aggregateSubInstance = null;
    this.positionsSubInstance = null;
    this.fullBookSubInstance = null;
    this.aggregatedTopOfBooks = new Map();
    this.activeOrdersIdx = new Map();
    this.activeOrdersEntries = [];
    this.activeExecsIdx = new Map();
    this.activeExecsEntries = [];
    this.elementRef = React.createRef();
    this.viewOnlyOrder = false;
    this.editingOrder = null;
    this.cancelingOrder = null;
    this.symbol = new Map();
    this.quoteSubscription = null;
    this.setStateIfMounted = () => {
      if (this.elementRef.current) {
        this.setState({});
      }
    }
    this.subscribedItems = [];

    this.popupSymbol = (symbol) => {
      const features = 'resizable=yes; status=no; scroll=no; help=no; center=yes; width=460; height=640; menubar=no; directories=no; location=no; modal=yes';
      window.open(
        window.location.pathname + "?popup=true#symbol=" + encodeURIComponent(symbol),
        symbol,
        features,
        false
      )
    }

    this.instrumentSetSymbolCB = (symbol) => {
      const inst = this.props.instruments[symbol];
      const subType = (!!inst && inst.eventAttributes.length > 0) ? inst.eventAttributes[0].symbol : "";
      const symbolAndSubType = symbol + (!!subType ? "::" + subType : "");
      this.setSymbol(symbolAndSubType, false);
    }

    this.setSymbol = (symbols, shouldBroadcast = true) => {
      let symbol = "";
      let subType = "";

      if (!!symbols && symbols.indexOf("::") >= 0) {
        const keys = symbols.split("::");
        symbol = keys[0];
        subType = keys[1];
      } else {
        symbol = symbols;
      }

      if (shouldBroadcast && typeof fin !== 'undefined') {
        // eslint-disable-next-line no-undef
        fin.desktop.InterApplicationBus.send(fin.desktop.Application.getCurrent().uuid, "setSymbol", symbol);
      }

      if (!!symbol) {
        window.history.replaceState(undefined, undefined, "#symbol=" + symbols);

        this.props.setGridContext({
          id: symbol,
          symbol: symbol,
          subType: subType,
          context: "instruments"
        });

        this.props.performSetSymbol(symbol, subType);
        this.props.subscribeBook(symbol);
        this.props.getRelatedInstruments(symbol);

        this.symbol.set('symbol', symbol);
        this.symbol.set('subType', subType);

        this.setStateIfMounted();
      }
    };

    this.setViewingOrder = (order) => {
      this.viewOnlyOrder = true;
      this.editingOrder = order.id;
      const subType = order.symbolSubType
      const key = order.symbol + (!!subType ? "::" + subType : "");
      this.setSymbol(key);
    };

    this.setEditingOrder = (order) => {
      this.viewOnlyOrder = false;
      this.editingOrder = order.id;
      const subType = order.symbolSubType
      const key = order.symbol + (!!subType ? "::" + subType : "");
      this.setSymbol(key);
    };

    this.setCancelingOrder = (id) => {
      this.viewOnlyOrder = false;
      this.cancelingOrder = id;
      this.setStateIfMounted();
    };

    this.isMasterWindow = () => {
      if (typeof fin !== 'undefined') {
        /* eslint-disable */
        const app = fin.desktop.Application.getCurrent();
        const win = fin.desktop.Window.getCurrent();
        return win.name === app.uuid
        /* eslint-enable */
      }
      return !isPopup();
    };

    this.getLayout = () => {
      if (typeof fin !== 'undefined') {
        /* eslint-disable */
        const win = fin.desktop.Window.getCurrent();
        var windowName = win.name;
        if (this.isMasterWindow()) {
          // Spawn the child windows
          var pwin = new fin.desktop.Window({ name: 'PositionsWidget', url: location.href }, () => pwin.show());
          var owin = new fin.desktop.Window({ name: 'OrderDataGridWidget', url: location.href }, () => owin.show());
          var cwin = new fin.desktop.Window({ name: 'CompletedOrdersDataGridWidget', url: location.href }, () => cwin.show());
          var ewin = new fin.desktop.Window({ name: 'ExecDataGridWidget', url: location.href }, () => ewin.show());
          var bwin = new fin.desktop.Window({ name: 'CurrentBookWidget', url: location.href }, () => bwin.show());
          windowName = 'BookDataGridWidget';
        }
        return {
          rows: [{
            columns: [{
              className: 'col-lg-12',
              widgets: [{ key: windowName }],
            }],
          }],
        }
        /* eslint-enable */
      } else if (isPopup()) {
        return {
          rows: [{
            columns: [{
              className: 'col-lg-12',
              widgets: [{ key: 'CurrentBookWidget' }],
            }],
          }],
        }
      }

      let grids = [{ key: 'BookDataGridWidget' }];

      if (!Env.getEnvBool("REACT_APP_HIDE_POSITIONS")) {
        grids.push({ key: 'PositionsWidget' });
      }

      grids.push({ key: 'OrderDataGridWidget' });

      if (!Env.getEnvBool("REACT_APP_HIDE_EXECUTIONS")) {
        grids.push({ key: 'ExecDataGridWidget' });
      }

      if (!Env.getEnvBool("REACT_APP_HIDE_COMPLETED_ORDERS")) {
        grids.push({ key: 'CompletedOrdersDataGridWidget' });
      }

      return {
        rows: [{
          columns: [{
            className: 'col-lg-3 col-md-12',
            widgets: [{ key: 'CurrentBookWidget' }],
          }, {
            className: 'col-lg-9 col-md-12',
            widgets: grids,
          }],
        }],
      }
    };

    this.state = {
      widgets: {
        CurrentBookWidget: {
          type: OrderBook,
          title: i18n.t("order_book"),
          props: {
            onPlaceOrder: (e, values) => {
              this.placingCross = false;
              this.placingOrder = values ? values : true;
              this.setStateIfMounted();
            },
            onPlaceCross: (e, values) => {
              this.placingCross = true;
              this.placingOrder = values ? values : true;
              this.setStateIfMounted();
            },
            canCross: () => !!this.props.users && this.props.users.size > 1,
            symbolcb: this.setSymbol,
            symbolpopup: this.popupSymbol,
          }
        },
        BookDataGridWidget: {
          type: Instruments,
          title: i18n.t("instruments"),
          props: {
            symbolcb: this.instrumentSetSymbolCB,
            symbolpopup: this.popupSymbol,
            onPageRowsChange: (items = []) => this.subscribeBooksOfWatchlistItems(items)
          }
        },
        PositionsWidget: {
          type: Positions,
          title: 'Positions',
        },
        OrderDataGridWidget: {
          type: OrderDataGrid,
          title: !Env.getEnvBool("REACT_APP_HIDE_COMPLETED_ORDERS") ? 'Working Orders' : 'Orders',
          props: {
            onView: this.setViewingOrder,
            onEdit: this.setEditingOrder,
            onCancel: this.setCancelingOrder,
          }
        },
        CompletedOrdersDataGridWidget: {
          type: CompletedOrdersDataGrid,
          title: 'Order Fill & Completion Status',
        },
        ExecDataGridWidget: {
          type: ExecDataGrid,
          title: 'Executions',
          props: {
            activeOrders: this.activeOrdersIdx,
            symbolcb: this.setSymbol,
          }
        }
      },
      layout: this.getLayout()
    };

    this.subscribeBooksOfWatchlistItems = (items = []) => {
      let difference = items.filter((x) => !this.subscribedItems.includes(x));
      if (!difference || difference.length < 1) return;

      if (this.aggregateSubscription) this.aggregateSubscription.cancel();
      this.subscribedItems = [...items];

      this.aggregateSubInstance = subscribeBook([...items], (symbol, book, status) => {
        if (!this.props.instruments[symbol]) {
          BookService.getInstrument(symbol, (symbol, scale, qtyScale, multiplier, baseCurrency, minimumTradeQty) => {
            this.scale.set(symbol, scale);
            this.qtyScale.set(symbol, qtyScale);
            this.props.performAddInstrument(symbol, scale, qtyScale, multiplier, baseCurrency, minimumTradeQty);
            this.props.performUpdateInstrument(symbol, book, status);
            this.setStateIfMounted();
          });
        }
        else {
          this.props.performUpdateInstrument(symbol, book, status);
        }

        if (!this.aggregatedTopOfBooks.has(symbol)) {
          var startOfDay = new Date();
          startOfDay.setHours(0, 0, 0, 0);

          //If this is the first time seeing the book, query history search
          OrderService.searchHistory((exec) => {
            this.updateExecutions(exec);
            this.setStateIfMounted();
          }, symbol, startOfDay, [ExecutionType.EXECUTION_TYPE_FILL, ExecutionType.EXECUTION_TYPE_PARTIAL_FILL]);
        }
        this.aggregatedTopOfBooks.set(symbol, book);

      });
    }

    this.updateExecutions = (exec) => {
      if (exec) {
        const execItem = new Execution(exec);
        if (execItem.isFill) {
          this.props.performUpdateExecutions([exec]);
        } else if (execItem.isReject) {
          Notification.error(`Submission Rejected: ${execItem.text}`);
        }
      }
    };
  }

  async UNSAFE_componentWillMount() {
    await this.props.listAccounts();
  }

  refreshAuthTokensAndReSubscribe = () => {
    refreshTokens(() => {
      this.registerServices();
    })
  }

  componentDidMount() {
    document.title = TraderDocumentTitle;
    this.props.listSymbols();
    this.props.listSelectedInstruments();
    this.registerServices()
  }

  registerServices = () => {
    this.cancelServicesSubscription();

    this.props.fetchMetadata();
    const updateOrders = (orders) => {
      const ordItems = orders.map(order => new Order(order));
      ordItems.forEach((ordItem) => {
        if (ordItem.status.leavesQty > 0) {
          if (this.activeOrdersIdx.has(ordItem.id)) {
            this.activeOrdersEntries[this.activeOrdersIdx.get(ordItem.id)] = ordItem;
          } else {
            this.activeOrdersIdx.set(ordItem.id, this.activeOrdersEntries.length);
            this.activeOrdersEntries.push(ordItem);
          }
        } else if (this.activeOrdersIdx.has(ordItem.id)) {
          const removedIdx = this.activeOrdersIdx.get(ordItem.id);
          this.activeOrdersEntries.splice(removedIdx, 1);
          this.activeOrdersIdx.delete(ordItem.id);
          for (var i = removedIdx; i < this.activeOrdersEntries.length; i++) {
            this.activeOrdersIdx.set(this.activeOrdersEntries[i].id, i);
          }
        }
      });
    };

    this.executionSubInstance = this.isMasterWindow() ? this.props.subscribeOrders((orders) => {
      updateOrders(orders);
      this.props.performUpdateCompletedOrders(orders);
      this.setStateIfMounted();
    }, (exec) => {
      this.updateExecutions(exec);
      this.setStateIfMounted();
    }, (rej) => {
      Notification.error(`Submission Rejected: ${rej.getText()}`);
    }) : null;

    //load completed orders of last 24 hours..
    this.props.performLoadCompletedOrders();

    //subscribe to positions
    this.positionsSubInstance = subscribePositions((update) => this.props.performUpdatePositions(update.getPositionsList()));

    if (typeof fin !== 'undefined') {
      const setSymbolFromSubscribe = (symbol, uuid, name) => {
        setTimeout(() => this.setSymbol(symbol, false), 1);
      };
      /* eslint-disable */
      fin.desktop.InterApplicationBus.subscribe(fin.desktop.Application.getCurrent().uuid, "setSymbol", setSymbolFromSubscribe);
      /* eslint-enable */
    }

    setTimeout(() => this.setSymbol(queryString.parse(window.location.hash).symbol), 1);
    this.props.getWhoAmI()
    //this.quoteSubscription = this.props.subscribeQuotes();
  }

  cancelServicesSubscription = () => {
    if (this.aggregateSubInstance) {
      this.aggregateSubInstance.cancel();
    }
    if (this.executionSubInstance) {
      this.executionSubInstance.cancel();
    }
    if (this.fullBookSubInstance) {
      this.fullBookSubInstance.cancel();
    }
   /*  if (this.quoteSubscription) {
      this.quoteSubscription.cancel();
    } */
    if (this.positionsSubInstance) {
      this.positionsSubInstance.cancel();
    }
  }

  componentWillUnmount() {
    this.cancelServicesSubscription();
  }

  static contextTypes = {
    router: PropTypes.object
  };

  onWatchListPageSizeChange = async (pageNumber, pageSize) => {
    await this.props.setPageSize(pageSize)
    await this.props.listInstrumentsForWatchList(pageNumber, pageSize)
  }

  render() {
    let minTradeQty = this.props.instruments && this.symbol.get('symbol') && this.props.instruments[this.symbol.get('symbol')] ? this.props.instruments[this.symbol.get('symbol')].minimumTradeQty : null;

    return (
      <div className="with-callback page-container" ref={this.elementRef}>

        {this.state && this.state.layout && <Dashboard className="container"
          layout={this.state.layout}
          widgets={this.state.widgets}
        />}

        <OrderTicket
          show={this.symbol.get('symbol') && !!this.placingOrder}

          onClose={() => {
            this.placingCross = false;
            this.placingOrder = false;
            this.editingOrder = null;
            this.viewOnlyOrder = false;
            this.setStateIfMounted();
          }
          }

          symbol={this.symbol.get('symbol')}
          minTradeQty={minTradeQty}
          subType={this.symbol.get('subType')}
          accounts={this.props.accounts}
          users={this.props.users}
          instruments={this.props.instruments}
          usersToAccounts={this.props.usersToAccounts}
          firms={this.props.firms}
          firmsToUsers={this.props.firmsToUsers}
          placingOrder={this.placingOrder}
          placingCross={this.placingCross}
          priceScale={this.props.scale[this.symbol.get('symbol')]}
          qtyScale={this.props.qtyScale[this.symbol.get('symbol')]}
          sessionId={this.props.sessionId}
          onSubmit={(values) => {
            this.placingCross = false;
            this.placingOrder = false;
            this.editingOrder = null;
            this.viewOnlyOrder = false;
            this.setStateIfMounted();
          }}
        />
        <CancelConfirm
          order={this.activeOrdersEntries[this.activeOrdersIdx.get(this.cancelingOrder)]}
          onClose={() => {
            this.cancelingOrder = null;
            this.setState({});
          }
          }
        />
        <AmendTicket
          show={this.activeOrdersEntries[this.activeOrdersIdx.get(this.editingOrder)] != null}

          onClose={() => {
            this.placingCross = false;
            this.placingOrder = false;
            this.editingOrder = null;
            this.viewOnlyOrder = false;
            this.setStateIfMounted();
          }
          }

          readonly={this.viewOnlyOrder}
          symbol={this.symbol.get('symbol')}
          subType={this.symbol.get('subType')}
          accounts={this.props.accounts}
          instruments={this.props.instruments}
          defaultAccount={this.props.auth.user}
          priceScale={this.props.scale[this.symbol.get('symbol')]}
          qtyScale={this.props.qtyScale[this.symbol.get('symbol')]}
          original={this.activeOrdersEntries[this.activeOrdersIdx.get(this.editingOrder)]}
          onSubmit={(values) => {
            this.placingCross = false;
            this.placingOrder = false;
            this.editingOrder = null;
            this.viewOnlyOrder = false;
            this.setStateIfMounted();
          }}
        />
        {this.props.isPositionAuthorized && <ViewPosition accounts={this.props.accounts} />}
        <ViewExecution accounts={this.props.accounts} />
        <ViewOrderExecution accounts={this.props.accounts} />
        {this.props.viewSwapDetail && <SwapDetails />}
        {this.props.watchList.show && <WatchList onWatchListPageSizeChange={this.onWatchListPageSizeChange}></WatchList>}
        {this.props.downloadTrades.show && <DownloadTrades></DownloadTrades>}
        <ChangePassword show={this.props.showChangePassword} />
        {this.props.notifications.show && <NotificationList></NotificationList>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Trader);
