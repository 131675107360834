import { UPDATE_EXECUTIONS } from '../constants/actions';

export const executions = (state = [], action) => {
    switch (action.type) {
        case UPDATE_EXECUTIONS:
            let newState = [...state];

            action.executions.forEach((e) => {
                let stored = state.find((ex) => { return ex.id === e.id; });

                if (stored === undefined) {
                    newState.push(e);
                } else {
                    newState[newState.indexOf(stored)] = Object.assign({}, stored, e);
                }
            });

            return newState;

        default:
            return state;
    }
};