import React from "react";
import { Field, withFormik } from "formik";
import PropTypes from "prop-types";
import { convertDateToString } from "../../modules/util";

const ExecutionDetails = (props) => {
  const { values } = props;
  return (
    <div className="PositionDetail">
      <form>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm" htmlFor="account">
              Account
            </label>
            <Field
              id="account"
              name="account"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>

          <div className="form-group col">
            <label className="form-control-sm" htmlFor="symbol">
              Symbol
            </label>
            <Field
              id="symbol"
              name="symbol"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm" htmlFor="bod">
              Type
            </label>
            <Field
              id="type"
              name="type"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>

          <div className="form-group col">
            <label className="form-control-sm" htmlFor="net">
              Side
            </label>
            <Field
              id="side"
              name="side"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm" htmlFor="bought">
              Fill Qty
            </label>
            <Field
              id="fillQty"
              name="fillQty"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>

          <div className="form-group col">
            <label className="form-control-sm" htmlFor="sold">
              Fill Px
            </label>
            <Field
              id="fillPx"
              name="fillPx"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm" htmlFor="cost">
              Order Px
            </label>
            <Field
              id="orderPx"
              name="orderPx"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>

          <div className="form-group col">
            <label className="form-control-sm" htmlFor="realized">
              Order Id
            </label>
            <Field
              id="orderId"
              name="orderId"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm" htmlFor="unrealized">
              Fill Time
            </label>
            <Field
              id="fillTime"
              name="fillTime"
              className="form-control-plaintext"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm col-2" htmlFor="allOrNone">All or None</label>
            <Field type ="checkbox"
              id="allOrNone"
              name="allOrNone"
              className="allOrNone-checkbox"
              disabled
              checked={values.allOrNone}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label className="form-control-sm col-2" htmlFor="blockTradeIndicator">Block Trade</label>
            <Field type ="checkbox"
                   id="blockTradeIndicator"
                   name="blockTradeIndicator"
                   className="blockTradeIndicator-checkbox"
                   disabled
                   checked={values.blockTradeIndicator}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

ExecutionDetails.propTypes = {
  execution: PropTypes.object,
  accounts: PropTypes.object,
};

export const EnhancedExecutionDetails = withFormik({
  mapPropsToValues: (props) => {
    if (props.execution) {
      const acct = props.execution.account;
      return {
        account:
          !!props.accounts && props.accounts.has(acct)
            ? props.accounts.get(acct)
            : acct,
        symbol: props.execution.symbol,
        type: props.execution.execType.name,
        side: props.execution.side.name,
        fillPx: props.execution.fillPx,
        fillTime: convertDateToString(
          props.execution.transactTime,
          "YYYYMMDD-HH:mm:ss.SSS"
        ),
        fillQty: props.execution.lastSharesScaled,
        orderPx: props.execution.orderPxScaled,
        orderId: props.execution.orderId,
        allOrNone: props.execution.allOrNone,
        blockTradeIndicator: props.execution.blockTradeIndicator,
      };
    } else {
      return {
        account: "",
        symbol: "",
        type: "",
        side: "",
        fillPx: "",
        fillTime: "",
        fillQty: "",
        orderPx: "",
        orderId: "",
        allOrNone: null,
        blockTradeIndicator: null,
      };
    }
  },
})(ExecutionDetails);
