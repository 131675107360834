import React, { Component } from 'react';
import ReactTable from 'react-table';
import "./WatchlistInstrumentSelector.css"
import { connect } from 'react-redux'
import _ from 'lodash'

class WatchlistInstrumentSelector extends Component {
    constructor(props) {
        super(props);
        this.instruments = props.instruments;
        this.traderInstruments = props.traderInstruments;
        this.selectedInstruments = props.selectedInstruments;
        this.prepareGridData = this.prepareGridData.bind(this)
        this.instrumentsData = this.prepareGridData(props.watchListData, props.selectedInstruments);
        this.gridColumns = this.prepareGridColumns();
        this.state = { instrumentsData: this.instrumentsData, gridColumns: this.gridColumns, selectAll: false, pageNumber: 0, pageSize: props.pageSize || 10, totalNumberOfPages: 1, selectedSymbols: [] }
        this.onInstrumentSelect = props.onInstrumentSelect;
        this.tableRef = React.createRef();
        this.symbols = this.props.symbols;
        this.searchInstrumentFromSymbol = _.debounce((symbols) => this.props.searchInstrumentFromSymbol(symbols), 3000)
    }

    componentDidMount = () => {
        this.updateSelectAllState();
        this.onInstrumentSelectNotify()
    }


    async componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.instruments) !== Object.keys(this.props.instruments)) {
            this.instrumentsData = this.prepareGridData(nextProps.instruments, nextProps.selectedInstruments);
            await this.setState({ instrumentsData: this.instrumentsData })
            this.onInstrumentSelect = nextProps.onInstrumentSelect;
            this.onInstrumentSelectNotify()
        }
    }

    prepareGridData = (instruments, selectedSymbols) => {
        let gridData = [];
        let idx = 0;
        const otherSymbols = this.state && this.state.selectedSymbols ? this.state.selectedSymbols : []
        selectedSymbols = [...selectedSymbols, ...otherSymbols]
        for (const symbol in instruments) {
            const instrument = instruments[symbol];
            const traderInst = this.traderInstruments[symbol];
            const instState = traderInst && traderInst.state ? traderInst.state : instrument.state;
            gridData.push({ index: idx++, selected: selectedSymbols.indexOf(symbol) >= 0, instrument, state: instState });
        }

        return gridData;
    }

    getCheckedStatus = (instrument) => {
        return instrument.selected;
    }

    prepareGridColumns = () => {
        let gridColumns = [
            {
                id: "selected",
                filterable: false,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            checked={this.getCheckedStatus(original)}
                            onChange={(e) => {
                                this.setRowSelection(original.index, original.instrument.symbol, e.target.checked);
                            }}
                        />
                    );
                },
                Header: x => {
                    return (
                        <input
                            type="checkbox"
                            checked={this.state.selectAll}
                            onChange={(e) => this.selectAll(e.target.checked)}
                        />
                    );
                },

                width: 35
            },
            {
                Header: "Symbol",
                id: "symbol",
                filterable: true,
                sortable: false,
                accessor: (d) => d.instrument.symbol

            },
            {
                Header: "Description",
                id: "description",
                filterable: false,
                sortable: false,
                accessor: (d) => d.instrument && d.instrument.instrument && d.instrument.instrument.description,
            },

            {
                Header: "Currency",
                id: "baseCurrency",
                filterable: false,
                sortable: false,
                accessor: (element) => element.instrument && element.instrument.instrument && element.instrument.instrument.baseCurrency
            },

            {
                Header: "State",
                id: "state",
                filterable: false,
                sortable: false,
                accessor: (element) => element.instrument && element.instrument.instrument && element.instrument.instrument.state && element.instrument.instrument.state.name
            }
        ]

        return gridColumns;
    }


    getTableCurrentViewData = () => {
        if (this.tableRef.current) {
            return this.tableRef.current.getResolvedState().sortedData;
        }

        return null;
    }

    isAllSelected = (onlyInView = false) => {
        if (onlyInView) {
            let viewData = this.getTableCurrentViewData();
            if (viewData) {
                let inst = viewData.find((element) => element._original.selected === false)
                return !inst;
            }

            return false;
        }

        let inst = this.state.instrumentsData.find((element) => element.selected === false)
        return !inst;
    }

    setRowSelection = (index, symbol, selected) => {
        let inst = this.state.instrumentsData[index]
        let selectedSymbols = this.state.selectedSymbols
        if (selected) { selectedSymbols.push(symbol) }
        if (!selected && selectedSymbols.indexOf(symbol) >= 0) {
            let index = selectedSymbols.indexOf(symbol)
            selectedSymbols.splice(index, 1)
        }

        if (inst.index !== index) {
            throw new Error("Table and data index mismatch")
        }

        inst.selected = selected;

        this.setState({ instrumentsData: this.state.instrumentsData, selectAll: this.isAllSelected(), selectedSymbols }, () => {
            this.onInstrumentSelectNotify();
        });

    }

    selectAll = (selected) => {
        let viewData = this.getTableCurrentViewData();
        if (!viewData) return;

        let instrumentsData = this.state.instrumentsData;
        let selectedSymbols = []
        Object.keys(this.instruments).forEach(symbol => {
            selectedSymbols.push(symbol)
        })

        viewData.forEach(element => {
            let idx = element._original.index
            instrumentsData[idx].selected = selected;
        });

        this.setState({ instrumentsData: instrumentsData, selectAll: selected, selectedSymbols }, () => {
            this.onInstrumentSelectNotify();
        });
    }

    getSelectedSymbols = () => {
        let selectedSymbols = [];
        this.state.instrumentsData.forEach(element => {
            if (element.selected) {
                selectedSymbols.push(element.instrument.symbol)
            }
        });
        return selectedSymbols;
    }

    onInstrumentSelectNotify = () => {
        if (this.onInstrumentSelect) {
            this.onInstrumentSelect(this.getSelectedSymbols())
        }
    }

    filterData = (filter, row, column) => {
        const rowData = row[filter.id];

        if (rowData && filter.value) {
            return rowData.toLowerCase().includes(filter.value.toLowerCase());
        }

        return false;
    }

    updateSelectAllState = () => {
        this.setState({ selectAll: this.isAllSelected(true) });
    }

    onPageSizeChange = async (e) => {
        this.setState({ pageSize: e })
        this.props.onWatchListPageSizeChange(this.state.pageNumber, e)
    }

    onPageChange = e => {
        this.setState({ pageNumber: e })
        this.props.onWatchListPageSizeChange(e, this.state.pageSize)
    }

    onSymbolSearch = (data) => {
        if (data && data.length > 0 && data[0].id === 'symbol') {
            let sortedSymbols = []
            const sortedData = this.tableRef.current.getResolvedState().sortedData
            if (sortedData && sortedData.length > 0) {
                sortedData.forEach(d => {
                    sortedSymbols.push(d.symbol)
                });
                if (sortedData && sortedData.length > 0)
                    this.searchInstrumentFromSymbol(sortedSymbols)
            }
        }
    }
    render() {
        return (
            <div id="WatchlistInstrumentSelector">
                <ReactTable
                    ref={this.tableRef}
                    data={this.state.instrumentsData}
                    parent={this}
                    filterable={true}
                    sortable={true}
                    minRows={0}
                    columns={this.gridColumns}
                    showPagination={true}
                    showPageSizeOptions={true}
                    showPageJump={false}
                    defaultPageSize={this.props.pageSize || 10}
                    onFilteredChange={(data) => {
                        this.onSymbolSearch(data)
                        this.updateSelectAllState();
                    }}
                    onPageSizeChange={this.onPageSizeChange}
                    defaultFilterMethod={(filter, row, column) => {
                        return this.filterData(filter, row, column)
                    }}
                    onPageChange={this.onPageChange}
                >
                </ReactTable>
            </div >
        )
    }
}

const mapStateToProps = state => {
    const { watchListData } = state.watchList;
    return { watchListData };
}

export default connect(mapStateToProps, null)(WatchlistInstrumentSelector)
