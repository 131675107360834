import React from "react";
import { connect } from "react-redux";
import Dialog from "../core/dialog/Dialog";

import ChangePasswordForm from "./ChangePasswordForm";
import { CHANGE_PASSWORD_SUCCESS } from "../../constants/actions";
import { changePassword } from "../../actions/auth";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.trader && state.trader.trader ? state.trader.trader.user : ""
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeDialog: () => {
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  },
  performChangePassword: (user, oldPassword, newPassword) => {
    dispatch(changePassword(user, oldPassword, newPassword));
  }
});

const ChangePassword = ({
  show,
  closeDialog,
  user,
  performChangePassword
}) => {

  return (
    <Dialog
      title="Change Password"
      show={show}
      onClose={() => closeDialog()}
    >
      <ChangePasswordForm
        doSubmit={(oldPassword, newPassword) => {
          performChangePassword(user, oldPassword, newPassword)
        }}
        handleClose={() => { closeDialog() }}
      />
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
