import {apiUrl, countDecimals, sleep} from "../modules/util";
import Notification from "../modules/notifications";
import { apiCall, refreshTokens } from "../modules/apiCall";
import { StatusCode } from "grpc-web";
import {DEFAULT_PAGE_SIZE} from "../constants/strings";

const {
  ListInstrumentsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_api_pb");
const {
  CreateMarketDataSubscriptionRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_subscription_api_pb");
const {
  MarketDataAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_api_grpc_web_pb");
const {
  MarketDataSubscriptionAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_subscription_api_grpc_web_pb");

const client = new MarketDataAPIClient(
  apiUrl(),
  null,
  null
);

const subclient = new MarketDataSubscriptionAPIClient(
    apiUrl(),
    null,
    null
);

const activeSymbolCalls = new Set();
const activeAllCalls = new Set();

export default class BookService {
  static subscribe(symbol, onData, onStatus, onError, onEnd) {
    const request = new CreateMarketDataSubscriptionRequest();
    if (symbol && !Array.isArray(symbol)) {
      request.setSymbolsList([symbol]);
    } else {
      if (Array.isArray(symbol)) {
        request.setSymbolsList(symbol);
      }
      request.setDepth(1);
    }

    const onErr = (error) => {
      if (error && error.message === "Http response at 400 or 500 level") {
        console.log({ "Resubscribe Market Data Triggered": error });
        sleep(1000).then(() =>
          this.subscribe(symbol, onData, onStatus, onError, onEnd)
        );
      } else if (error && error.code === StatusCode.UNAUTHENTICATED) {
        refreshTokens().then(() => {
          this.subscribe(symbol, onData, onStatus, onError, onEnd);
        });
      }
    };

    var call = apiCall(subclient, "createMarketDataSubscription", request, null, {data: onData, status: onStatus, end: onEnd, error: onErr});

    // if it is array- it is subscription call for watchlist instruments otherwise for book

    let activeCalls = !Array.isArray(symbol)
      ? activeSymbolCalls : activeAllCalls;

    for (let entry of activeCalls.values()){
      entry.cancel();
    }
    activeCalls.clear();
    if (call) {
      activeCalls.add(call);
    }
    return call;
  }

  static getAllInstruments(cb, whenComplete) {
    const request = new ListInstrumentsRequest();
    request.setPageSize(DEFAULT_PAGE_SIZE);
    const instruments = [];
    const cbLoop = (err, response) => {
      if (response) {
        response.getInstrumentsList().forEach((inst) => {
          const decimals = countDecimals(inst.getTickSize());
          const priceScale = Math.pow(10, decimals);
          const qtyScale = inst.getFractionalQtyScale();
          const multiplier = inst.getMultiplier();
          const baseCurrency = inst.getBaseCurrency();
          const minimumTradeQty = inst.getMinimumTradeQty();
          const description = inst.getDescription();

          instruments.push({
            symbol: inst.getSymbol(),
            scale: priceScale,
            qtyScale,
            multiplier,
            baseCurrency,
            minimumTradeQty,
            description
          });

          cb(inst.getSymbol(), priceScale, qtyScale, multiplier, baseCurrency, minimumTradeQty);
        });

        if (response.getNextPageToken()) {
          request.setPageToken(response.getNextPageToken());

          apiCall(client, "listInstruments", request, cbLoop);
        } else {
          whenComplete(instruments);
        }
      }

      if (err) {
        Notification.error(
          `Error when fetching instruments: \n ${err.message}`
        );
      }
    };

    apiCall(client, "listInstruments", request, cbLoop);
  }

  static getInstrument(symbol, cb) {
    let instruments = {};
    const cbLoop = (_symbol, priceScale, qtyScale, multiplier, baseCurrency) => {
      instruments[_symbol] = { priceScale, qtyScale, multiplier, baseCurrency, _symbol };
    };
    const whenComplete = () => {
      const instrument = instruments[symbol];
      if (!instrument) Notification.error(`Instrument ${symbol} not found`);
      else
        cb(
          instrument._symbol,
          instrument.priceScale,
          instrument.qtyScale,
          instrument.multiplier,
          instrument.baseCurrency
        );
    };
    this.getAllInstruments(cbLoop, whenComplete);
  }
}
