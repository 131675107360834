import {
  SET_SYMBOL,
  UPDATE_BOOK,
  ADD_INSTRUMENT,
  UPDATE_INSTRUMENT,
  VIEW_POSITION,
  HIDE_POSITION,
  UPDATE_POSITIONS,
  UPDATE_COMPLETED_ORDERS,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  UPDATE_EXECUTIONS,
  UPDATE_WORKING_ORDERS,
  ADD_INSTRUMENTS,
  UPDATE_RELATED_INSTRUMENTS,
  UPDATE_ACCOUNTS,
  UPDATE_USERS,
  UPDATE_TRADER,
  UPDATE_SESSION_ID,
  SET_GRID_CONTEXT,
  VIEW_EXECUTION,
  HIDE_EXECUTION,
  VIEW_ORDER_EXECUTION,
  HIDE_ORDER_EXECUTION,
} from "../constants/actions";
import { instruments } from "./instruments";
import { positions } from "./positions";
import { executions } from "./executions";
import { completedOrders } from "./completedOrders";
import Order from "../entities/Order";

const initialAppContext = { id: null, symbol: null, context: null };

const initialState = {
  symbol: null,
  scale: {},
  qtyScale: {},
  book: [],
  bookHidden: false,
  instruments: {},
  relatedInstruments: [],
  positions: [],
  showPosition: null,
  completedOrders: [],
  executions: [],
  workingOrders: {},
  usersToAccounts: new Map(),
  accounts: new Map(),
  users: new Map(),
  firms: new Map(),
  firmsToUsers: new Map(),
  trader: null,
  sessionId: "",
  appContext: initialAppContext,
  showExecution: null,
};

export const trader = (state = initialState, action) => {
  const { type, book, bookHidden } = action;

  switch (type) {
    case SET_GRID_CONTEXT:
      const { payload } = action;

      let newState = Object.assign({}, state);
      newState.appContext = { ...state.appContext };

      for (let [key, value] of Object.entries(payload)) {
        newState.appContext[key] = value;
      }

      let oldInst, newInst;

      if (state.appContext.id) {
        if (state.appContext.context === "instruments") {
          oldInst = state.instruments[state.appContext.id];
        }
      }

      if (payload.context === "instruments") {
        newInst = state.instruments[payload.id];
      }

      if (oldInst || newInst) {
        newState.instruments = Object.assign({}, state.instruments);

        if (oldInst) {
          newState.instruments[oldInst.symbol] = {
            ...oldInst,
            selected: false,
          };
        }

        if (newInst) {
          newState.instruments[newInst.symbol] = { ...newInst, selected: true };
        }
      }

      return newState;

    case SET_SYMBOL: {
      const { symbol, subType } = action;
      return Object.assign({}, state, {
        symbol: symbol,
        subType: subType,
        book: [],
      });
    }

    case UPDATE_BOOK:
      return Object.assign({}, state, {
        book: book,
        bookHidden: bookHidden,
      });

    case ADD_INSTRUMENT: {
      const { instrument: inst } = action;
      const { symbol, scale, qtyScale } = inst;
      const insts = instruments(state.instruments, action);
      return Object.assign({}, state, {
        instruments: insts,
        positions: positions(state.positions, {
          ...action,
          instrument: insts[symbol],
        }),
        scale: { ...state.scale, [symbol]: scale },
        qtyScale: { ...state.qtyScale, [symbol]: qtyScale },
      });
    }
    case ADD_INSTRUMENTS: {
      const instList = instruments(state.instruments, action);

      action.instruments.forEach((instrument) => {
        state.scale[instrument.symbol] = instrument.scale;
        state.qtyScale[instrument.symbol] = instrument.qtyScale;
      });

      state.positions = positions(state.positions, action);

      const newState = Object.assign({}, state, {
        instruments: instList,
        positions: [...state.positions],
        scale: { ...state.scale },
        qtyScale: { ...state.qtyScale },
      });

      const symbols = action.instruments.map( (ins) => ins.symbol );

      if (Object.keys(state.workingOrders).length > 0) {
        let workingOrdersUpdated = false;

        Object.keys(state.workingOrders).forEach((key) => {
          const ord = state.workingOrders[key];

          if (symbols.indexOf(ord.symbol) !== -1) {
            workingOrdersUpdated = true;
            state.workingOrders[key] = { ...ord };
          }
        });

        if (workingOrdersUpdated) {
          newState.workingOrders = { ...state.workingOrders };
        }
      }

      if (state.completedOrders.length > 0) {
        let ordersUpdated = false;
        const completed = state.completedOrders.map((ord) => {
          if (symbols.indexOf(ord.symbol) !== -1) {
            ordersUpdated = true;
            return { ...ord };
          } else {
            return ord;
          }
        });

        if (ordersUpdated) {
          newState.completedOrders = completed;
        }
      }

      return newState;
    }
    case UPDATE_RELATED_INSTRUMENTS: {
      return Object.assign({}, state, {
        relatedInstruments: action.payload,
      });
    }

    case UPDATE_INSTRUMENT: {
      const { symbol } = action;

      const insts = instruments(state.instruments, action);
      return Object.assign({}, state, {
        instruments: insts,
        positions: positions(state.positions, {
          ...action,
          instrument: insts[symbol],
        }),
      });
    }

    case UPDATE_POSITIONS:
      return Object.assign({}, state, {
        positions: positions(state.positions, {
          ...action,
          instruments: state.instruments,
        }),
      });

    case VIEW_POSITION: {
      const { symbol, account } = action;

      return Object.assign({}, state, {
        showPosition: { symbol, account },
      });
    }

    case HIDE_POSITION: {
      return Object.assign({}, state, {
        showPosition: null,
      });
    }
    case UPDATE_COMPLETED_ORDERS: {
      if (action.orders.length > 0)
        return Object.assign({}, state, {
          completedOrders: completedOrders(state.completedOrders, {
            ...action,
          }),
        });
      return state;
    }

    case LOGIN_RESET:
    case LOGIN_SUCCESS:
       const resetState  = { ...initialState, scale: {}, qtyScale: {} };
       return resetState
    case UPDATE_EXECUTIONS:
      return Object.assign({}, state, {
        executions: executions(state.executions, {
          ...action,
          instruments: state.instruments,
        }),
      });

    case UPDATE_WORKING_ORDERS: {
      state = Object.assign({}, state);
      action.orders.forEach((order) => {
        let isOpen = order.status.leavesQty > 0;
        if (isOpen &&
          order.status.hasOwnProperty('state') &&
          order.status.state.hasOwnProperty('name') &&
          !Order.isOpen(order.status.state.name)) {
          isOpen = false;
        }
        if (isOpen) {
          state.workingOrders = { ...state.workingOrders, [order.id]: order };
        } else {
          state.workingOrders = { ...state.workingOrders };
          delete state.workingOrders[order.id];
        }
      });
      return state;
    }

    case UPDATE_ACCOUNTS:
      let newUsersToAccounts = new Map(state.usersToAccounts);
      newUsersToAccounts.set(action.user, action.payload);
      let newAccounts = new Map();
      newUsersToAccounts.forEach((accounts) => {
        accounts.forEach((displayName, account) => {
          newAccounts.set(account, displayName);
        });
      });
      return {
        ...state,
        usersToAccounts: newUsersToAccounts,
        accounts: newAccounts,
      };

    case UPDATE_USERS:
      return {
        ...state,
        users: action.payload,
        firms: action.firms,
        firmsToUsers: action.firmsToUsers,
      };

    case UPDATE_TRADER:
      return {
        ...state,
        trader: action.payload,
      };

    case UPDATE_SESSION_ID:
      return Object.assign({}, state, {
        sessionId: action.payload,
      });

    case VIEW_EXECUTION: {
      const { id, account } = action;

      return Object.assign({}, state, {
        showExecution: { id, account },
      });
    }

    case HIDE_EXECUTION: {
      return Object.assign({}, state, {
        showExecution: null,
      });
    }

    case VIEW_ORDER_EXECUTION: {
      const { id, execution } = action;

      return Object.assign({}, state, {
        showOrderExecution: { id, execution },
      });
    }

    case HIDE_ORDER_EXECUTION: {
      return Object.assign({}, state, {
        showOrderExecution: null,
      });
    }

    default:
      return state;
  }
};
