import { LOGIN_RESET, DOWNLOAD_TRADES, HIDE_DOWNLOAD_TRADES, SHOW_DOWNLOAD_TRADES } from "../constants/actions";

const initialState = {
  show: false,
  startDownloading: false,
  instruments:[]
};

const downloadTrades = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOWNLOAD_TRADES:
      state.show = true;
      return Object.assign({}, state);
    case HIDE_DOWNLOAD_TRADES:
      state.show = false;
      return Object.assign({}, state);
    case DOWNLOAD_TRADES:
      state.startDownloading = true;
      state.downloadTradesDateRange = [...action.payload];
      return Object.assign({}, state);
    case LOGIN_RESET:
      state = Object.assign({}, state, initialState);
      return state;
    default:
      return state;
  }
};

export default downloadTrades;