import React, { useState } from "react";
import { Field, ErrorMessage, withFormik } from "formik";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import {
  getEnumName,
  capitalizeWord,
  isNullOrUndefined,
  parsePrice,
  validScaledPrice,
  convertScaledPriceToInt
} from "../../../modules/util";
import { Button } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import ProtobufParser from "../../../modules/protobufParser";
import i18n from 'i18next'
import {createUserMap} from "../../book/OrderTicketForm";
import AccountSelector from "../../core/select/AccountSelector";

const RFQOrderTicketForm = (props) => {
  const { values, accounts, users, usersToAccounts, firms, firmsToUsers, errors, handleSubmit, dealers, onCancel, setFieldValue } = props;

  const createSideOptions = () => {
    let items = [];
    for (const sideName in Side) {
      const sideValue = Side[sideName];
      const prettySideName = i18n.t(getEnumName(Side, sideValue).toLowerCase(), capitalizeWord(getEnumName(Side, sideValue)));

      items.push(
        <option key={sideValue} value={sideValue}>
          {prettySideName}
        </option>
      );
    }
    return items;
  };

  const isAmend = () => {
    return values.id;
  };

  const isReadOnly = () => {
    return values.readonly;
  };

  const [selectedDealers, setSelectedDealers] = useState([]);
  values.selectedDealers = selectedDealers;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="symbol">
            Symbol
          </label>
          <Field
            id="symbol"
            name="symbol"
            className="form-control-plaintext symbol-text"
            type="text"
            readOnly
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="account">
            {isAmend() || !users || users.size <= 1 ? "Account" : "On Behalf Of"}
          </label>
          {isAmend() && (
            <Field
              id="account"
              name="account"
              className="form-control-plaintext"
              value={
                !!accounts && accounts.has(values.account)
                  ? accounts.get(values.account)
                  : values.account
              }
            />
          )}
          { !isAmend() &&
          <AccountSelector
              id="account"
              name="account"
              users={users}
              usersToAccounts={usersToAccounts}
              firms={firms}
              firmsToUsers={firmsToUsers}
              accounts={accounts}
              account={values.account}
              user={values.user}
              userAccountCb={(user, account) => {
                setFieldValue('account', account);
                setFieldValue('user', user);
              }}
          >
          </AccountSelector>
          }
          <ErrorMessage
            name="account"
            component="div"
            className="form-input-error"
          />
        </div>
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="side">
            Side
          </label>
          {isReadOnly() && (
            <Field
              id="side"
              name="side"
              className="form-control-plaintext"
              value={capitalizeWord(getEnumName(Side, values.side))}
            />
          )}
          {!isReadOnly() && (
            <Field
              component="select"
              id="side"
              name="side"
              placeholder="Side"
              className={
                errors.side
                  ? `text-input error form-control`
                  : `text-input form-control`
              }
            >
              {createSideOptions()}
            </Field>
          )}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="quantity">
          {i18n.t("quantity")}
          </label>
          {isReadOnly() && (
            <Field
              id="quantity"
              name="quantity"
              className="form-control-plaintext"
              value={values.quantity}
            />
          )}
          {!isReadOnly() && (
            <Field
              id="quantity"
              name="quantity"
              placeholder={i18n.t("quantity")}
              type="text"
              className={
                errors.quantity
                  ? `text-input error form-control`
                  : `text-input form-control`
              }
            />
          )}
          <ErrorMessage
            name="quantity"
            component="div"
            className="form-input-error"
          />
        </div>
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="expirationTime">
            Expiration Time
          </label>
          {isReadOnly() && (
            <Field
              id="expirationTime"
              name="expirationTime"
              className="form-control-plaintext"
              value={values.expirationTime}
            />
          )}
          {!isReadOnly() && (
            <Field
              id="expirationTime"
              name="expirationTime"
              placeholder="Seconds"
              type="text"
              className={
                errors.expirationTime
                  ? `text-input error form-control`
                  : `text-input form-control`
              }
            />
          )}
          <ErrorMessage
            name="expirationTime"
            component="div"
            className="form-input-error"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="selectedDealers">
            Dealers
          </label>
          {!isAmend() && (
            <MultiSelect
              id="selectedDealers"
              options={dealers}
              value={selectedDealers}
              onChange={setSelectedDealers}
              labelledBy={"Select"}
            />
          )}
          <ErrorMessage
            name="selectedDealers"
            component="div"
            className="form-input-error"
          />
        </div>
      </div>

      {!isReadOnly() && (
        <div className="form-row">
          <div className="col text-right">
            {!isAmend() && (
              <Button variant={"outline-success"} type="submit">
                {values.id ? "Amend" : "Submit"}
              </Button>
            )}
          </div>
          <div className="col-2" />
          <div className="col">
            <Button variant={"outline-danger"} onClick={() => onCancel()}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

const formatValues = (values, props) => {
  let { expirationTime, selectedDealers } = values;

  let expirationInDate = new Date();
  expirationInDate.setSeconds(
    expirationInDate.getSeconds() + parseInt(expirationTime)
  );

  return {
    account: values.account,
    side: values.side,
    quantity: convertScaledPriceToInt(values.quantity, values.qtyScale),
    symbol: values.symbol,
    user: values.user,
    firm: props.trader.firm,
    expirationTime: ProtobufParser.toTimestamp(expirationInDate),
    dealers: selectedDealers.map((dealer) => dealer.value),
  };
};

export const EnhancedRFQOrderTicketForm = withFormik({
  mapPropsToValues: (props) => {
    const selectedAccount = props.original
        ? props.original.account
        : !!props.accounts
            ? props.accounts.keys().next().value
            : "";
    const selectDefaultUser = !!selectedAccount && (!props.users || props.users.size <= 1);
    const qtyScale = props.symbol && props.instruments[props.symbol] ? props.instruments[props.symbol].qtyScale : 0;
    return {
    id: props.original ? props.original.id : "",
    account: selectedAccount,
    user: selectDefaultUser ? createUserMap(props.users, props.usersToAccounts, selectedAccount).keys().next().value : "",
    side: props.original ? props.original.side : 1,
    quantity: props.original ? parsePrice(props.original.quantity, qtyScale) : "",
    readonly: props.readonly,
    symbol: props.symbol,
    dealers: props.dealers.length > 0 ? props.dealers : [],
    expirationTime: props.original ? props.original.expirationTime : "",
    selectedDealers: null,
    onCancel: props.onCancel,
    minimumTradeQty: props.symbol && props.instruments[props.symbol] ? props.instruments[props.symbol].minimumTradeQty : 0,
    qtyScale: qtyScale,
  }},
  handleSubmit: (values, { props }) => {
    props.doSubmit(formatValues(values, props));
  },
  validate: values => {
    let errors = validateAttributes(values);
    if (!values.selectedDealers || values.selectedDealers.length <= 0)
    {
      errors.selectedDealers = "Dealer is required";
    }
    return errors
  },
  displayName: "RFQOrderTicketForm", // helps with React DevTools
})(RFQOrderTicketForm);

export function validateAttributes(values) {
  let errors = {}

  if (isNullOrUndefined(values.side)) {
    errors.side = 'Side is required';
  }

  if (!values.account) {
    errors.account = 'Account is required';
  }

  if (!values.expirationTime || !Number.isInteger(parseFloat(values.expirationTime))) {
    errors.expirationTime = 'Expiration Time is required';
  } else if (parseInt(values.expirationTime) <= 0) {
    errors.expirationTime = 'Expiration Time must be > 0';
  }

  if (!values.quantity || Number.isNaN(parseFloat(values.quantity))) {
    errors.quantity = `${i18n.t("quantity")} is required`;
  } else if (parseFloat(values.quantity) <= 0) {
    errors.quantity = `${i18n.t("quantity")} must be > 0`;
  } else if (!validScaledPrice(values.quantity, values.qtyScale)) {
    errors.quantity = `${i18n.t("quantity")} has too many decimal places`;
  } else if (convertScaledPriceToInt(values.quantity, values.qtyScale) % values.minimumTradeQty > 0) {
    errors.quantity = `${i18n.t("quantity")} must be multiple of ${parsePrice(values.minimumTradeQty, values.qtyScale)}`;
  }

  return errors
}
