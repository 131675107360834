import React from "react";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import DataGrid from "../core/data-grid/DataGrid";
import { parsePrice } from "../../modules/util";
import "./styles.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SetGridContext, SetViewContext } from "../../actions/app";
import DataGridActions from "../core/data-grid/DataGridActions";
import i18n from "i18next";
import Loader from "../core/loader/Loader";
import {Env} from "../../constants/environment";

const upArrow = "\u2b06";
const downArrow = "\u2b07";
const nochangeArrow = "\u2b0c";

const getDirection = (delta) => {
  if (delta > 0) {
    return upArrow;
  } else if (delta < 0) {
    return downArrow;
  }
  return nochangeArrow;
};

const Instruments = ({
  id,
  symbolcb,
  symbolpopup,
  instruments,
  loadingInstruments,
  setViewContext,
  setGridContext,
  onPageRowsChange,
}) => {
  const getTdProps = (state, rowInfo, column) => {
    let className;

    if (column.Header === "LAST") {
      if (rowInfo.row.lastdelta > 0) {
        className = "lastup";
      } else if (rowInfo.row.lastdelta < 0) {
        className = "lastdown";
      }
    }

    return {
      className: className,
    };
  };

  const getTrProps = (state, rowInfo, column, instance) => {
    return {
      onClick: () => {
        //don't fire update if row is already selected
        if (rowInfo.original.symbol !== id) {
          if (symbolcb) {
            symbolcb(rowInfo.original.symbol);
          }

          setGridContext({
            id: rowInfo.original.symbol,
            symbol: rowInfo.original.symbol,
            context: "instruments",
          });
        }

        instance.props.parent.forceUpdate();
      },
      className: rowInfo.original.symbol === id ? "trselected" : null,
    };
  };

  let columns = [
    {
      Header: i18n.t("symbol").toUpperCase(),
      id: "symbol",
      accessor: (d) => d.symbol,
      Cell: (props) => instrumentTitle(props.value),
      minWidth: 200,
      className: "symbol",
    },
  ];
  if (Env.getEnvBool("REACT_APP_SHOW_HIDDEN_BOOKS")) {
    columns.push({
      Header: i18n.t("market").toUpperCase(),
      id: "market",
      accessor: (d) =>
        d.bookHidden ? i18n.t("market_hidden") : i18n.t("market_active"),
      maxWidth: 80,
    });
  }
  if (!Env.getEnvBool("REACT_APP_HIDE_DESCRIPTION")) {
    columns.push({
      Header: "DESCRIPTION",
      id: "description",
      accessor: (d) => d.description,
      Cell: (props) => (
        <div className={props.value ? props.value.toLowerCase() : ""}>
          {props.value}
        </div>
      ),
      minWidth: 200,
    });
  }
  columns.push(
    {
      Header: "STATE",
      id: "state",
      accessor: (d) => d.state,
      Cell: (props) => (
        <Countdown
          date={
            moment().isAfter(props.original.preStartTime)
              ? props.original.startTime
              : props.original.preStartTime
          }
          renderer={(countdownProps) => {
            let txt = "";
            if (
              !countdownProps.completed &&
              Env.getEnvBool("REACT_APP_SHOW_COUNTDOWN")
            ) {
              txt +=
                " -> " +
                (moment().isAfter(props.original.preStartTime)
                  ? "Open"
                  : "Preopen") +
                " ";
              txt += countdownProps.days + "d ";
              txt += String(countdownProps.hours).padStart(2, "0") + "hr ";
              txt += String(countdownProps.minutes).padStart(2, "0") + "m ";
              txt += String(countdownProps.seconds).padStart(2, "0") + "s";
            }
            return (
              <div>
                <span className={props.value ? props.value.toLowerCase() : ""}>
                  {props.value}
                </span>
                <span>{txt}</span>
              </div>
            );
          }}
        />
      ),
      minWidth: Env.getEnvBool("REACT_APP_SHOW_COUNTDOWN") ? 160 : 80,
      maxWidth: Env.getEnvBool("REACT_APP_SHOW_COUNTDOWN") ? 160 : 80,
    },
    {
      Header: i18n.t("close").toUpperCase(),
      id: "close",
      accessor: (d) => d.close,
      maxWidth: 70,
    },
    {
      Header: i18n.t("open").toUpperCase(),
      id: "open",
      accessor: (d) => d.open,
      maxWidth: 50,
    },
    {
      Header: i18n.t("high").toUpperCase(),
      id: "high",
      accessor: (d) => d.high,
      maxWidth: 70,
    },
    {
      Header: i18n.t("low").toUpperCase(),
      id: "low",
      accessor: (d) => d.low,
      maxWidth: 80,
    },
    {
      Header: "LAST",
      id: "last",
      accessor: (d) => (d.last ? d.last + " " + getDirection(d.lastdelta) : ""),
      maxWidth: 80,
    },
    {
      id: "lastdelta",
      accessor: (d) => d.lastdelta,
      show: false,
    },
    {
      Header: "BID QTY",
      id: "bidqty",
      accessor: (d) => d.bidqty,
      maxWidth: 80,
      className: "bid",
    },
    {
      Header: "BID PX",
      id: "bidpx",
      accessor: (d) => d.bidpx,
      maxWidth: 80,
      className: "bid",
    },
    {
      Header: "OFFER PX",
      id: "offerpx",
      accessor: (d) => d.offerpx,
      maxWidth: 80,
      className: "offer",
    },
    {
      Header: "OFFER QTY",
      id: "offerqty",
      accessor: (d) => d.offerqty,
      maxWidth: 80,
      className: "offer",
    },
    {
      Header: "SETTLEMENT PRICE",
      id: "settlementPx",
      accessor: (d) => d.settlementPx,
      maxWidth: 80,
      className: "settlementPx",
    },
    {
      id: "action",
      maxWidth: 100,
      minWidth: 100,
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <DataGridActions
          onView={() =>
            setViewContext({
              viewSwapDetail: true,
            })
          }
          onPopup={() => symbolpopup(row.row.symbol)}
        />
      ),
    }
  );
  return (
    <div className="BookDataGrid">
      <Loader show={loadingInstruments}/>
      <DataGrid
        data={
          Env.getEnvBool("REACT_APP_SHOW_HIDDEN_BOOKS")
            ? instruments
            : instruments.filter((d) => !d.bookHidden)
        }
        columns={columns}
        getTdProps={getTdProps}
        getTrProps={getTrProps}
        filterable={true}
        defaultSortedField="symbol"
        noDataText="Please add an instrument to your Watchlist"
        showPagination={true} // enabled if more than 20 records
      >
        {(state, makeTable, instance, sortedData) => {
          if (onPageRowsChange)
            onPageRowsChange(state.pageRows.map((row) => row.symbol));
          return <>{makeTable()}</>;
        }}
      </DataGrid>
    </div>
  );
};

Instruments.propTypes = {
  id: PropTypes.string,
  instruments: PropTypes.array,
  loadingInstruments: PropTypes.bool,
  symbolpopup: PropTypes.func,
  symbolcb: PropTypes.func,
};

function mapStateToProps(state) {
  const { trader, quotes, watchList } = state;
  const { metadata, loadingInstruments } = quotes;
  const { id } = trader.appContext;

  let _instruments = [];

  if (watchList.instruments && watchList.instruments.length) {
    _instruments = Object.values(trader.instruments).filter((ins) =>
      watchList.instruments.includes(ins.symbol)
    );
  }

  const instruments = Object.values(_instruments).map((i) => {
    const { symbol, scale, selected, description } = i;

    if (scale === undefined) {
      return { symbol };
    }

    const {
      book,
      state,
      bookHidden,
      open,
      close,
      high,
      low,
      last,
      lastdelta,
      settlementPx,
    } = i;
    const earliestDate = moment(new Date(0));
    const startTime = quotes.instruments[symbol]
      ? quotes.instruments[symbol].startTime(metadata, false)
      : earliestDate;
    const preStartTime = quotes.instruments[symbol]
      ? quotes.instruments[symbol].startTime(metadata, true)
      : earliestDate;
    const timesAreEqual = Math.abs(preStartTime.diff(startTime, "seconds")) < 5;

    return {
      description: description,
      selected: selected,
      symbol: symbol,
      state: state ? state.name : null,
      bookHidden: !!bookHidden,
      startTime: startTime,
      preStartTime: timesAreEqual ? earliestDate : preStartTime,
      open: open ? parsePrice(open, i.scale) : null,
      close: close ? parsePrice(close, i.scale) : null,
      high: high ? parsePrice(high, i.scale) : null,
      low: low ? parsePrice(low, i.scale) : null,
      lastdelta: lastdelta ? lastdelta : 0,
      last: last ? parsePrice(last, i.scale) : null,
      bidqty: (book && book.length) > 0 && book[0].qtyBid ? parsePrice(book[0].qtyBid, i.qtyScale) : null,
      bidpx:
        book && book.length > 0 && book[0].pxBid
          ? parsePrice(book[0].pxBid, i.scale)
          : null,
      offerqty: book && book.length > 0 && book[0].qtyOffer ? parsePrice(book[0].qtyOffer, i.qtyScale) : null,
      offerpx:
        book && book.length > 0 && book[0].pxOffer
          ? parsePrice(book[0].pxOffer, i.scale)
          : null,
      settlementPx: !!settlementPx ? parsePrice(settlementPx, i.scale) : null,
    };
  });

  return {
    id,
    instruments,
    loadingInstruments,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setViewContext: (payload) => {
    dispatch(SetViewContext(payload));
  },
  setGridContext: (payload) => {
    dispatch(SetGridContext(payload));
  },
});

export function instrumentTitle(symbol, lastPart = "") {
  const symbolDelimiter = Env.getEnv("REACT_APP_SYMBOL_DELIMITER");
  const lastSpaceIdx = symbol.lastIndexOf(!symbolDelimiter ? " " : symbolDelimiter);
  if (
    !lastPart &&
    (lastSpaceIdx < 0 || !Env.getEnvBool("REACT_APP_HIGHLIGHT_SYMBOL"))
  ) {
    return symbol;
  }
  return (
    <div>
      {!lastPart && symbol.substring(0, lastSpaceIdx + 1)}
      <b style={{ color: "#FF6600" }}>
        {symbol.substring(lastSpaceIdx + 1) + (lastPart ? ": " + lastPart : "")}
      </b>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Instruments);
