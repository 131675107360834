import { LOGIN_RESET } from "../constants/actions";

const initialState = {};

const appReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case LOGIN_RESET:
      state = Object.assign({}, state, initialState);
      return state;

    default:
      return state;
  }
};

export default appReducer;
