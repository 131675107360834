import React, { Component } from "react";
import { connect } from "react-redux";
import Instruments from '../components/book/Instruments';
import QuoteBook from "../components/quote/QuoteBook";
import QuoteTicket from "../components/quote/QuoteTicket";
import SwapDetails from "../components/quote/SwapDetails";
import RequestForQuotesDataGrid from "../components/quote/datagrids/RequestForQuotesDataGrid";
import QuotesDataGrid from "../components/quote/datagrids/QuotesDataGrid";
import { TraderDocumentTitle } from "../constants/strings";
import {
  SubscribeQuotes,
  ListDealers,
  ListRequesters,
  SearchRfqs,
  SearchQuotes,
} from "../actions/quotes";
import { ListAccounts } from "../actions/accounts";
import { GetWhoAmI } from "../actions/orders";
import { FetchMetadata, ListInstrumentsForWatchList, ListSymbols, ListSelectedInstruments} from "../actions/instruments";
import { Row, Col } from "react-bootstrap";
import { subscribeOrders } from "../actions/orders";
import { updateInstrument, addInstruments } from '../actions/trader';
import { subscribeBook, SubscribeBook } from "../actions/books";
import WatchList from "../components/WatchList";
import { isPopup } from "../components/shared/AdminWrapper";
import ProtobufParser from "../modules/protobufParser";
import Execution from '../entities/Execution';
import Notification from "../modules/notifications";
import DownloadTrades from "../components/DownloadTrades";
import NotificationList from "../components/NotificationList";
import { setCurrentPageSize } from "../actions/watchlist";
const { QuoteStatus } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/quotes/v1beta1/quotes_pb")

function mapStateToProps(state) {
  return {
    instruments: state.trader.instruments,
    viewSwapDetail: state.quotes.appContext.viewSwapDetail,
    watchList: state.watchList,
    quotes: state.quotes.quotes,
    downloadTrades: state.downloadTrades,
    notifications: state.notifications
  };
}

const mapDispatchToProps = (dispatch) => ({
  subscribeQuotes: () => {
    return dispatch(SubscribeQuotes());
  },
  listAccounts: () => {
    dispatch(ListAccounts());
  },
  listDealers: () => {
    dispatch(ListDealers());
  },
  listRequesters: () => {
    dispatch(ListRequesters());
  },
  getWhoAmI: () => {
    dispatch(GetWhoAmI());
  },
  fetchMetadata: () => {
    dispatch(FetchMetadata());
  },
  subscribeOrders: (cbOrd, cbExec, cbRej) => {
    dispatch(subscribeOrders(cbOrd, cbExec, cbRej));
  },

  performAddInstruments: (instruments) => {
    dispatch(addInstruments(instruments))
  },

  performUpdateInstrument: (symbol, book, status) => {
    dispatch(updateInstrument(symbol, book, status))
  },

  subscribeBook: (payload) => {
    dispatch(SubscribeBook(payload));
  },

  searchRfqs: () => {
    let midNight = new Date();
    midNight.setHours(0, 0, 0, 0)
    midNight = ProtobufParser.toTimestamp(midNight);
    let statusList = [QuoteStatus.QUOTE_STATUS_EXPIRED, QuoteStatus.QUOTE_STATUS_ACCEPTED];
    dispatch(SearchRfqs(statusList, midNight));
  },

  searchQuotes: () => {
    let midNight = new Date();
    midNight.setHours(0, 0, 0, 0)
    midNight = ProtobufParser.toTimestamp(midNight);
    let statusList = [QuoteStatus.QUOTE_STATUS_EXPIRED, QuoteStatus.QUOTE_STATUS_ACCEPTED, QuoteStatus.QUOTE_STATUS_DONE_AWAY];
    dispatch(SearchQuotes(statusList, midNight));
  },
  listSymbols: () => {
    dispatch(ListSymbols())
  },
  listSelectedInstruments: () => {
    dispatch(ListSelectedInstruments())
  },
  setPageSize: (size) => {
    dispatch(setCurrentPageSize(size))
  },
  listInstrumentsForWatchList: (pageNumber, pageSize) => {
    dispatch(ListInstrumentsForWatchList(pageNumber, pageSize))
  }
});

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.aggregateSubscription = null;
    this.subscribedItems = [];
    this.subscribeBooksOfWatchlistItems = (items = []) => {
      let difference = items.filter((x) => !this.subscribedItems.includes(x));
      if (!difference || difference.length < 1) return;

      if (this.aggregateSubscription) this.aggregateSubscription.cancel();
      this.subscribedItems = [...items];
      this.aggregateSubscription = subscribeBook([...items], (symbol, book, status) => {
        this.props.performUpdateInstrument(symbol, book, status);
      }
      );
    };
    this.handleExecution = this.handleExecution.bind(this);
  }

  async UNSAFE_componentWillMount(){
    await this.props.listAccounts();
  }

  componentDidMount() {
    document.title = TraderDocumentTitle;
    this.props.getWhoAmI();
    this.props.listDealers();
    this.props.listRequesters();
    this.props.fetchMetadata();
    this.props.listSymbols();
    this.props.listSelectedInstruments();
    this.quoteSubscription = this.props.subscribeQuotes();
    this.props.subscribeOrders(null, this.handleExecution, null);
    this.props.searchRfqs();
    this.props.searchQuotes();
  }

  handleExecution = (exec) => {
    if (exec) {
      const execItem = new Execution(exec);
      if (execItem.isReject) {
        let isQuoteAssociated = Object.values(this.props.quotes).find(quote => quote.clordId === execItem.clordId)
        if (isQuoteAssociated) {
          Notification.error(`Submission Rejected for OrderId: ${execItem.orderId}, Reason: ${execItem.text}`);
        }
      }
    }
  };

  componentWillUnmount() {
    this.quoteSubscription.cancel();

    if (this.aggregateSubscription) {
      this.aggregateSubscription.cancel();
    }
  }

  popupSymbol = (symbol) => {
    const features = 'resizable=yes; status=no; scroll=no; help=no; center=yes; width=460; height=640; menubar=no; directories=no; location=no; modal=yes';
    window.open(
      window.location.pathname + "?popup=true#symbol=" + encodeURIComponent(symbol),
      symbol,
      features,
      false
    )
  }
  onWatchListPageSizeChange = async (pageNumber, pageSize) => {
    await this.props.setPageSize(pageSize)
    await this.props.listInstrumentsForWatchList(pageNumber, pageSize)
  }

  render() {
    return (
      <div className="with-callback page-container">
        <Row>
          <Col className="col-lg-3 col-md-12">
            <QuoteBook />
          </Col>
          {!isPopup() ?
            <Col className="col-lg-9 col-md-12">
              <Row>
                <Col>
                  <div className="componentFrame quotes">
                    <div className="table-title">Instruments</div>
                    <Instruments symbolpopup={this.popupSymbol} symbolcb={this.props.subscribeBook} onPageRowsChange={this.subscribeBooksOfWatchlistItems} />
                  </div>
                </Col>
                <Col><RequestForQuotesDataGrid /></Col>
                <Col><QuotesDataGrid /></Col>
              </Row>
            </Col>
            : ''}
        </Row>

        <QuoteTicket />

        {this.props.viewSwapDetail && <SwapDetails />}
        {this.props.watchList.show && <WatchList onWatchListPageSizeChange={this.onWatchListPageSizeChange}></WatchList>}
        {this.props.downloadTrades.show && <DownloadTrades></DownloadTrades>}
        {this.props.notifications.show && <NotificationList></NotificationList>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
