import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "./core/dialog/Dialog";
import OrderService from "../services/OrderService";
import Notification from '../modules/notifications';

const CancelConfirm = ({
    order,
    onClose
}) => {
    return (
        <Dialog 
            show={order} 
            onClose={onClose}
            closeButton={false}
            okButtonText="YES, CANCEL ORDER"
            cancelButtonText="No, don't cancel"
            onOk={() => {
                const cb = (err, response) => {
                    if (response) {
                        Notification.info('Cancel submitted');
                        onClose();
                    }

                    if (err) {
                        Notification.error(`Error when submitting cancel: \n ${err.message}`);
                    }
                };

                OrderService.submitCancelOrder(cb, order.symbol, order);
            }}
        >
        {order && 
            <div className="container">
                <div className="row m-3">
                    <i className="fa fa-3x fa-exclamation-triangle red-icon" />
                </div>
                <div className="row mx-3">
                    <h5>Are you sure you want to cancel order {order.id}?</h5>
                    <h6>This action cannot be undone.</h6>
                </div>
            </div>
        }
        </Dialog>
    )
};

CancelConfirm.propTypes = {
    order: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default CancelConfirm;