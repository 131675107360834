import React from "react";
import PropTypes from "prop-types";
import { ButtonToolbar, Button, ButtonGroup } from "react-bootstrap";

const DataGridActions = ({ onView, onEdit, onCancel, onPopup }) => {
  const viewButton = (
    <Button variant="buttonActions" size="sm" onClick={onView} title="View">
      <i className="fa fa-fw fa-eye orange-icon" />
    </Button>
  );

  const editButton = (
    <Button variant="buttonActions" size="sm" onClick={onEdit} title="Edit">
      <i className="fa fa-fw fa-edit orange-icon" />
    </Button>
  );

  const cancelButton = (
    <Button variant="buttonActions" size="sm" onClick={onCancel} title="Cancel">
      <i className="fa fa-fw fa-times-circle orange-icon" />
    </Button>
  );

  const popupButton = (
    <Button variant="buttonActions" size="sm" onClick={onPopup} title="Popout">
        <i className="fa fa-fw fa-expand orange-icon" />
    </Button>
  );

  return (
    <ButtonToolbar>
      <ButtonGroup>
        {onView && viewButton}
        {onEdit && editButton}
        {onCancel && cancelButton}
        {onPopup && popupButton}
      </ButtonGroup>
    </ButtonToolbar>
  );
};

DataGridActions.propTypes = {
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onCancel: PropTypes.func,
  onPopup: PropTypes.func,
};

export default DataGridActions;
