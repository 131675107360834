import { v4 } from "uuid";
import {apiUrl, convertScaledPriceToInt, sleep} from "../modules/util";
import { apiCall, apiCallPromise, refreshTokens } from "../modules/apiCall";
import { StatusCode } from "grpc-web";

const {
  CreateQuoteRequest,
  CreateQuoteSubscriptionRequest,
  CreateRequestForQuoteRequest,
  DeleteQuoteRequest,
  ListDealersRequest,
  ListRequestersRequest,
  PassQuoteRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/quote_entry_api_pb");

const {
  SearchRequestForQuotesRequest,
  SearchQuotesRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/quote_api_pb")
const {
  QuoteEntryAPIClient,
  QuoteEntryAPIPromiseClient
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/quote_entry_api_grpc_web_pb");

const {
  QuoteAPIClient
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/quote_api_grpc_web_pb")

const {
  RequestForQuote,
  Quote,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/api_pb.js");

const quoteEntryClient = new QuoteEntryAPIClient(
  apiUrl(),
  null,
  null
);

const quoteEntryPromiseClient = new QuoteEntryAPIPromiseClient(
  apiUrl(),
  null,
  null
);

const quoteAPIClient = new QuoteAPIClient(
  apiUrl(),
  null,
  null
)

const activeCalls = new Set();

export default class QuoteService {
  static listDealers(cb) {
    var request = new ListDealersRequest();

    apiCall(quoteEntryClient, "listDealers", request, cb);
  }

  static listRequesters(cb) {
    var request = new ListRequestersRequest();

    apiCall(quoteEntryClient, "listRequesters", request, cb);
  }

  static createQuote(data) {
    var quote = new Quote();

    quote.setClientId(data.clientId);
    quote.setPrice(convertScaledPriceToInt(data.price, data.priceScale));
    quote.setSide(parseInt(data.side));
    quote.setOrderQty(parseInt(data.quantity));
    quote.setSymbol(data.symbol);
    quote.setExpirationTime(data.expirationTime);
    quote.setAccount(data.account);
    quote.setUser(data.user);
    quote.setFirm(data.firm);
    quote.setQuoteRequestId(data.name);
    quote.setClordId(v4());

    var request = new CreateQuoteRequest();
    request.setParent(data.name);
    request.setQuote(quote);

    apiCallPromise(
      quoteEntryPromiseClient,
      "createQuote",
      request,
      "Quote submitted!"
    );
  }

  static createRequestForQuote(data) {
    var requestForQuote = new RequestForQuote();

    requestForQuote.setClientId(v4());
    requestForQuote.setSide(parseInt(data.side));
    requestForQuote.setOrderQty(parseInt(data.quantity));
    requestForQuote.setSymbol(data.symbol);
    requestForQuote.setAccount(data.account);
    requestForQuote.setUser(data.user);
    data.dealers.forEach((dealer) => {
      requestForQuote.addFirms(dealer);
    });
    requestForQuote.setFirm(data.firm);
    requestForQuote.setExpirationTime(data.expirationTime);

    var request = new CreateRequestForQuoteRequest();
    request.setRequestForQuote(requestForQuote);

    apiCallPromise(
      quoteEntryPromiseClient,
      "createRequestForQuote",
      request,
      "Request for Quote submitted!"
    );
  }

  static passQuote(data) {
    var request = new PassQuoteRequest();

    request.setName(data.name);

    apiCallPromise(
      quoteEntryPromiseClient,
      "passQuote",
      request,
      "Passing quote!"
    );
  }

  static deleteQuote(data) {
    var request = new DeleteQuoteRequest();

    request.setName(data.name);

    apiCallPromise(
      quoteEntryPromiseClient,
      "deleteQuote",
      request,
      "Canceling quote!"
    );
  }

  static subscribe(onData, onStatus, onError, onEnd) {
    const request = new CreateQuoteSubscriptionRequest();

    const onErr = (error) => {
      if (error && error.message === "Http response at 400 or 500 level") {
        sleep(1000).then(() =>
          this.subscribe(onData, onStatus, onError, onEnd)
        );
      } else if (error && error.code === StatusCode.UNAUTHENTICATED) {
        refreshTokens().then(() => {
          this.subscribe(onData, onStatus, onError, onEnd);
        });
      }
    };

    var call = apiCall(quoteEntryClient, "createQuoteSubscription", request, null, {data: onData, status: onStatus, end: onEnd, error: onErr});

    for (let entry of activeCalls.values()) {
      entry.cancel();
    }
    activeCalls.clear();
    if (call) {
      activeCalls.add(call);
    }
    return call;
  }

  static searchRfqs(statuses, startDate, cb){
    let request = new SearchRequestForQuotesRequest();

    request.setStatusesList(statuses)
    request.setStartTime(startDate);
    apiCall(quoteAPIClient, "searchRequestForQuotes", request, cb);
  }

  static searchQuotes(statuses, startDate, cb){
    let request = new SearchQuotesRequest();

    request.setStatusesList(statuses)
    request.setStartTime(startDate);
    apiCall(quoteAPIClient, "searchQuotes", request, cb);
  }
}
