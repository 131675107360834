import { CLEAR_ALL_NOTIFICATION, HIDE_NOTIFICATIONS, MOVE_NOTIFICATION, SAVE_NOTIFICATION, SHOW_NOTIFICATIONS } from '../constants/actions';

let initialState = {
    new: [],
    read: [],
    show: false
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_NOTIFICATION: {
            return {
                ...state,
                new: [
                    { ...action.payload },
                    ...state.new
                ]
            };
        }
        case MOVE_NOTIFICATION: {
            return {
                ...state,
                new: [],
                read: [
                    ...state.new,
                    ...state.read,
                ],

            };
        }
        case CLEAR_ALL_NOTIFICATION: {
            return {
                ...state,
                new: [],
                read: []
            };
        }
        case SHOW_NOTIFICATIONS: {
            return {
                ...state,
                show: true
            };
        }
        case HIDE_NOTIFICATIONS: {
            return {
                ...state,
                show: false
            };
        }
        default: return state;
    }
};

export default notificationsReducer;
