import React from "react";
import { connect } from "react-redux";
import Dialog from "./core/dialog/Dialog";
import PropTypes from "prop-types";
import { hideOrderExecution } from "../actions/trader";
import OrderExecutionDetails from "./book/OrderExecutionDetails";

const ViewOrderExecution = ({ show, onClose, accounts, execution }) => {
  return (
    <Dialog title="Execution Details" show={show} onClose={onClose}>
      <OrderExecutionDetails execution={execution} accounts={accounts} />
    </Dialog>
  );
};

ViewOrderExecution.propTypes = {
  show: PropTypes.bool,
  execution: PropTypes.object,
  accounts: PropTypes.object,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { trader } = state;
  const { showOrderExecution } = trader;

  if (!showOrderExecution) {
    return {
      show: false,
    };
  }

  let execution = showOrderExecution.execution;

  return {
    show: execution !== undefined,
    execution
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideOrderExecution());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderExecution);
