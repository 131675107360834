import { UPDATE_COMPLETED_ORDERS } from '../constants/actions';

export const completedOrders = (state = [], action) => {
    switch (action.type) {
        case UPDATE_COMPLETED_ORDERS:
            let newState = [...state];

            action.orders.forEach((o) => {
                let index = state.findIndex((ord) => { return ord.id === o.id; });
                if (index >= 0) {
                    newState[index] = Object.assign({}, state[index], o);
                }
                else
                    newState.push(o);
            });
            return newState;
        default:
            return state;
    }
};