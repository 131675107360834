import { UPDATE_POSITIONS, UPDATE_POSITION, ADD_INSTRUMENT, UPDATE_INSTRUMENT, ADD_INSTRUMENTS } from '../constants/actions'
import { countDecimals, scaleDecimals } from "../modules/util";

const scaleValue = (value, instrument) => {
    let scaledValue = (value * instrument.multiplier) / instrument.scale;
    if (instrument.qtyScale > 1) {
        scaledValue /= instrument.qtyScale;
    }
    return scaledValue;
}

export const calculatePnl = (pos, instrument) => {
    let netPnl, scaledCost, scaledRealized, unrealized, decimals, avgPrice, scaledAvgPrice, qtyScale, qtyDecimals
    if (instrument === undefined) {
        return {
            netPnl,
            scaledCost,
            scaledRealized,
            unrealized,
            decimals,
            avgPrice,
            scaledAvgPrice,
            qtyScale,
            qtyDecimals,
        }
    }
    const { netPosition, cost, realized } = pos
    const { multiplier, scale, last, close, open } = instrument
    qtyScale = instrument.qtyScale > 1 ? instrument.qtyScale : 1;
    qtyDecimals = scaleDecimals(qtyScale);

    decimals = scaleDecimals(scale) + countDecimals(multiplier) + qtyDecimals;
    scaledCost = scaleValue(cost, instrument);
    scaledRealized = scaleValue(realized, instrument);
    netPnl = scaledCost + scaledRealized
    let currentCost;

    if (last !== undefined && last !== null) {
        currentCost = netPosition * last
    } else if (open !== undefined && open !== null) {
        currentCost = netPosition * open
    } else if (close !== undefined && close !== null) {
        currentCost = netPosition * close
    } else if (cost) {
        currentCost = -1 * cost
    } else {
        currentCost = 0
    }

    const scaledCurrentCost = scaleValue(currentCost, instrument);
    netPnl += scaledCurrentCost;

    unrealized = scaledCurrentCost + scaledCost;

    if (decimals !== undefined && cost !== undefined && netPosition !== undefined && netPosition !== 0 && cost !== 0) {
        avgPrice = parseFloat(Math.abs(cost / netPosition).toFixed(decimals));
        scaledAvgPrice = scaleValue(avgPrice, instrument) * qtyScale;
    }

    return { netPnl, scaledCost, scaledRealized, unrealized, decimals, avgPrice, scaledAvgPrice, qtyScale, qtyDecimals }
}

export const position = (state = {}, action) => {
    const { type } = action

    switch (type) {
        case ADD_INSTRUMENT:
        case UPDATE_INSTRUMENT: {
            const { instrument } = action
            const { symbol, baseCurrency } = instrument

            if (state.symbol !== symbol) {
                return state
            }

            return Object.assign({}, state, { baseCurrency }, calculatePnl(state, instrument))
        }
        case ADD_INSTRUMENTS: {
            const { instruments } = action
            const instrument = instruments.find(instrument => instrument.symbol === state.symbol);
            if (instrument == null)
                return state;
            const { baseCurrency } = instrument;
            return Object.assign({}, state, { baseCurrency }, calculatePnl(state, instrument))
        }

        case UPDATE_POSITION: {
            const { position: pos, instruments } = action
            const { account, symbol, symbolSubType } = pos

            if (state.account !== account || state.symbol !== symbol || state.symbolSubType !== symbolSubType) {
                return state
            }

            let instrument
            if (instruments !== undefined) {
                instrument = instruments[symbol]
            }

            let baseCurrency
            if (instrument !== undefined) {
                baseCurrency = instrument.baseCurrency
            }

            return Object.assign({}, pos, { baseCurrency }, calculatePnl(pos, instrument))
        }

        default:
            return state
    }
}

export const positions = (state = [], action) => {
    const { type } = action

    switch (type) {
        case ADD_INSTRUMENT:
        case ADD_INSTRUMENTS:
        case UPDATE_INSTRUMENT:
            return state.map(p => position(p, action))

        case UPDATE_POSITION:
            const { position: pos } = action
            const { symbol, account, symbolSubType } = pos
            let p = state.find((s) => {
                return s.symbol === symbol && s.account === account && s.symbolSubType === symbolSubType
            })

            if (p === undefined) {
                return [...state, position(pos, action)]
            }

            return state.map(
                p => position(p, action)
            )
        case UPDATE_POSITIONS:
            const { positions: ps, instruments } = action

            ps.map(
                p => state = positions(state,
                    {
                        type: UPDATE_POSITION,
                        position: p,
                        instruments
                    })
            )

            return state
        default:
            return state;
    }
}

