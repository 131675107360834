import React from 'react';
import PropTypes from 'prop-types';
import './Buttons.css';

const ButtonMain = ({
  type,
  text,
  customClassName,
  disabled,
  onClick
}) => (
    <button
      type={type}
      className= {`btn-main${customClassName ? ' ' + customClassName : '' }`}
      disabled={!!disabled}
      onClick={onClick}
    >{text}</button>
  )

ButtonMain.defaultProps = {
  type: 'submit',
  text: '',
}

ButtonMain.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

export default ButtonMain;