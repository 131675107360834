import { apiCall } from "../modules/apiCall";
import { DEFAULT_PAGE_SIZE } from "../constants/strings";
import { apiUrl } from "../modules/util";

const {
  ListInstrumentsRequest,
  GetInstrumentMetadataRequest,
  ListSymbolsRequest
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_api_pb.js");
const {
  MarketDataAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/market_data_api_grpc_web_pb");

const client = new MarketDataAPIClient(
  apiUrl(),
  null,
  null
);

export default class MarketService {
  static getInstrumentMetadata(cb) {
    const request = new GetInstrumentMetadataRequest();
    apiCall(client, "getInstrumentMetadata", request, cb);
  }

  static listInstruments(pageToken, symbols, productId, cb) {
    if (!Array.isArray(symbols) && symbols !== '') {
      symbols = [symbols]
    }
    const request = new ListInstrumentsRequest();
    request.setPageSize(DEFAULT_PAGE_SIZE);
    if (symbols && symbols.length !== 0) request.setSymbolsList(symbols);
    if (productId) request.setProductId(productId);
    if (pageToken) request.setPageToken(pageToken);

    apiCall(client, "listInstruments", request, cb);
  }

  static listSymbols(cb) {
    const request = new ListSymbolsRequest()
    apiCall(client, 'listSymbols', request, cb)
  }
}
