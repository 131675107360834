import {
  ADD_QUOTE_INSTRUMENTS,
  SET_LOADING_INSTRUMENTS,
  UPDATE_QUOTE_SUBSCRIPTION,
  UPDATE_DEALERS,
  UPDATE_REQUESTERS,
  LOGIN_RESET,
  SET_VIEW_CONTEXT,
  SET_GRID_CONTEXT,
  FETCH_METADATA_START,
  FETCH_METADATA_SUCCEED,
  FETCH_METADATA_FAILED,
} from "../constants/actions";
import produce, { original } from "immer";

const initialState = {
  dealers: [],
  requesters: [],
  instruments: {},
  loadingInstruments: false,
  metadata: null,
  requestForQuotes: {},
  quotes: {},
  quoteRejects: {},
  tokens: {},
  pageToken: null,
  appContext: {
    id: null,
    symbol: null,
    context: null,
    viewSwapDetail: false,
    viewQuoteTicket: false,
  },
};

const quotes = produce((draft, action) => {
  const { type, payload } = action;
  const state = original(draft);

  switch (type) {
    case SET_VIEW_CONTEXT:
      for (let [key, value] of Object.entries(payload)) {
        draft.appContext[key] = value;
      }
      return;

    case SET_GRID_CONTEXT:
      for (let [key, value] of Object.entries(payload)) {
        draft.appContext[key] = value;
      }

      if (state.appContext.id) {
        if (state.appContext.context === "requestForQuotes") {
          draft.requestForQuotes[state.appContext.id].selected = false;
        }

        if (state.appContext.context === "quotes") {
          draft.quotes[state.appContext.id].selected = false;
        }
      }

      if (payload.context === "requestForQuotes") {
        draft.requestForQuotes[payload.id].selected = true;
      }

      if (payload.context === "quotes") {
        draft.quotes[payload.id].selected = true;
      }

      return;

    case ADD_QUOTE_INSTRUMENTS:
      payload.instrumentsList.forEach((instrument) => {
        draft.instruments[instrument.symbol] = instrument;
      });
      draft.pageToken = payload.pageToken;
      draft.tokens[payload.pageToken] = payload.nextPageToken;
      return;

    case SET_LOADING_INSTRUMENTS:
      draft.loadingInstruments = payload;
      return;

    case UPDATE_QUOTE_SUBSCRIPTION:
      payload.rfqList.forEach((requestForQuote) => {
        draft.requestForQuotes[requestForQuote.id] = requestForQuote;
      });

      payload.quotesList.forEach((quote) => {
        draft.quotes[quote.id] = quote;
      });

      payload.quoteRejectsList.forEach((quoteRejects) => {
        draft.quoteRejects[quoteRejects.id] = quoteRejects;
      });

      return;

    case UPDATE_DEALERS:
      draft.dealers = payload;
      return;

    case UPDATE_REQUESTERS:
      draft.requesters = payload;
      return;

    case LOGIN_RESET:
      return initialState;

    case FETCH_METADATA_START:
      draft.metadata = null;
      return

    case FETCH_METADATA_SUCCEED:

      draft.metadata = payload;
      return

    case FETCH_METADATA_FAILED:
      draft.metadata = null;
      return

    default:
      return;
  }
}, initialState);

export default quotes;
