import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../core/data-grid/DataGrid';
import { convertDateToString, parsePrice, validatePrice } from "../../modules/util";
import _ from 'lodash';
import { connect } from 'react-redux';
import i18n from "i18next";
import { instrumentTitle } from "./Instruments";
import DataGridActions from "../core/data-grid/DataGridActions";
import OrderService from "../../services/OrderService";
import Execution from "../../entities/Execution";
import { viewOrderExecution } from "../../actions/trader";
import {Env} from "../../constants/environment";

const { SearchExecutionsRequest } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/order_api_pb");

const CompletedOrdersDataGrid = ({
  scale,
  qtyScale,
  accounts,
  data,
  onView
}) => {
  let columns = [
    {
      Header: i18n.t('symbol').toUpperCase(),
      id: 'symbol',
      accessor: d => instrumentTitle(d.symbol),
      minWidth: 200,
      maxWidth: 300
    },
    {
      Header: 'SUB TYPE',
      id: 'subType',
      accessor: d => d.symbolSubType,
      maxWidth: 80
    },
    {
      Header: 'SIDE',
      id: 'side',
      accessor: d => d.side.name,
      maxWidth: 60
    },
    {
      Header: 'QTY',
      id: 'qty',
      accessor: d => parsePrice(d.quantity, qtyScale[d.symbol]),
      maxWidth: 60
    },
    {
      Header: 'PX',
      id: 'px',
      accessor: d => _.includes(d.orderType.name.toLowerCase(), 'limit') && validatePrice(d.price) ? parsePrice(d.price, scale[d.symbol]) : null,
      maxWidth: 70
    }
  ];
  if (!Env.getEnvBool("REACT_APP_HIDE_STOP_PX")) {
    columns.push(
      {
        Header: 'STOP PX',
        id: 'stopPx',
        accessor: d => _.includes(d.orderType.name.toLowerCase(), 'stop') && validatePrice(d.stopPrice) ? parsePrice(d.stopPrice, scale[d.symbol]) : null,
        maxWidth: 70
      }
    );
  }
  columns.push(
    {
      Header: 'TYPE',
      id: 'ordertype',
      accessor: d => d.orderType.name,
      maxWidth: 80
    },
    {
      Header: 'STATUS',
      id: 'status',
      accessor: d => d.status.state.name,
      maxWidth: 80
    },
    {
      Header: 'CUM QTY',
      id: 'cumqty',
      accessor: d => parsePrice(d.status.cumQty, qtyScale[d.symbol]),
      maxWidth: 80
    },

    {
      Header: 'ACCOUNT',
      id: 'account',
      accessor: d => !!accounts && accounts.has(d.account) ? accounts.get(d.account) : d.account,
      maxWidth: 80
    },
    {
      Header: 'ORDER ID',
      id: 'orderId',
      accessor: d => d.id,
      maxWidth: 80
    },
    {
      Header: 'CLORDId',
      id: 'clordId',
      accessor: d => d.clordId,
      maxWidth: 80
    },
    {
      Header: 'TIMESTAMP',
      id: 'timestamp',
      accessor: d => convertDateToString(d.insertedOn || d.createdOn, "YYYYMMDD-HH:mm:ss.SSS"),
      minWidth: 150,
      maxWidth: 250
    },
    {
      sortable: false,
      Cell: (row) => {
        return (
          <DataGridActions
            onView={() => {
              let request = new SearchExecutionsRequest();
              request.setSymbol(row.original.symbol);
              request.setOrderId(row.original.id);
              request.setNewestFirst(true);

              OrderService.searchOrderExecutions((executions) => {
                if (executions && executions.length > 0) {
                  const execution = new Execution(executions[0]);
                  onView(row.original.id, execution);
                }
              }, request);
            }}
          />
        );
      },
      maxWidth: 50,
      className: "action-column",
    }
  );

  return (
    <div className="CompletedOrdersDataGrid">
      <DataGrid
        data={data}
        columns={columns}
        filterable={true}
        defaultSortedField="timestamp"
        defaultSortedDesc={true}
        showPagination={false}
      >
      </DataGrid>
    </div>
  )
};

CompletedOrdersDataGrid.propTypes = {
  data: PropTypes.array.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  onView: (id, execution) => {
    dispatch(viewOrderExecution(id, execution));
  },
});

function mapStateToProps(state) {
  const { trader } = state
  const { completedOrders, scale, qtyScale, accounts } = trader
  return {
    data: completedOrders,
    scale,
    qtyScale,
    accounts,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompletedOrdersDataGrid);