import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from 'react-widgets';

const dateMin = new Date('1900-01-01T00:00:00Z');
const dateMax = new Date('2900-01-01T00:00:00Z');
const DateTimePickerControl = ({
  enableTime,
  step,
  dropUp,
  minDate,
  maxDate,
  value,
  enabled,
  defaultCurrentDate,
  onChange,
  onBlur
}) => (
    <DateTimePicker
      dropUp={dropUp}
      time={enableTime}
      step={step}
      min={minDate || dateMin}      
      max={maxDate || dateMax}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={!enabled}      
      format={`MM/DD/YYYY${enableTime ? ' hh:mm:ssA' : ''}`}
      defaultCurrentDate={defaultCurrentDate}
      containerClassName={enableTime ? 'date-and-time-picker' : ''}
    />
  )

DateTimePickerControl.defaultProps = {
  enableTime:         false,
  dropUp:             false,
  minDate:            dateMin,
  maxDate:            dateMax,
  value:              new Date(),
  enabled:            true,
  step:               30,
  defaultCurrentDate: null
}

DateTimePickerControl.propTypes = {
  enableTime: PropTypes.bool,
  dropUp:     PropTypes.bool,
  minDate:    PropTypes.any,
  maxDate:    PropTypes.any,
  value:      PropTypes.any,
  enabled:    PropTypes.bool,
  step:       PropTypes.number,
  onChange:   PropTypes.func.isRequired
}

export default DateTimePickerControl;