import React from "react";
import {
  isNullOrUndefined,
  parsePrice,
  validatePrice,
} from "../../modules/util";
import "./styles.css";
import ButtonMain from "../core/form/ButtonMain";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SetViewContext } from "../../actions/app";
import Notification from "../../modules/notifications";
import Quote from "../../../src/entities/Quote";
import { isPopup } from "../shared/AdminWrapper";

const BookEntry = ({ isBid, scale, qtyScale, entry }) => {
  return (
    <div className="row book-entry no-gutters">
      <div className={"col text-center qty " + (isBid ? "order-1" : "order-2")}>
        {parsePrice(entry.qty, qtyScale)}
      </div>
      <div
        className={
          "col text-center " + (isBid ? "order-2 bid-px" : "order-1 offer-px")
        }
      >
        {validatePrice(entry.px) ? parsePrice(entry.px, scale) : null}
      </div>
    </div>
  );
};

BookEntry.propTypes = {
  isBid: PropTypes.bool,
  scale: PropTypes.number,
  qtyScale: PropTypes.number,
  entry: PropTypes.object,
};

const BookEntries = ({ isBid, entries, scale, qtyScale }) => {
  return (
    <div
      className={
        "col container-fluid book-entries mh-100 " +
        (isBid ? "book-entries-bids" : "")
      }
    >
      <div className="row no-gutters">
        <div className="col text-center book-heading">
          <div className="book-heading-title">{isBid ? "Bids" : "Offers"}</div>
        </div>
      </div>

      <div className="row no-gutters book-subheading">
        <div
          className={"col text-center qty " + (isBid ? "order-1" : "order-2")}
        >
          QUANTITY
        </div>
        <div className={"col text-center " + (isBid ? "order-2" : "order-1")}>
          PRICE
        </div>
      </div>

      {entries.map((entry, i) => (
        <BookEntry key={i} entry={entry} isBid={isBid} scale={scale} qtyScale={qtyScale} />
      ))}
    </div>
  );
};

BookEntry.propTypes = {
  isBid: PropTypes.bool,
  scale: PropTypes.number,
  entries: PropTypes.array,
};

const QuoteBook = ({
  data,
  id,
  symbol,
  context,
  instruments,
  requestForQuotes,
  quotes,
  setViewContext,
}) => {
  let bids = [];
  let offers = [];

  for (let i = 0; i < data.length; i++) {
    if (validatePrice(data[i].pxBid)) {
      bids.push({ qty: data[i].qtyBid, px: data[i].pxBid });
    }

    if (validatePrice(data[i].pxOffer)) {
      offers.push({ qty: data[i].qtyOffer, px: data[i].pxOffer });
    }
  }

  const processRfq = (quote) => {
    if (Quote.isExpiredOrRejectedOrDeleted(quote.statusName)) {
      let type = !isNullOrUndefined(quote.quoteRequestId)
        ? "Quote"
        : "Request for Quote";
      Notification.info(`This ${type} is ${quote.statusName}`);
      return;
    }
    if (quote.quoteRequestId) {
      const RFQID = quote.quoteRequestId.replace("requestForQuotes/", "");
      let rfq = requestForQuotes[RFQID];
      if (Quote.isExpiredOrRejectedOrDeleted(rfq.statusName)) {
        Notification.info(`Request for Quote is ${rfq.statusName}`);
        return;
      }
    }
    setViewContext({ viewQuoteTicket: true });
  };

  const nonTradable = instruments[symbol]
    ? !!instruments[symbol].nonTradable
    : false;

  return (
    <div
      className={`AggregatedBookDataGrid ${
        isPopup() ? "" : "componentFrameFixed"
      }`}
    >
      <div>&nbsp;</div>
      <h3 className="orderbooktext">
        {id ? symbol || id : "Select an item"}
      </h3>
      <div className="text-center">
        {(() => {
          switch (context) {
            case "instruments":
              return (
                <ButtonMain
                  type="button"
                  onClick={() => setViewContext({ viewQuoteTicket: true })}
                  disabled={!symbol || nonTradable}
                  text={"Place RFQ"}
                />
              );
            case "requestForQuotes":
              return (
                <ButtonMain
                  type="button"
                  onClick={() => processRfq(requestForQuotes[id])}
                  disabled={!symbol || nonTradable}
                  text={"Place Quote"}
                />
              );
            case "quotes":
              return (
                <ButtonMain
                  type="button"
                  onClick={() => processRfq(quotes[id])}
                  disabled={!symbol || nonTradable}
                  text={"Respond to Quote"}
                />
              );
            default:
              return (
                <ButtonMain type="button" disabled={true} text={"Place RFQ"} />
              );
          }
        })()}
      </div>
      <div className="book container-fluid h-100 mt-3">
        <div className="row no-gutters">
          <BookEntries
            entries={bids}
            isBid={true}
            scale={instruments[symbol] ? instruments[symbol].scale : null}
            qtyScale={instruments[symbol] ? instruments[symbol].qtyScale : null}
          />
          <BookEntries
            entries={offers}
            isBid={false}
            scale={instruments[symbol] ? instruments[symbol].scale : null}
            qtyScale={instruments[symbol] ? instruments[symbol].qtyScale : null}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setViewContext: (payload) => {
    dispatch(SetViewContext(payload));
  },
});

function mapStateToProps(state) {
  return {
    id: state.quotes.appContext.id,
    symbol: state.quotes.appContext.symbol,
    context: state.quotes.appContext.context,
    data: state.trader.book,
    instruments: state.trader.instruments,
    requestForQuotes: state.quotes.requestForQuotes,
    quotes: state.quotes.quotes,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteBook);
