import React from "react";
import { connect } from "react-redux";
import Dialog from "./core/dialog/Dialog";
import PropTypes from "prop-types";
import { hideExecution } from "../actions/trader";
import { EnhancedExecutionDetails } from "./book/ExecutionDetails";
import { parsePrice, validatePrice } from "../modules/util";

const ViewExecution = ({ show, onClose, accounts, execution }) => {
  return (
    <Dialog title="Execution Details" show={show} onClose={onClose}>
      <EnhancedExecutionDetails execution={execution} accounts={accounts} />
    </Dialog>
  );
};

ViewExecution.propTypes = {
  show: PropTypes.bool,
  execution: PropTypes.object,
  accounts: PropTypes.object,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { trader } = state;
  const { showExecution, scale, qtyScale } = trader;

  if (!showExecution) {
    return {
      show: false,
    };
  }

  const { id, account } = showExecution;

  let execution = trader.executions.find(
    (p) => p.id === id && p.account === account
  );
  execution.fillPx = validatePrice(execution.lastPx)
    ? parsePrice(execution.lastPx, scale[execution.symbol])
    : null;
  execution.orderPxScaled = validatePrice(execution.orderPx)
    ? parsePrice(execution.orderPx, scale[execution.symbol])
    : null;
  execution.lastSharesScaled = parsePrice(execution.lastShares, qtyScale[execution.symbol]);

  return {
    show: execution !== undefined,
    execution,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideExecution());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewExecution);
