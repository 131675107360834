import React from 'react';

const NoAuth = () => (
  <div className="content-login-box">
    <div className="login-box">
      <img className="logo" alt="logo-v" src="/favicon.ico" />
      <img className="logo-light" alt="logo-v" src="/favicon.ico" />
      <h1 className="title">WHOOPS!</h1>
      <div className="content">
        {/* TODO: replace styles below with classes */}
        <h3 style={{ color: '#fff' }}>You are not authorized to access this page.</h3>
      </div>
    </div>
  </div>
);

export default NoAuth;
