import { getEnumName, capitalizeWord } from "../modules/util";

import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import { QuoteStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/quotes/v1beta1/quotes_pb";
import ProtobufParser from "../modules/protobufParser";

export default class RequestForQuote {
  static get(item) {
    const object = item.toObject();
    return {
      id: object.id,
      createdOn: ProtobufParser.tsProtoToDate(item.getInsertTime()),
      expiredOn: ProtobufParser.tsProtoToDate(item.getExpirationTime()),
      name: object.name,
      clientId: object.clientId,
      sideId: object.side,
      sideName: capitalizeWord(getEnumName(Side, object.side)),
      quantity: object.orderQty,
      symbol: object.symbol,
      account: object.account,
      user: object.user,
      firmsList: object.firmsList,
      statusId: object.status,
      statusName: capitalizeWord(getEnumName(QuoteStatus, object.status)),
      firm: object.firm,
      selected: false,
    };
  }
}
