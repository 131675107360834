import React from "react";
import { connect } from "react-redux";
import DataGrid from "../../core/data-grid/DataGrid";
import "../styles.css";
import { SetGridContext, SetViewContext } from "../../../actions/app";
import DataGridActions from "../../core/data-grid/DataGridActions";
import { SubscribeBook } from "../../../actions/books";
import moment from "moment";
import {capitalizeWord, parsePrice} from "../../../modules/util";
import i18n from 'i18next'

const RequestForQuotesDataGrid = ({
  requestForQuotes,
  users,
  firms,
  dealers,
  requesters,
  id,
  setGridContext,
  subscribeBook,
  instruments,
  setViewContext,
}) => {
  const getTrProps = (state, rowInfo, column, instance) => {
    return {
      onClick: () => {
        setGridContext({
          id: rowInfo.original.id,
          symbol: rowInfo.original.symbol,
          context: "requestForQuotes",
        });
        subscribeBook(rowInfo.original.symbol);
      },
      className: rowInfo.original.id === id ? "trselected" : null,
    };
  };

  let firmNames = new Map(firms);
  if (Array.isArray(dealers)) dealers.forEach(elem => firmNames.set(elem.value, elem.label));
  if (Array.isArray(requesters)) requesters.forEach(elem => firmNames.set(elem.value, elem.label));

  const columns = [
    {
      Header: "CREATED ON",
      id: "createdOn",
      accessor: (d) =>
        moment(d.createdOn).local().format("MMMM Do YYYY, h:mm:ss a"),
      minHeight: 10,
      maxWidth: 180,
      className: "createdOn",
    },
    {
      Header: "SYMBOL",
      id: "symbol",
      accessor: (d) => d.symbol,
      maxWidth: 100,
      className: "symbol",
    },
    {
      Header: "SIDE",
      id: "sideName",
      accessor: (d) => i18n.t(d.sideName.toLowerCase(), capitalizeWord(d.sideName) ),
      maxWidth: 60,
      className: "sideName",
    },
    {
      Header: "QTY",
      id: "quantity",
      accessor: (d) =>
          parsePrice(
              d.quantity,
              instruments[d.symbol] ? instruments[d.symbol].qtyScale : 1
          ),
      maxWidth: 60,
      className: "quantity",
    },
    {
      Header: "STATUS",
      id: "statusName",
      accessor: (d) => d.statusName,
      maxWidth: 80,
      className: "statusName",
    },
    {
      Header: "EXPIRATION TIME",
      id: "expiredOn",
      accessor: (d) =>
        moment(d.expiredOn).local().format("MMMM Do YYYY, h:mm:ss a"),
      maxWidth: 180,
      className: "expiredOn",
    },
    {
      Header: "USER",
      id: "user",
      accessor: (d) => !!users && users.has(d.user) ? users.get(d.user) : String(d.user).replace(d.firm+"/users/", ''),
      maxWidth: 150,
      className: "user",
    },
    {
      Header: "FIRM",
      id: "firm",
      accessor: (d) => firmNames.has(d.firm) ? firmNames.get(d.firm) : d.firm,
      maxWidth: 150,
      className: "firm",
    },
    {
      id: "action",
      maxWidth: 120,
      minWidth: 120,
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <DataGridActions
          onView={() =>
            setViewContext({
              viewSwapDetail: true,
            })
          }
        />
      ),
    },
  ];

  return (
    <div className="componentFrame">
      <div className="table-title">Request For Quotes</div>
      <DataGrid
        data={requestForQuotes ? requestForQuotes : []}
        columns={columns}
        filterable={true}
        getTrProps={getTrProps}
        defaultSortedField="createdOn"
        defaultSortedDesc={true}
        showPagination={false}
      ></DataGrid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setGridContext: (payload) => {
    dispatch(SetGridContext(payload));
  },
  setViewContext: (payload) => {
    dispatch(SetViewContext(payload));
  },
  subscribeBook: (payload) => {
    dispatch(SubscribeBook(payload));
  },
});

const mapStateToProps = (state) => {
  return {
    requestForQuotes: Object.values(state.quotes.requestForQuotes),
    dealers: state.quotes.dealers,
    requesters: state.quotes.requesters,
    users: state.trader.users,
    firms: state.trader.firms,
    id: state.quotes.appContext.id,
    instruments: state.quotes.instruments,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestForQuotesDataGrid);
