import { store } from "../store";

export const getAccessToken = () => {
  return store.getState().auth.accToken;
};

export const getRefreshToken = () => {
  return store.getState().auth.refToken;
};

export const hasWriteAccess = () => {
  return store.getState().auth.hasWriteAccess;
};
