import { UPDATE_EXECUTIONS } from '../constants/actions';
import Execution from '../entities/Execution';

export function updateExecutions(pbExecutionsList) {
  const executions = pbExecutionsList.map(e => {
    return new Execution(e);
  });
  return {
    type: UPDATE_EXECUTIONS,
    executions: executions
  };
}