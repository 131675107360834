import { toast } from "react-toastify";
import {saveNotification} from "../actions/notifications"
import {store} from '../store'
import {Env} from "../constants/environment";
const toastSuccessTimeoutEnv = Env.getEnv("REACT_APP_TOAST_SUCCESS_TIMEOUT");
const toastErrorTimeoutEnv = Env.getEnv("REACT_APP_TOAST_ERROR_TIMEOUT");
const toastSuccessTimeout = toastSuccessTimeoutEnv && Number(toastSuccessTimeoutEnv) ? Number(toastSuccessTimeoutEnv) : false;
const toastErrorTimeout = toastErrorTimeoutEnv && Number(toastErrorTimeoutEnv) ? Number(toastErrorTimeoutEnv) : false;

export default class Notification {
  static success(message) {
    toast.success(`${message}`, {
      autoClose: toastSuccessTimeout,
      className: "custom-toast custom-toast-success",
    });
    store.dispatch(saveNotification(message,'success'))
  }

  static error(message) {
    if (!toast.isActive(message)) {
      toast.error(`${message}`, {
        toastId: message,
        autoClose: toastErrorTimeout,
        className: "custom-toast custom-toast-error",
      });
      store.dispatch(saveNotification(message,'error'))
    }
  }

  static warning(message) {
    toast.warn(message, {
      autoClose: toastErrorTimeout,
      className: "custom-toast custom-toast-warning",
    });
    store.dispatch(saveNotification(message,'warning'))
  }

  static info(message) {
    toast.info(message, {
      autoClose: toastSuccessTimeout,
      className: "custom-toast custom-toast-info",
    });
    store.dispatch(saveNotification(message,'info'))
  }
}
