import React from 'react'
import {Field, withFormik} from "formik";
import PropTypes from 'prop-types';

const PositionDetails = () => {

    return (
    <div className="PositionDetail">
        <form>
            <div className = 'form-row'>
                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='account'>Account</label>
                    <Field id="account" name="account" className='form-control-plaintext' type='text' readOnly/>
                </div>

                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='symbol'>Symbol</label>
                    <Field id="symbol" name="symbol" className='form-control-plaintext' type='text' readOnly/>
                </div>
            </div>
            <div className = 'form-row'>
                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='bod'>BOD Position</label>
                    <Field id="bod" name="bod" className='form-control-plaintext' type='text' readOnly/>
                </div>

                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='net'>Net Position</label>
                    <Field id="net" name="net" className='form-control-plaintext' type='text' readOnly/>
                </div>
            </div>
            <div className = 'form-row'>
                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='bought'>Qty Bought</label>
                    <Field id="bought" name="bought" className='form-control-plaintext' type='text' readOnly/>
                </div>

                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='sold'>Qty Sold</label>
                    <Field id="sold" name="sold" className='form-control-plaintext' type='text' readOnly/>
                </div>
            </div>
            <div className = 'form-row'>
                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='cost'>Cost of Position</label>
                    <Field id="cost" name="cost" className='form-control-plaintext' type='text' readOnly/>
                </div>

                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='realized'>Realized P&L</label>
                    <Field id="realized" name="realized" className='form-control-plaintext' type='text' readOnly/>
                </div>
            </div>
            <div className = 'form-row'>
                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='unrealized'>Unrealized P&L</label>
                    <Field id="unrealized" name="unrealized" className='form-control-plaintext' type='text' readOnly/>
                </div>

                <div className = 'form-group col'>
                    <label className = 'form-control-sm' htmlFor='netPnl'>Net P&L</label>
                    <Field id="netPnl" name="netPnl" className='form-control-plaintext' type='text' readOnly/>
                </div>
            </div>
        </form>
    </div>
    )
}

PositionDetails.propTypes = {
    position: PropTypes.object,
    accounts: PropTypes.object
}

export const EnhancedPositionDetails = withFormik({
    mapPropsToValues: (props) => {
        if (props.position) {
            const acct = props.position.account;
            return {
                account: !!props.accounts && props.accounts.has(acct) ? props.accounts.get(acct) : acct,
                symbol: props.position.symbol,
                bod: !!props.position.qtyDecimals ? (props.position.bod / props.position.qtyScale).toFixed(props.position.qtyDecimals) : props.position.bod,
                net: !!props.position.qtyDecimals ? (props.position.netPosition / props.position.qtyScale).toFixed(props.position.qtyDecimals) : props.position.netPosition,
                bought: !!props.position.qtyDecimals ? (props.position.qtyBought / props.position.qtyScale).toFixed(props.position.qtyDecimals) : props.position.qtyBought,
                sold: !!props.position.qtyDecimals ? (props.position.qtySold / props.position.qtyScale).toFixed(props.position.qtyDecimals) : props.position.qtySold,
                cost: props.position.scaledCost === undefined ? '' : props.position.scaledCost.toFixed(props.position.decimals) + ' ' + props.position.baseCurrency,
                realized: props.position.scaledRealized === undefined ? '' : props.position.scaledRealized.toFixed(props.position.decimals) + ' ' + props.position.baseCurrency,
                unrealized: props.position.unrealized === undefined ? '' : props.position.unrealized.toFixed(props.position.decimals) + ' ' + props.position.baseCurrency,
                netPnl: props.position.netPnl === undefined ? '' : props.position.netPnl.toFixed(props.position.decimals) + ' ' + props.position.baseCurrency,
            }
        } else {
            return {
                account: '',
                symbol: '',
                bod: '',
                net: '',
                bought: '',
                sold: '',
                cost: '',
                realized: '',
                unrealized: '',
                netPnl: '',
            }
        }
    }})(PositionDetails)