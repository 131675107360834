import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import auth from "./auth";
import app from "./app";
import { trader } from "./trader";
import quotes from "./quotes";
import watchList from "./watchlist";
import downloadTrades from "./downloadTrades";
import notifications from "./notifications";
import { watchListConfig } from "../store";

const rootReducer = (history) =>
  combineReducers({
    app,
    auth,
    trader,
    quotes,
    watchList: persistReducer(watchListConfig, watchList),
    downloadTrades,
    notifications,
    router: connectRouter(history),
  });

export default rootReducer;
