import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "./core/dialog/Dialog";
import { EnhancedOrderTicketForm } from "./book/OrderTicketForm";
import OrderService from "../services/OrderService";
import Notification from '../modules/notifications';
import {Env} from "../constants/environment";

const OrderTicket = ({
    show,
    onClose,
    symbol,
    subType,
    minTradeQty,
    accounts,
    instruments,
    users,
    usersToAccounts,
    firms,
    firmsToUsers,
    priceScale,
    qtyScale,
    sessionId,
    placingOrder,
    placingCross,
    onSubmit,
}) => {
    return (
        <Dialog title="New Order" show={show} onClose={onClose}>
            <EnhancedOrderTicketForm
                symbol={symbol}
                subType={subType}
                accounts={accounts}
                instruments={instruments}
                users={users}
                usersToAccounts={usersToAccounts}
                firms={firms}
                firmsToUsers={firmsToUsers}
                priceScale={priceScale}
                qtyScale={qtyScale}
                minTradeQty={minTradeQty}
                placingOrder={placingOrder}
                placingCross={placingCross}
                doSubmit={(values) => {
                    const cb = (err, response) => {
                        if (response) {
                            Notification.info('Order submitted');
                        }

                        if (err) {
                            Notification.error(`Error when submitting order: \n ${err.message}`);
                        }
                    };
                    const cxlOnDisconnect = Env.getEnvBool("REACT_APP_CANCEL_ON_DISCONNECT");
                    if (cxlOnDisconnect && !sessionId) {
                        Notification.error('Error when submitting order: session ID unavailable');
                        return;
                    }
                    if (placingCross) {
                        OrderService.submitNewCross(cb, symbol, values);
                    } else {
                        OrderService.submitNewOrder(cb, symbol, values, cxlOnDisconnect ? sessionId : "");
                    }
                    onSubmit(values);
                }}
            />
        </Dialog>
    );
};

OrderTicket.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    symbol: PropTypes.string,
    minTradeQty: PropTypes.number,
    accounts: PropTypes.instanceOf(Map),
    instruments: PropTypes.object,
    users: PropTypes.instanceOf(Map),
    usersToAccounts: PropTypes.instanceOf(Map),
    firms: PropTypes.instanceOf(Map),
    firmsToUsers: PropTypes.instanceOf(Map),
    accountsDisplayNames: PropTypes.array,
    priceScale: PropTypes.number,
    qtyScale: PropTypes.number,
    placingOrder: PropTypes.any,
}

export default OrderTicket;
