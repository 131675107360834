import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {Env} from "./constants/environment";

const resources = {
  en: {
    translation: {
      instruments: "Instruments",
      instrument: "Instrument",
      order_book: "Order Book",
      contract_details: "Contract Details",
      fixed_leg_details: "Fixed Leg Details",
      float_leg_details: "Float Leg Details",
      float_leg_1_details: "Float Leg 1 Details",
      float_leg_2_details: "Float Leg 2 Details",
      derivative_details: "Derivative Details",
      symbol: "Symbol",
      market: "Market",
      market_active: "Active",
      market_hidden: "Hidden",
      net_position: "Net Position",
      net_pnl: "Net P&L",
      quantity: "Quantity",
      cashOrderQty: "Cash Quantity",
      price: "Price",
      buy: "Buy",
      sell: "Sell",
      open: "Open",
      high: "High",
      low: "Low",
      close: "Prev Close",
      undefined: "Two-Sided",
      event_details: "Event Details",
      non_deliverable_forward_details: "Non Deliverable Forward Details",
      forex_details: "Foreign Exchange (Forex) Details",
    }
  },
  en_swap: {
    translation: {
      quantity: "Notional",
      price: "Rate",
      buy: "Pay",
      sell: "Receive"
    }
  },
  en_sport: {
    translation: {
      instruments: "Sporting RTI Policies - Current & Upcoming Events",
      instrument: "Policy",
      order_book: "",
      contract_details: "Policy Details",
      symbol: "Policy",
      market_active: "Secondary",
      market_hidden: "Primary",
      close: "Primary Close",
      net_position: "Quantity"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: ["en"],
    debug: false,
    keySeparator: true,
    interpolation: {
      escapeValue: false
    }
  }, (err, t) => {
    const langEnv = Env.getEnv("REACT_APP_LANGUAGE");
    if (langEnv) {
      i18n.changeLanguage(langEnv)
    }
  });

export default i18n;
