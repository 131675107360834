import {
  LOGIN_RESET,
  SHOW_DOWNLOAD_TRADES,
  HIDE_DOWNLOAD_TRADES,
} from "../constants/actions";
import ProtobufParser from "../modules/protobufParser";
import OrderService from "../services/OrderService";
import Notification from "../modules/notifications";
export function showDownloadTrades() {
  return {
    type: SHOW_DOWNLOAD_TRADES,
  };
}

export function hideDownloadTrades() {
  return {
    type: HIDE_DOWNLOAD_TRADES,
  };
}

export function reset() {
  return { type: LOGIN_RESET };
}

export function downloadTrades(action, startDate, endDate) {
  return () => {
    let onSuccess = (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", action + ".csv");
      document.body.appendChild(link);
      link.click();
      Notification.success("Downloaded " + action + " successfully.");
    };
    let onError = (error) => {
      console.error(error);
      Notification.error("Could not download report.");
    };

    OrderService.downloadReport(
      action,
      startDate ? ProtobufParser.toTimestamp(startDate) : null,
      endDate ? ProtobufParser.toTimestamp(endDate) : null,
      onSuccess,
      onError
    );
  };
}
