import { SET_VIEW_CONTEXT, SET_GRID_CONTEXT } from "../constants/actions";

export const SetViewContext = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_VIEW_CONTEXT,
      payload: payload,
    });
  };
};

export const SetGridContext = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_GRID_CONTEXT,
      payload: payload,
    });
  };
};
