import State from "./State";
import {capitalizeWord, getEnumName} from "../modules/util";
import {InstrumentState} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/api_pb";
import ProtobufParser from "../modules/protobufParser";

export default class BookStatusItem {
  constructor(item) {
    if (item) {
      let state = new State();
      state.id = item.getState();
      state.name = capitalizeWord(getEnumName(InstrumentState, state.id));
      this.state = state;
      this.symbol = item.getSymbol();
      this.isLive = state.id !== InstrumentState.INSTRUMENT_STATE_TERMINATED;
      this.bookHidden = item.getBookHidden();

      //Book stats
      this.open = item.hasStats() && item.getStats().hasOpenPx() ? item.getStats().getOpenPx() : null;
      this.close = item.hasStats() && item.getStats().hasClosePx() ? item.getStats().getClosePx() : null;
      this.high = item.hasStats() && item.getStats().hasHighPx() ? item.getStats().getHighPx() : null;
      this.low = item.hasStats() && item.getStats().hasLowPx() ? item.getStats().getLowPx() : null;
      this.last = item.hasStats() && item.getStats().hasLastTradePx() ? item.getStats().getLastTradePx() : null;
      this.settlementPx = item.hasStats() && item.getStats().hasSettlementPx() ? item.getStats().getSettlementPx() : null;

      this.transactTime = ProtobufParser.toDate(item.getTransactTime());
      return;
    }

    this.state = null;
    this.symbol = null;
    this.isLive = false;
    this.bookHidden = false;

    this.open = null;
    this.close = null;
    this.high = null;
    this.low = null;
    this.last = null;
    this.settlementPx = null;

    this.transactTime = null;
  }
}