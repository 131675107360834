import React from 'react';
import { Field, ErrorMessage, withFormik } from "formik";
import { TimeInForce, OrderType, Side, ConditionTriggerMethod } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import {
    validScaledPrice,
    getEnumName,
    capitalizeWord,
    parsePrice,
    convertScaledPriceToInt,
    isNullOrUndefined
} from "../../modules/util";
import i18n from 'i18next'
import AccountSelector from "../core/select/AccountSelector";
import PositionService from "../../services/PositionService";
import DateGroup from "../core/form/DateGroup"
import {Env} from "../../constants/environment";

const OrderTicketForm = props => {
    const {
        values,
        accounts,
        instruments,
        users,
        usersToAccounts,
        firms,
        firmsToUsers,
        errors,
        touched,
        doSubmit,
        placingOrder,
        placingCross,
        setFieldValue,
    } = props;

    const createTimeInForceOptions = () => {
        let items = [];
        for (const tifName in TimeInForce) {
            const tifValue = TimeInForce[tifName];
            if (tifValue !== TimeInForce.TIME_IN_FORCE_UNDEFINED) {
                const prettyTifName = capitalizeWord(getEnumName(TimeInForce, tifValue));
                items.push(<option key={tifValue} value={tifValue}>{prettyTifName}</option>)
            }
        }
        return items;
    };

    const createOrderTypeOptions = () => {
        let items = [];
        for (const typName in OrderType) {
            const typValue = OrderType[typName];
            if (typValue !== OrderType.ORDER_TYPE_UNDEFINED &&
                (!Env.getEnvBool("REACT_APP_HIDE_STOP_PX") || (typValue !== OrderType.ORDER_TYPE_STOP && typValue !== OrderType.ORDER_TYPE_STOP_LIMIT))
            ) {
                const prettyTypName = capitalizeWord(getEnumName(OrderType, typValue));
                items.push(<option key={typValue} value={typValue}>{prettyTypName}</option>);
            }
        }
        return items;
    };

    const createConditionTriggerMethodOptions = () => {
        let items = [];
        for (const triggerMethod in ConditionTriggerMethod) {
            const option = ConditionTriggerMethod[triggerMethod];
            if (option !== ConditionTriggerMethod.CONDITION_TRIGGER_METHOD_UNDEFINED) {
                const optionName = capitalizeWord(getEnumName(ConditionTriggerMethod, option));
                items.push(<option key={option} value={option}>{optionName}</option>)
            }
        }
        return items;
    };

    const isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    const isAmend = () => {
        return values.id;
    };

    const isReadOnly = () => {
        return values.readonly;
    };

    const inst = !!instruments && !!instruments[values.symbol] ? instruments[values.symbol] : null;
    const currency = !!inst ? inst.baseCurrency : null;
    const fxcurrency = !!inst && !!inst.hasForexAttributes ? inst.forex.baseCurrency : null;
    if (Env.getEnvBool("REACT_APP_SHOW_CURRENCY_BALANCES") && values.account !== values.currencyAccount && !!currency) {
        PositionService.getAccountBalance(values.account, currency, (_, response) => {
            setFieldValue('currencyAccount', values.account);
            setFieldValue('currencyBalance', !response ? "--" : response.getBuyingPower());
        });
        if (!!fxcurrency) {
            PositionService.getAccountBalance(values.account, fxcurrency, (_, response) => {
                setFieldValue('fxCurrencyBalance', !response ? "--" : response.getBuyingPower());
            });
        } else {
            PositionService.listAccountPositions(values.account, values.symbol, (_, response) => {
                let pos = "--";
                if(!!response) {
                    const posList = response.getPositionsList();
                    const availableList = response.getAvailablePositionList();
                    if (Array.isArray(posList) && Array.isArray(availableList) && posList.length === availableList.length) {
                        posList.forEach((posEntry, idx) => {
                            if (posEntry.getSymbol() === values.symbol && posEntry.getSymbolSubType() === values.subType) {
                                pos = parsePrice(availableList[idx], values.qtyScale);
                            }
                        })
                    }
                }
                setFieldValue('availablePosition', pos);
            });
        }
    }

    const readyToSubmit = !isEmpty(touched) || placingOrder instanceof Object;

    const attrSubType = (!!inst && !!Array.isArray(inst.eventAttributes))
        ? inst.eventAttributes.find((attr) => attr.symbol === values.subType)
        : null;
    const ordTyp = parseInt(values.orderType);
    const timeInForce = parseInt(values.timeInForce);
    const showCashQuantity = !values.id && ordTyp === OrderType.ORDER_TYPE_MARKET_TO_LIMIT;
    const showStopPrice = (ordTyp === OrderType.ORDER_TYPE_STOP || ordTyp === OrderType.ORDER_TYPE_STOP_LIMIT);
    const showTriggerMethod = (ordTyp === OrderType.ORDER_TYPE_STOP || ordTyp === OrderType.ORDER_TYPE_STOP_LIMIT);
    const showMinQty = timeInForce === TimeInForce.TIME_IN_FORCE_IMMEDIATE_OR_CANCEL;
    const showPrice = (ordTyp === OrderType.ORDER_TYPE_LIMIT || ordTyp === OrderType.ORDER_TYPE_STOP_LIMIT);
    const crossMustRest = !!inst && !isNullOrUndefined(inst.crossOrderRestingDuration) && !values.blockTradeIndicator && (inst.crossOrderRestingDuration.seconds > 0 || inst.crossOrderRestingDuration.nanos > 0);
    const showBuyButton = !!attrSubType ? attrSubType.buyScale !== 0 : true;
    const showSellButton = !!attrSubType ? attrSubType.sellScale !== 0 : !placingCross || crossMustRest;
    const showReadOnly = isReadOnly();

    return (
        <form onSubmit={(evt) => {
            doSubmit(values);
            evt.preventDefault();
        }}>
            {isAmend() && <div className='form-row'>
                <div className='form-group col'>
                    <label className='form-control-sm' htmlFor='orderID'>Order ID</label>
                    <Field id="orderID" name="id" className='form-control-plaintext' type='text' readOnly />
                </div>
                <div className='form-group col'>
                    <label className='form-control-sm' htmlFor='side'>Side</label>
                    <Field id="side"
                        name="side" className='form-control-plaintext' type='text'
                        value={i18n.t(getEnumName(Side, values.side).toLowerCase(), capitalizeWord(getEnumName(Side, values.side)))}
                        readOnly />
                </div>
            </div>
            }
            <div className='form-row'>
                <div className="form-group col">
                    <label className='form-control-sm' htmlFor='symbol'>Symbol</label>
                    <span id="symbol" name="symbol" className='form-control-plaintext' type='text' readOnly>{values.symbol}{!!values.subType ? " :: " + values.subType : ""}</span>
                </div>
                <div className="form-group col">
                    <label className='form-control-sm' htmlFor='account'>{!users || users.size <= 1 ? "Account" : placingCross ? "Buyer" : "On Behalf Of"}</label>
                    {isAmend() &&
                        <Field
                            id="account"
                            name="account"
                            className="form-control-plaintext"
                            value={!!accounts && accounts.has(values.account) ? accounts.get(values.account) : values.account}
                        />
                    }
                    {!isAmend() &&
                        <AccountSelector
                            id="account"
                            name="account"
                            users={users}
                            usersToAccounts={usersToAccounts}
                            firms={firms}
                            firmsToUsers={firmsToUsers}
                            accounts={accounts}
                            account={values.account}
                            user={values.user}
                            userAccountCb={(user, account) => {
                                setFieldValue('account', account);
                                setFieldValue('user', user);
                            }}
                        >
                        </AccountSelector>
                    }
                    {placingCross && <label className='form-control-sm' htmlFor='account'>Seller</label>}
                    {placingCross &&
                        <AccountSelector
                            id="account2"
                            name="account2"
                            users={users}
                            usersToAccounts={usersToAccounts}
                            firms={firms}
                            firmsToUsers={firmsToUsers}
                            accounts={accounts}
                            account={values.account2}
                            user={values.user2}
                            userAccountCb={(user, account) => {
                                setFieldValue('account2', account);
                                setFieldValue('user2', user);
                            }}
                        >
                        </AccountSelector>
                    }
                    {values.currencyBalance &&
                        <label className='form-control-sm' htmlFor='currencyBalance'>
                            Available: {values.currencyBalance} {currency}
                        </label>}
                    {values.fxCurrencyBalance &&
                        <label className='form-control-sm' htmlFor='fxCurrencyBalance'>
                            Available: {values.fxCurrencyBalance} {fxcurrency}
                        </label>}
                    {values.availablePosition &&
                        <label className='form-control-sm' htmlFor='availablePosition'>
                            Current Position: {values.availablePosition}
                        </label>}
                    <ErrorMessage name="account" component="div" className="form-input-error" />
                </div>
            </div>
            <div className='form-row'>
                <div className="form-group col">
                    <label className='form-control-sm' htmlFor='orderType'>Type</label>
                    {(isAmend() || Env.getEnvBool("REACT_APP_LOCK_ORDER_TYPE") || placingCross) &&
                        <Field
                            id="orderType"
                            name="orderType"
                            className="form-control-plaintext"
                            value={capitalizeWord(getEnumName(OrderType, values.orderType))}
                            readOnly
                            type='text'
                        />
                    }
                    {!isAmend() && !Env.getEnvBool("REACT_APP_LOCK_ORDER_TYPE") && !placingCross &&
                        <Field component="select"
                            id="orderType"
                            name="orderType"
                            placeholder="Order Type"
                            className="form-control"
                        >
                            {createOrderTypeOptions()}
                        </Field>
                    }
                </div>
                <div className="form-group col">
                    <label className='form-control-sm' htmlFor="timeInForce">TIF</label>
                    {(isReadOnly() || Env.getEnvBool("REACT_APP_LOCK_TIME_IN_FORCE")) &&
                        <Field
                            id="timeInForce"
                            name="timeInForce"
                            className="form-control-plaintext"
                            value={capitalizeWord(getEnumName(TimeInForce, values.timeInForce))}
                            readOnly
                            type='text'
                        />
                    }
                    {!isReadOnly() && !Env.getEnvBool("REACT_APP_LOCK_TIME_IN_FORCE") && <Field component="select"
                        id="timeInForce"
                        name="timeInForce"
                        placeholder="Time in Force"
                        className={
                            errors.timeInForce ?
                                `text-input error form-control` :
                                `text-input form-control`
                        }
                    >
                        {createTimeInForceOptions()}
                    </Field>}
                </div>
            </div>


            {(parseInt(values.timeInForce) === TimeInForce.TIME_IN_FORCE_GOOD_TILL_TIME) && <div className='form-row'>
                {/* <div className="form-group col"></div> */}
                <div className="form-group col">

                    {(isReadOnly() || Env.getEnvBool("REACT_APP_LOCK_TIME_IN_FORCE")) && <>
                        <label className='form-control-sm' htmlFor="timeInForce">Good Till Time</label>
                        <Field
                            id="goodTillTime"
                            name="goodTillTime"
                            className="form-control-plaintext"
                            value={capitalizeWord(getEnumName(TimeInForce, values.goodTillTime))}
                            readOnly
                            type='text'
                        /></>
                    }

                    {!isReadOnly() && !Env.getEnvBool("REACT_APP_LOCK_TIME_IN_FORCE") &&
                        <>
                            <DateGroup
                                id="goodTillTime"
                                name="goodTillTime"
                                label="Good Till Time"
                                onChange={(e) => {
                                    setFieldValue("goodTillTime", e);
                                }}
                                // isRequired={false}
                                value={!isNullOrUndefined(values.goodTillTime) ? values.goodTillTime : new Date()}
                                enableTime={true}
                                errors={errors}
                                errClassName={'form-input-error'}
                            />
                        </>}
                </div>
            </div>}

            <div className='form-row'>
                <div className="form-group col">
                    <label className='form-control-sm' htmlFor='quantity'>{i18n.t("quantity")}</label>
                    {isReadOnly() &&
                        <Field
                            id="quantity"
                            name="quantity"
                            className="form-control-plaintext"
                            value={values.quantity}
                        />
                    }
                    {!isReadOnly() &&
                        <Field
                            id="quantity"
                            name="quantity"
                            placeholder={i18n.t("quantity")}
                            type="text"
                            className={
                                errors.quantity ?
                                    `text-input error form-control` :
                                    `text-input form-control`
                            }
                        />
                    }
                    <ErrorMessage name="quantity" component="div" className="form-input-error" />
                </div>
                {showCashQuantity && <div className="form-group col">
                    <label className='form-control-sm' htmlFor='cashOrderQty'>{i18n.t("cashOrderQty")}</label>
                    {showReadOnly &&
                        <Field
                            id="cashOrderQty"
                            name="cashOrderQty"
                            className="form-control-plaintext"
                            disabled={!isReadOnly()}
                            value={values.cashOrderQty}
                        />
                    }
                    {!showReadOnly &&
                        <Field
                            id="cashOrderQty"
                            name="cashOrderQty"
                            placeholder={i18n.t("cashOrderQty")}
                            type="text"
                            className={
                                errors.cashOrderQty ?
                                    `text-input error form-control` :
                                    `text-input form-control`
                            }
                        />
                    }
                    <ErrorMessage name="cashOrderQty" component="div" className="form-input-error" />
                </div>}
            </div>
            <div className='form-row'>
                {showPrice && <div className="form-group col">
                    <label className='form-control-sm' htmlFor='price'>{i18n.t("price")}</label>
                    {showReadOnly &&
                        <Field
                            id="price"
                            name="price"
                            className="form-control-plaintext"
                            value={values.price}
                        />
                    }
                    {!showReadOnly &&
                        <Field
                            id="price"
                            name="price"
                            placeholder={i18n.t("price")}
                            type="text"
                            disabled={!values.id && parseInt(values.orderType) !== OrderType.ORDER_TYPE_LIMIT && parseInt(values.orderType) !== OrderType.ORDER_TYPE_STOP_LIMIT}
                            className={
                                errors.price ?
                                    `text-input error form-control` :
                                    `text-input form-control`
                            }
                        />
                    }
                    <ErrorMessage name="price" component="div" className="form-input-error" />
                </div>}

                {(Env.getEnvBool("REACT_APP_HIDE_STOP_PX") || placingCross) && <div className="form-group col"></div>}
                {(!Env.getEnvBool("REACT_APP_HIDE_STOP_PX") && !placingCross && showStopPrice) && <div className="form-group col">
                    <label className='form-control-sm' htmlFor='stopPrice'>StopPx</label>
                    {showReadOnly &&
                        <Field
                            id="stopPrice"
                            name="stopPrice"
                            className="form-control-plaintext"
                            disabled={!isReadOnly()}
                            value={values.stopPrice}
                        />
                    }
                    {!showReadOnly &&
                        <Field
                            id="stopPrice"
                            name="stopPrice"
                            placeholder="StopPx"
                            type="text"
                            className={
                                errors.stopPrice ?
                                    `text-input error form-control` :
                                    `text-input form-control`
                            }
                        />
                    }
                    <ErrorMessage name="stopPrice" component="div" className="form-input-error" />
                </div>}
            </div>

            <div className="form-row align-center">
                {!Env.getEnvBool("REACT_APP_HIDE_MIN_QTY") && showMinQty && <div className="form-group col">
                    <label className='form-control-sm' htmlFor='minQty'>Min Qty</label>
                    {showReadOnly &&
                        <Field
                            id="minQty"
                            name="minQty"
                            className="form-control-plaintext"
                            disabled={!isReadOnly()}
                            value={values.minQty}
                        />
                    }
                    {!showReadOnly &&
                        <Field
                            id="minQty"
                            name="minQty"
                            placeholder="Min Qty"
                            type="text"
                            className={
                                errors.minQty ?
                                    `text-input error form-control` :
                                    `text-input form-control`
                            }
                        />
                    }
                    <ErrorMessage name="minQty" component="div" className="form-input-error" />
                </div>}
                {Env.getEnvBool("REACT_APP_HIDE_MIN_QTY") && <div className="form-group col"></div>}

                {showTriggerMethod && <div className="form-group col">
                    <label className='form-control-sm' htmlFor="triggerMethod">Trigger Method</label>
                    {(isReadOnly() || isAmend()) &&
                        <Field
                            id="triggerMethod"
                            name="triggerMethod"
                            className="form-control-plaintext"
                            value={capitalizeWord(getEnumName(ConditionTriggerMethod, values.triggerMethod))}
                            readOnly
                            type='text'
                        />
                    }

                    {(!isReadOnly() && !isAmend()) && <Field component="select"
                        id="triggerMethod"
                        name="triggerMethod"
                        placeholder="Trigger Method"
                        className={errors.triggerMethod ? `text-input error form-control` : `text-input form-control`}
                    >
                        {createConditionTriggerMethodOptions()}
                    </Field>}
                </div>}

            </div>

            <div className='form-row'>
                {(!Env.getEnvBool("REACT_APP_HIDE_ALL_OR_NONE") && !placingCross) && <div style={{ paddingTop: "35px" }} className="form-group col">
                    <label className="form-control-sm form-check-label" htmlFor="allOrNone">All or None</label>
                    <Field type="checkbox" id="allOrNone" name="allOrNone" className="allOrNone-checkbox" disabled={isReadOnly() || Env.getEnvBool("REACT_APP_LOCK_ALL_OR_NONE")} checked={values.allOrNone} />
                </div>}
                {!!placingCross && <div className="form-group col">
                    <label className="form-control-sm form-check-label" htmlFor="blockTradeIndicator">Block Trade</label>
                    <Field type="checkbox" id="blockTradeIndicator" name="blockTradeIndicator" className="blockTradeIndicator-checkbox" disabled={isReadOnly()} checked={values.blockTradeIndicator} />
                </div>}
            </div>
            <div className='form-row'>
                {!!placingCross && !!values.blockTradeIndicator && <div className="form-group col">
                    <DateGroup
                        id="transactionBookedTime"
                        name="transactionBookedTime"
                        label="Transaction Booked Time"
                        onChange={(e) => {
                            setFieldValue("transactionBookedTime", e);
                        }}
                        value={!isNullOrUndefined(values.transactionBookedTime) ? values.transactionBookedTime : new Date()}
                        enableTime={true}
                        errors={errors}
                        errClassName={'form-input-error'}
                    />
                </div>}
            </div>
            {!isReadOnly() && Env.getEnvBool("REACT_APP_CANCEL_ON_DISCONNECT") &&
                <div className="form-row">
                    <div className="col">
                        <label className="form-control-sm form-check-label" htmlFor="cancelOnDisconnect">
                            Cxl on Disconnect
                        </label>
                        <Field type="checkbox" id="cancelOnDisconnect" name="cancelOnDisconnect"
                            className="cancelOnDisconnect-checkbox" disabled={isReadOnly()}
                            checked={values.cancelOnDisconnect} />
                    </div>
                </div>
            }

            {!isReadOnly() && <div className='form-row button-row'>

                {showBuyButton && <div className={!showSellButton ? "col text-center" : "col text-right"}>
                    <button className={'btn btn-side btn-buy'}
                        type="submit"
                        onClick={(_) => values.side = Side.SIDE_BUY} disabled={(!values.id && !readyToSubmit) || !isEmpty(errors)}>
                        {values.id ? "Amend" : placingCross && !crossMustRest ? "Submit" : i18n.t("buy").toUpperCase() + (placingCross ? " Prioritize" : "")}
                    </button>
                </div>}

                {showSellButton && <>
                    <div className="col-2" />
                    <div className="col">
                        <button className={isAmend() ? 'btn btn-primary btn-borderless' : "btn btn-side btn-sell"}
                            type="submit"
                            onClick={(_) => values.side = Side.SIDE_SELL} disabled={(!values.id && !readyToSubmit) || !isEmpty(errors)}>
                            {isAmend() ? "Cancel" : i18n.t("sell").toUpperCase() + (placingCross ? " Prioritize" : "")}
                        </button>
                    </div></>}

            </div>}
        </form>
    );
};

export function createUserMap(users, usersToAccounts, selectedAccount) {
    let userMap = new Map();
    if (!!users) {
        users.forEach((displayName, user) => {
            if (usersToAccounts.has(user) && usersToAccounts.get(user).has(selectedAccount)) {
                userMap.set(user, displayName);
            }
        });
    }
    return userMap;
}

export const EnhancedOrderTicketForm = withFormik({
    mapPropsToValues: (props) => {
        const selectedAccount = props.original ? props.original.account : !!props.accounts ? props.accounts.keys().next().value : "";
        const selectDefaultUser = !!selectedAccount && (!props.users || props.users.size <= 1);

        const values = {
            id: props.original ? props.original.id : '',
            side: props.original ? props.original.sideEnum : Side.SIDE_UNDEFINED,
            account: selectedAccount,
            account2: null,
            currencyAccount: "",
            currencyBalance: "",
            fxCurrencyBalance: "",
            availablePosition: "",
            user: selectDefaultUser ? createUserMap(props.users, props.usersToAccounts, selectedAccount).keys().next().value : "",
            user2: null,
            timeInForce: props.original ? props.original.timeInForce : TimeInForce.TIME_IN_FORCE_DAY,
            orderType: props.original ? props.original.type : OrderType.ORDER_TYPE_LIMIT,
            quantity: props.original ? parsePrice(props.original.quantity, props.qtyScale) : 'qty' in Object(props.placingOrder) ? parsePrice(props.placingOrder.qty, props.qtyScale) : '',
            minQty: props.original ? parsePrice(props.original.minQty, props.qtyScale) : '',
            price: props.original ? parsePrice(props.original.price, props.priceScale) : 'px' in Object(props.placingOrder) ? parsePrice(props.placingOrder.px, props.priceScale) : '',
            stopPrice: props.original ? parsePrice(props.original.stopPrice, props.priceScale) : '',
            priceScale: props.priceScale,
            qtyScale: props.qtyScale,
            instruments: props.instruments,
            readonly: !!props.readonly,
            symbol: props.symbol,
            subType: props.subType,
            minTradeQty: props.minTradeQty,
            allOrNone: props.original ? props.original.allOrNone : Env.getEnvBool("REACT_APP_ALL_OR_NONE_DEFAULT"),
            blockTradeIndicator: props.original ? props.original.blockTradeIndicator : false,
            transactionBookedTime: props.original ? props.original.transactionBookedTime : new Date(),
            cancelOnDisconnect: !props.original,
            placingCross: props.placingCross,
            goodTillTime: props.original ? props.original.goodTillTime : new Date(),
            triggerMethod: props.original ? props.original.triggerMethod : ConditionTriggerMethod.CONDITION_TRIGGER_METHOD_LAST_PRICE,
        }

        // Conditionally updating the values
        if (values.minQty === '0') values.minQty = '';

        if (values.orderType !== OrderType.ORDER_TYPE_STOP && values.orderType !== OrderType.ORDER_TYPE_STOP_LIMIT) {
            values.stopPrice = '';
        }

        return values;
    },
    validate: values => {
        let errors = {};

        if (!values.account) {
            errors.account = 'Account is required';
        }

        if (!values.id && !values.user) {
            errors.user = 'User is required';
        }

        if (values.placingCross && !values.account2) {
            errors.account2 = 'Account is required';
        }

        if (values.placingCross && !values.id && !values.user2) {
            errors.user2 = 'User is required';
        }

        if (values.placingCross && values.account === values.account2) {
            errors.account = 'Account must be different';
            errors.account2 = 'Account must be different';
        }

        if (!values.cashOrderQty && (!values.quantity || Number.isNaN(parseFloat(values.quantity)))) {
            errors.quantity = `${i18n.t("quantity")} is required`;
        } else if (!Number.isNaN(parseFloat(values.quantity)) && !Number.isNaN(parseFloat(values.cashOrderQty))) {
            errors.quantity = `Only one of ${i18n.t("quantity")} or ${i18n.t("cashOrderQty")} must be set`;
        } else if (parseFloat(values.quantity) <= 0) {
            errors.quantity = `${i18n.t("quantity")} must be > 0`;
        } else if (values.quantity && !validScaledPrice(values.quantity, values.qtyScale)) {
            errors.quantity = `${i18n.t("quantity")} has too many decimal places`;
        } else if (values.quantity && convertScaledPriceToInt(values.quantity, values.qtyScale) % values.minTradeQty > 0) {
            errors.quantity = `${i18n.t("quantity")} must be multiple of ${parsePrice(values.minTradeQty, values.qtyScale)}`;
        }

        if (values.cashOrderQty) {
            if (Number.isNaN(parseFloat(values.cashOrderQty))) {
                errors.cashOrderQty = `${i18n.t("cashOrderQty")} is invalid`;
            } else if (parseFloat(values.cashOrderQty) <= 0) {
                errors.cashOrderQty = `${i18n.t("cashOrderQty")} must be > 0`;
            } else {
                let cashScale = values.priceScale;

                if (values.qtyScale > 0) {
                    cashScale *= values.qtyScale;
                }

                if (!Number.isNaN(parseFloat(values.cashOrderQty)) && !validScaledPrice(values.cashOrderQty, cashScale)) {
                    errors.cashOrderQty = `${i18n.t("cashOrderQty")} has too many decimal places`;
                }
            }
        }

        if (values.minQty) {
            if (parseFloat(values.minQty) < 0) {
                errors.minQty = 'Min Qty must be >= 0';
            } else if (!validScaledPrice(values.minQty, values.qtyScale)) {
                errors.minQty = 'Min Qty has too many decimal places';
            }
        }

        if (parseInt(values.orderType) === OrderType.ORDER_TYPE_LIMIT || parseInt(values.orderType) === OrderType.ORDER_TYPE_STOP_LIMIT || (!!values.id && parseInt(values.orderType) === OrderType.ORDER_TYPE_MARKET_TO_LIMIT)) {
            if (!values.price) {
                errors.price = `${i18n.t("price")} must be provided for this order type`;
            } else if (!validScaledPrice(values.price, values.priceScale)) {
                errors.price = `${i18n.t("price")} scale is invalid`;
            }
        }

        if (parseInt(values.orderType) === OrderType.ORDER_TYPE_STOP || parseInt(values.orderType) === OrderType.ORDER_TYPE_STOP_LIMIT) {
            if (!values.stopPrice) {
                errors.stopPrice = 'StopPx must be provided for this order type';
            } else if (!validScaledPrice(values.stopPrice, values.priceScale)) {
                errors.stopPrice = 'StopPx scale is invalid';
            }
        }

        if (parseInt(values.timeInForce) === TimeInForce.TIME_IN_FORCE_GOOD_TILL_TIME) {
            if (values.goodTillTime <= new Date()) {
                errors.goodTillTime = "Good till time must be greater than now."
            }
        }

        return errors;
    },
    displayName: 'OrderTicketForm' // helps with React DevTools
})(OrderTicketForm);
