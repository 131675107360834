import {
  SET_SYMBOL,
  UPDATE_BOOK,
  ADD_INSTRUMENT,
  UPDATE_INSTRUMENT,
  VIEW_POSITION,
  HIDE_POSITION,
  ADD_INSTRUMENTS,
  VIEW_EXECUTION,
  HIDE_EXECUTION,
  VIEW_ORDER_EXECUTION,
  HIDE_ORDER_EXECUTION,
} from "../constants/actions";

export function setSymbol(symbol, subType, scale) {
  return {
    type: SET_SYMBOL,
    symbol: symbol,
    subType: subType,
    scale: scale,
  };
}

export function updateBook(book, bookHidden) {
  return {
    type: UPDATE_BOOK,
    book: book,
    bookHidden: !!bookHidden,
  };
}

export function addInstrument(symbol, scale, qtyScale, multiplier, baseCurrency) {
  return {
    type: ADD_INSTRUMENT,
    instrument: {
      symbol: symbol,
      scale: scale,
      qtyScale: qtyScale,
      multiplier: multiplier,
      baseCurrency: baseCurrency,
    },
  };
}
export function addInstruments(instruments) {
  return {
    type: ADD_INSTRUMENTS,
    instruments,
  };
}

export function updateInstrument(symbol, book, status) {
  return {
    type: UPDATE_INSTRUMENT,
    symbol,
    stats: {
      book,
      ...status,
    },
  };
}

export function viewPosition(symbol, account) {
  return {
    type: VIEW_POSITION,
    symbol,
    account,
  };
}

export function hidePosition() {
  return {
    type: HIDE_POSITION,
  };
}

export function viewExecution(id, account) {
  return {
    type: VIEW_EXECUTION,
    id,
    account,
  };
}

export function hideExecution() {
  return {
    type: HIDE_EXECUTION,
  };
}

export function viewOrderExecution(id, execution) {
  return {
    type: VIEW_ORDER_EXECUTION,
    id,
    execution,
  };
}

export function hideOrderExecution() {
  return {
    type: HIDE_ORDER_EXECUTION,
  };
}