import React from 'react';
import { connect } from 'react-redux';
import DataGrid from '../core/data-grid/DataGrid';
import PropTypes from 'prop-types';
import DataGridActions from '../core/data-grid/DataGridActions';
import { viewPosition } from '../../actions/trader';
import i18n from "i18next";
import { instrumentTitle } from "./Instruments";

const Positions = ( { positions, accounts, onView, isPositionAuthorized, positionMessage } ) => {
    const getTdProps = (state, rowInfo, column) => {
        let className;
        if (column.Header === 'NET P&L') {
            if (rowInfo.row.netPnl > 0) {
                className = 'profit'
            } else if (rowInfo.row.netPnl < 0) {
                className = 'loss'
            }
        }

        return {
            className: className
        }
    }

    const columns = [
        {
            Header: i18n.t('symbol').toUpperCase(),
            id: 'symbol',
            accessor: d => instrumentTitle(d.symbol),
        },
        {
            Header: 'SUB TYPE',
            id: 'subType',
            accessor: d => d.symbolSubType,
            maxWidth: 80
        },
        {
            Header: 'ACCOUNT',
            id: 'account',
            accessor: d => !!accounts && accounts.has(d.account) ? accounts.get(d.account) : d.account,
        },
        {
            Header: i18n.t('net_position').toUpperCase(),
            id: 'netPosition',
            accessor: d => !!d.qtyDecimals ? (d.netPosition / d.qtyScale).toFixed(d.qtyDecimals) : d.netPosition,
        },
        {
            Header: i18n.t('net_pnl').toUpperCase(),
            id: 'prettyNetPnl',
            accessor: d => d.netPnl === undefined ? '-' : d.netPnl.toFixed(d.decimals) + ' ' + d.baseCurrency,
        },
        {
            Header: 'AVERAGE PRICE',
            id: 'averagePrice',
            accessor: d => d.scaledAvgPrice === undefined ? '-' : d.scaledAvgPrice.toFixed(d.decimals) + ' ' + d.baseCurrency,
        },
        {
            id: 'netPnl',
            accessor: d => d.netPnl,
            show: false,
        },
        {
            sortable: false,
            Cell: row => {
                return (
                    <DataGridActions
                        onView={() => onView(row.original.symbol, row.original.account)}
                    />
                )
            },
            maxWidth: 50,
            className: 'action-column',
        },
    ];
    return ((isPositionAuthorized && <div className="Positions">
        <DataGrid
            data={positions}
            columns={columns}
            getTdProps={getTdProps}
            filterable={true}
            defaultSortedField="account"
            showPagination={false}
        ></DataGrid>
    </div>) || (!isPositionAuthorized && <div className="Positions">
        <span>{positionMessage}</span>
    </div>))
};

Positions.propTypes = {
    isPositionAuthorized: PropTypes.bool,
    positionMessage: PropTypes.string,
    positions: PropTypes.array.isRequired,
    onView: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { trader } = state
    const { positions, accounts } = trader

    return {
        positions,
        accounts,
        isPositionAuthorized: state.auth.isPositionAuthorized,
        positionMessage: state.auth.positionMessage,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onView: (symbol, account) => {
        dispatch(viewPosition(symbol, account))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Positions);