import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import Menu from "../Menu";
import "./index.css";

const queryString = require('query-string');

class AdminWrapper extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="App">
          {!isPopup() && <Menu />}
          {!isPopup() && <ToastContainer hideProgressBar={true} />}
          <div className="page">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export function isPopup() {
  const query = queryString.parse(window.location.search);
  return !!query && !!query.popup && query.popup.toLowerCase() === 'true';
}

export default connect(mapStateToProps, null)(AdminWrapper);
