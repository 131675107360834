import { apiCall } from "../modules/apiCall";
import {apiUrl} from "../modules/util";

const {
  CreatePositionSubscriptionRequest,
  GetAccountBalanceRequest,
  ListAccountPositionsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/position_api_pb");
const {
  PositionAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/position_api_grpc_web_pb");

const client = new PositionAPIClient(apiUrl(), null, null);

export default class PositionService {
  static subscribe(onData, onStatus, onError, onEnd) {
    const request = new CreatePositionSubscriptionRequest();
    return apiCall(client, "createPositionSubscription", request, null, {data: onData, status: onStatus, end: onEnd, error: onError});
  }

  static getAccountBalance(account, currency, cb) {
    var request = new GetAccountBalanceRequest();
    request.setName(account);
    request.setCurrency(currency);
    apiCall(client, "getAccountBalance", request, cb);
  }

  static listAccountPositions(account, symbol, cb) {
    var request = new ListAccountPositionsRequest();
    request.setName(account);
    request.setSymbol(symbol)
    apiCall(client, "listAccountPositions", request, cb);
  }
}
