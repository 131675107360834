import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import { login, parseJwt, checkPassword } from "../../actions/auth";

import "./index.css";
import "./index.css";
import _ from "lodash";
import { Env } from "../../constants/environment";

const getRegister = (props) => {
  const getParams = _.chain(props.history.location.search)
    .replace("?", "")
    .split("&")
    .map(_.partial(_.split, _, "=", 2))
    .fromPairs()
    .value();
  return getParams.register
    ? getParams.register.toString().toLowerCase() === "true"
    : false;
};

const LoginForm = (props) => {
  const register = getRegister(props);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    auth,
  } = props;

  return (
    <form onSubmit={handleSubmit} className='login-form form'>
      {register &&
        <div className={`form-group formGroup`}>
          <label htmlFor="token">Token</label>
          <input
            id="token"
            placeholder="Enter your token"
            type="text"
            value={values.token}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.token && touched.token
              ? `text-input error form-control formControl`
              : `text-input form-control formControl`}
          />
          {errors.token && touched.username &&
            <label className="form-input-error username-error">
              <i
                className="fa fa-fw fa-warning-sign orange-icon"
                style={{ fontSize: "1.75em" }}
              />
              {errors.token}
            </label>}
        </div>}
      <div className={`form-group formGroup`}>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          placeholder="Enter your username"
          type="text"
          disabled={register}
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.username && touched.username
            ? `text-input error form-control formControl`
            : `text-input form-control formControl`}
        />
        {errors.username && touched.username &&
          <label className="form-input-error username-error">
            <i
              className="fa fa-fw fa-warning-sign orange-icon"
              style={{ fontSize: "1.75em" }}
            />
            {errors.username}
          </label>}
      </div>
      <div className={`form-group formGroup`}>
        <label htmlFor="password">
          Password
        </label>
        <input
          id="password"
          placeholder="Enter your password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.password && touched.password
            ? `text-input error form-control formControl`
            : `text-input form-control formControl`}
        />
        {errors.password && touched.password &&
          <label className="form-input-error password-error">
            <i
              className="fa fa-fw fa-warning-sign orange-icon"
              style={{ fontSize: "1.75em" }}
            />
            {errors.password}
          </label>}
        {auth.error &&
          <label className="form-input-error auth-error">
            <i
              className="fa fa-fw fa-warning-sign orange-icon"
              style={{ fontSize: "1.75em" }}
            />
            {auth.error}
          </label>}
      </div>
      {register && <div className={`form-group formGroup`}>
        <label htmlFor="matchpassword">
          Confirm Password
        </label>
        <input
          id="matchpassword"
          placeholder="Confirm your password"
          type="password"
          value={values.matchpassword}
          onChange={handleChange}
          onBlur={handleBlur}
          className={errors.matchpassword && touched.matchpassword
            ? `text-input error form-control formControl`
            : `text-input form-control formControl`}
        />
        {errors.matchpassword &&
          <label className="form-input-error password-error">
            <i
              className="fa fa-fw fa-warning-sign orange-icon"
              style={{ fontSize: "1.75em" }}
            />
            {errors.matchpassword}
          </label>}
        {auth.error &&
          <label className="form-input-error auth-error">
            <i
              className="fa fa-fw fa-warning-sign orange-icon"
              style={{ fontSize: "1.75em" }}
            />
            {auth.error}
          </label>}
      </div>}
      <div className={`form-group formGroup`}>
        <button
          className={`${register ? "btn-secondary" : "btn-main"} btnSubmit`}
          type="submit"
          disabled={isSubmitting}
          style={{ "marginLeft": "0px" }}
        >
          {register ? "Register" : "Login"}
        </button>
      </div>
      {!!Env.getEnv("REACT_APP_AUTH_URL") && <div className={`form-group formGroup`}>
        <button
          className={`btn-secondary btnSubmit`}
          onClick={() => {
            window.location.href = Env.getEnv("REACT_APP_AUTH_URL");
          }}
          disabled={isSubmitting}
          style={{ "marginLeft": "0px" }}
        >
          Single Sign On
        </button>
      </div>}
      {!register &&
        <a href="/?register=true">
          Need to register? Click here
        </a>}
    </form>
  );
};

export const EnhancedLoginForm = withFormik({
  mapPropsToValues: () => ({ username: "", password: "", suggestion: "" }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required("Username is required!"),
    password: Yup.string().required("Password is required!"),
  }),
  validate: (values, props) => {
    let errors = {};
    if (getRegister(props)) {
      if (!values.token) {
        errors.token = "Token is required!";
        values.username = "";
      } else {
        const parsed = parseJwt(values.token, true);
        if (parsed.sub) {
          const splitted = parsed.sub.split("/");
          values.username = splitted[splitted.length - 1];
        } else {
          values.username = "";
        }
      }
    }
    if (getRegister(props) && values.password !== values.matchpassword) {
      errors.matchpassword = "Password must match.";
      values.suggestion = "";
    } else if (!!values.suggestion) {
      errors.matchpassword = values.suggestion;
    }

    if (getRegister(props) && _.isEmpty(errors)) {
      props.checkPassword(values.password, (err, res) => {
        if (res && !res.getAcceptable()) {
          values.suggestion = Number(res.getStrength() / res.getMinimumStrength()).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}) + " " + res.getSuggestion();
        }
      })
    }
    return errors;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(false);
    props.login({
      user: values,
      registering: getRegister(props),
    });
  },
  displayName: "LoginForm", // helps with React DevTools
})(LoginForm);

class Login extends Component {
  componentDidMount() {
    document.title = "Exchange - Login";
    document.body.classList.add('loginBackground');
  }

  componentWillUnmount() {
    document.body.classList.remove('loginBackground');
  }

  render() {
    const { login, checkPassword, auth } = this.props;
    const Form = withRouter(({ history }) => (
      <EnhancedLoginForm login={login} checkPassword={checkPassword} history={history} auth={auth} />
    ));
    return (
      <div className="wrapper">
        <div style={{ textAlign: "center", paddingTop: "20px" }} ><img alt="" src={Env.getLogoLoginPage()} style={{ height: "110px" }} /></div>

        <h1 className="welcomeBack">Welcome Back</h1>
        <Form />
        <br/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { login, checkPassword },
)(Login);
