import { Date as ApiDate } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/type/v1beta1/type_pb";

const {
  Timestamp,
} = require("google-protobuf/google/protobuf/timestamp_pb.js");
const {
  Duration
} = require('google-protobuf/google/protobuf/duration_pb.js');

export default class ProtobufParser {
  //If you look at "google-protobuf/google/protobuf/timestamp_pb.js"
  // there is a method "fromDate()"". For some reason, that method doesn't work
  // and here is a custom method to replace it.
  static toTimestamp(date) {
    var newTimestamp = new Timestamp();
    newTimestamp.setSeconds(Math.floor(date.getTime() / 1000));
    newTimestamp.setNanos(date.getMilliseconds() * 1000000);

    return newTimestamp;
  }

  static dateToProtoDate(d) {
    let pDate = new ApiDate();
    pDate.setYear(d.getFullYear());
    pDate.setMonth(d.getMonth()+1);
    pDate.setDay(d.getDate());
    return pDate
  }

  // Returns a JavaScript 'Date' object corresponding to the Timestamp.
  static toDate(protoTimestamp) {
    var seconds = protoTimestamp.getSeconds();
    var nanos = protoTimestamp.getNanos();

    return new Date(seconds * 1000 + nanos / 1000000);
  }

  /**
   * Returns a JavaScript 'Date' object corresponding to this Timestamp.
   * @param {!proto.api.Timestamp} value The timestamp to convert
   * @return {!Date}
   */
  static tsProtoToDate(value) {
    var seconds = value.getSeconds();
    var nanos = value.getNanos();

    return new Date(seconds * 1000 + nanos / 1000000);
  }

  /**
   * Returns a JavaScript 'Date' object corresponding to this Date.
   * @param {!admin.Date} value The admin.Date proto to convert
   * @return {!Date}
   */
  static protoDateToDate(value) {
    if (typeof value.toDate !== 'undefined') {
      return value.toDate();
    }
    return new Date(value.getYear(), value.getMonth() - 1, value.getDay());
  }

  static objectDateToDate({ year, month, day }) {
    return new Date(year, month - 1, day);
  }

  static toDuration(value) {
    var newDuration = new Duration();
    newDuration.setSeconds(value && value.seconds ? value.seconds : 0);
    newDuration.setNanos(value && value.nanos ? value.nanos : 0);
    return newDuration;
  }

  static fromDuration(value) {
    return {
      seconds: value ? value.getSeconds() : 0,
      nanos: value ? value.getNanos() : 0,
    }
  }

  static formatDuration(value) {
    const obj = value && typeof value.toObject === 'function' ? value.toObject() : value;
    const str = String(obj && obj.seconds ? obj.seconds : 0).padStart(1, "0")
            + "."
            + String(obj && obj.nanos ? obj.nanos : 0).padStart(9, "0");
    return str.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1') + "s";
  }
}
