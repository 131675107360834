import React from "react";
import Dialog from "./core/dialog/Dialog";
import { hideWatchList, updateWatchList } from "../actions/watchlist";
import { ListInstrumentsForSymbols } from "../actions/instruments";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { Container } from "react-bootstrap";
import WatchlistInstrumentSelector from "./core/select/WatchlistInstrumentSelector";
import Loader from "./core/loader/Loader";
import { InstrumentState } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/api_pb";

const WatchList = ({
  setFieldValue,
  values,
  onClose,
  loadingInstruments,
  metadata,
  traderInstruments,
  onSubmit,
  doSubmit,
  onWatchListPageSizeChange,
  watchListData,
  searchInstrumentFromSymbol,
  currentPageSize
}) => {
  let userSelectedInstruments = null;
  const getInstruments = () => {
    let _instruments = { ...watchListData };
    for (const symbol in traderInstruments) {
      if (traderInstruments[symbol].state &&
        (traderInstruments[symbol].state.id === InstrumentState.INSTRUMENT_STATE_EXPIRED ||
          traderInstruments[symbol].state.id === InstrumentState.INSTRUMENT_STATE_TERMINATED)
      ) {
        if (_instruments[symbol]) {
          delete _instruments[symbol];
        }
      }
    }
    return { ..._instruments };
  }
  return (
    <Dialog
      title={"Instrument Watchlist"}
      show={true}
      onClose={onClose}
      dialogClassName="watchListDialog"
    >
      <div>
        <Container>
          <Loader show={loadingInstruments} />
          <WatchlistInstrumentSelector
            onWatchListPageSizeChange={onWatchListPageSizeChange}
            instruments={getInstruments()}
            traderInstruments={traderInstruments}
            selectedInstruments={values.selectedInstruments}
            onInstrumentSelect={(selectedInstruments) => { userSelectedInstruments = selectedInstruments }}
            searchInstrumentFromSymbol={searchInstrumentFromSymbol}
            pageSize={currentPageSize}
          />
        </Container>
        <div className="form-row">
          <div className="col-12 text-right">
            <button
              className={"btn btn-primary btn-side btn-buy"}
              onClick={() => {
                doSubmit(!userSelectedInstruments ? [] : userSelectedInstruments)
                if (onSubmit) {
                  onSubmit([userSelectedInstruments]);
                }
                onClose();
              }}
            >
              Confirm
            </button>
            <span>&nbsp;</span>
            <button
              className={"btn btn-primary btn-side btn-sell"}
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const withFormikWatchList = withFormik({
  mapPropsToValues: (props) => {
    let selectedInstruments = props.watchList.instruments;
    if (!selectedInstruments || !selectedInstruments.length) {
      selectedInstruments = [];
    }
    return {
      selectedInstruments: selectedInstruments,
      filteredInstruments: props.instruments && Object.keys(props.instruments),
    }
  },
  displayName: "WatchListForm",
})(WatchList);

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(hideWatchList());
  },
  doSubmit: (symbols) => {
    dispatch(updateWatchList(symbols));
  },
  searchInstrumentFromSymbol: (symbols) => {
    dispatch(ListInstrumentsForSymbols(symbols))
  }
});

const mapStateToProps = (state) => {
  const { watchList, quotes, trader } = state;
  const traderInstruments = trader.instruments;
  let { loadingInstruments, metadata } = quotes;
  let { watchListData, currentPageSize } = watchList

  return {
    watchList,
    loadingInstruments,
    metadata,
    traderInstruments,
    watchListData,
    currentPageSize
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFormikWatchList);
