// auth actions
export const LOGIN_START = "LOGIN_START";
export const LOGIN_END = "LOGIN_END";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_RESET = "LOGIN_RESET";
export const ACCESS_TKN_REFRESH_SUCCESS = "ACCESS_TKN_REFRESH_SUCCESS";
export const SET_AUTHORIZATION = "SET_AUTHORIZATION";


export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"

// set symbol context
export const SET_SYMBOL = "SET_SYMBOL";

// update the book for the symbol context
export const UPDATE_BOOK = "UPDATE_BOOK";

// add an instrument to the system
export const ADD_INSTRUMENT = "ADD_INSTRUMENT";
// add instruments to the system
export const ADD_INSTRUMENTS = "ADD_INSTRUMENTS";
// report metadata
export const FETCH_METADATA_START = 'FETCH_METADATA_START';
export const FETCH_METADATA_SUCCEED = 'FETCH_METADATA_SUCCEED';
export const FETCH_METADATA_FAILED = 'FETCH_METADATA_FAILED';

// update instrument states, top of book for instrument
export const UPDATE_INSTRUMENT = "UPDATE_INSTRUMENT";

// update positions
export const UPDATE_POSITIONS = "UPDATE_POSITIONS";

// update position updates a single position
export const UPDATE_POSITION = "UPDATE_POSITION";

// view position detail modal
export const VIEW_POSITION = "VIEW_POSITION";

// close position detail modal
export const HIDE_POSITION = "HIDE_POSITION";

// update completed orders
export const UPDATE_COMPLETED_ORDERS = "UPDATE_COMPLETED_ORDERS";

// update executions
export const UPDATE_EXECUTIONS = "UPDATE_EXECUTIONS";

// update working orders
export const UPDATE_WORKING_ORDERS = "UPDATE_WORKING_ORDERS";

export const SET_LOADING_INSTRUMENTS = "SET_LOADING_INSTRUMENTS";
export const ADD_QUOTE_INSTRUMENTS = "ADD_QUOTE_INSTRUMENTS";
export const UPDATE_RELATED_INSTRUMENTS = "UPDATE_RELATED_INSTRUMENTS";
export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_DEALERS = "UPDATE_DEALERS";
export const UPDATE_REQUESTERS = "UPDATE_REQUESTERS";
export const UPDATE_TRADER = "UPDATE_TRADER";
export const UPDATE_QUOTE_SUBSCRIPTION = "UPDATE_QUOTE_SUBSCRIPTION";
export const SET_VIEW_CONTEXT = "SET_VIEW_CONTEXT";
export const SET_GRID_CONTEXT = "SET_GRID_CONTEXT";
export const VIEW_EXECUTION = "VIEW_EXECUTION";
export const HIDE_EXECUTION = "HIDE_EXECUTION";
export const SHOW_WATCHLIST = "SHOW_WATCHLIST";
export const HIDE_WATCHLIST = "HIDE_WATCHLIST";
export const UPDATE_WATCHLIST = "UPDATE_WATCHLIST";
export const UPDATE_SESSION_ID = "UPDATE_SESSION_ID";
export const SHOW_DOWNLOAD_TRADES = "SHOW_DOWNLOAD_TRADES";
export const HIDE_DOWNLOAD_TRADES = "HIDE_DOWNLOAD_TRADES";
export const DOWNLOAD_TRADES = "DOWNLOAD_TRADES";

// notifications
export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION";
export const MOVE_NOTIFICATION = "MOVE_NOTIFICATION";
export const CLEAR_ALL_NOTIFICATION = "CLEAR_ALL_NOTIFICATION"
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS"
export const HIDE_NOTIFICATIONS = "HIDE_NOTIFICATIONS"

export const VIEW_ORDER_EXECUTION = "VIEW_ORDER_EXECUTION";
export const HIDE_ORDER_EXECUTION = "HIDE_ORDER_EXECUTION";

//symbols
export const SET_LOADING_SYMBOLS = 'SET_LOADING_SYMBOLS'
export const ADD_SYMBOLS = 'ADD_SYMBOLS'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const ADD_WATCHLIST_INSTRUMENTS = 'ADD_WATCHLIST_INSTRUMENTS'
export const SET_WATCHLIST_INSTRUMENTS_LOADING = 'SET_WATCHLIST_INSTRUMENTS_LOADING'
export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS"
