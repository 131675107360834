import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "../core/dialog/Dialog";
import { SetViewContext } from "../../actions/app";
import AccordionMain from "../core/accordion/Accordion";
import { ListGroup, Card } from "react-bootstrap";
import "./styles.css";
import moment from "moment";
import TradingHours from "../core/tradingHours/TradingHours";
import { getEnumName, validatePrice, parsePrice } from "../../modules/util";
import {
  InterestRateSwapAttributesBusinessDayConvention,
  InterestRateSwapAttributesFrequency,
  InterestRateSwapAttributesDayCountConvention,
  InterestRateSwapAttributesDateRelativeTo,
  InterestRateSwapAttributesCompoundingMethod,
  InterestRateSwapAttributesSideConvention,
  InterestRateSwapAttributesDayType,
  InterestRateSwapAttributesTimePeriod,
} from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/swaps_pb";
import { PutOrCall } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/instruments/v1beta1/derivatives_pb";
import i18n from "../../i18n";
import ProtobufParser from "../../modules/protobufParser";

const mapStateToProps = (state) => {
  return {
    symbol: state.quotes.appContext.symbol,
    viewSwapDetail: state.quotes.appContext.viewSwapDetail,
    instruments: state.quotes.instruments,
    metadata: state.quotes.metadata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setViewContext: (payload) => {
    dispatch(SetViewContext(payload));
  },
});

const arrayToString = (data) => {
  if (!data) return "";
  if (Array.isArray(data)) return data.join(", ");
  return data.toString();
};

function getFloatLegDetails(inst) {
  if (inst.hasIrsAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.irsa.floatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.irsa.floatCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.irsa.floatDayCountConvention
        ),
      ],
      ["Roll Conventions", inst.irsa.floatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.irsa.floatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.irsa.floatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.irsa.floatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.irsa.floatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.irsa.floatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.irsa.floatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.irsa.floatRateResetFrequency
        ),
      ],
      ["Holiday Calendar", arrayToString(inst.irsa.floatHolidayCalendarsList)],
    ];
  } else if (inst.hasFraAttributes) {
    return [
      ["Floating Rate Index", inst.irsa.floatingRateIndex],
      [
        "Float Fixing Date Holiday Calendar",
        arrayToString(inst.ifra.floatFixingDateHolidayCalendarsList),
      ],
      [
        "Fixing Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ifra.floatFixingDateBusinessDayConvention
        ),
      ],
      [
        "Fixing Date",
        moment(inst.ifra.floatFixingDate).local().format("MMMM Do YYYY"),
      ],
      [
        "Fixing Dates Day Type",
        getEnumName(
          InterestRateSwapAttributesDayType,
          inst.ifra.floatFixingDatesDayType
        ),
      ],
      ["Float Fixing Dates Offset", inst.ifra.floatFixingDatesOffset],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.ifra.floatDayCountConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ifra.floatBusinessDayConvention
        ),
      ],
      ["Floating Rate Index Tenor", inst.ifra.floatingRateIndexTenor],
    ];
  } else if (inst.hasForwardStartingInterestRateSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.fsIrs.floatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.fsIrs.floatCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.fsIrs.floatDayCountConvention
        ),
      ],
      ["Roll Conventions", inst.irsa.floatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.fsIrs.floatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.fsIrs.floatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.irsa.floatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.fsIrs.floatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.fsIrs.floatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.fsIrs.floatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.fsIrs.floatRateResetFrequency
        ),
      ],
      ["Holiday Calendar", arrayToString(inst.fsIrs.floatHolidayCalendarsList)],
    ];
  } else if (inst.hasOvernightIndexSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.ois.floatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.ois.floatCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.ois.floatDayCountConvention
        ),
      ],
      ["Roll Conventions", inst.irsa.floatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ois.floatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ois.floatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.irsa.floatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.ois.floatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.ois.floatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.ois.floatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.ois.floatRateResetFrequency
        ),
      ],
      ["Holiday Calendar", arrayToString(inst.ois.floatHolidayCalendarsList)],
    ];
   } else if (inst.hasSinglePeriodSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.sps.floatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.sps.floatCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.sps.floatDayCountConvention
        ),
      ],
      ["Roll Conventions", inst.irsa.floatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.sps.floatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.sps.floatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.sps.floatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.sps.floatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.sps.floatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.sps.floatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.sps.floatRateResetFrequency
        ),
      ],
      ["Holiday Calendar", arrayToString(inst.sps.floatHolidayCalendarsList)],
    ];
  } else if (inst.hasZeroCouponInflationSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.zeroCouponInflationSwap.floatPaymentFrequency
        ),
      ],
      ["Roll Conventions", inst.zeroCouponInflationSwap.floatRollConvention],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.zeroCouponInflationSwap.floatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.zeroCouponInflationSwap.floatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.zeroCouponInflationSwap.floatPaymentDateRelativeTo
        ),
      ],
    ];
  }

  return [];
}

function getCommonDetails(inst, metadata) {
  const restingDuration = ProtobufParser.formatDuration(
    inst.crossOrderRestingDuration
  );
  return [
    ["Base Currency", inst.baseCurrency],
    ["Minimum Price Increment", inst.tickSize],
    [
      "Trading Hours",
      inst.tradingScheduleList.length > 0 ? (
        <TradingHours
          scheduleList={inst.tradingScheduleList}
          metadata={metadata}
        />
      ) : null,
    ],
    ["Minimum Trade Quantity", inst.minimumTradeQty],
    [
      "Cross Order Resting Duration",
      restingDuration && restingDuration !== "0s" ? restingDuration : null,
    ],
    [
      "Low Price Limit",
      inst.priceLimit &&
      inst.priceLimit.lowSet &&
      validatePrice(inst.priceLimit.low)
        ? parsePrice(inst.priceLimit.low, inst.scale)
        : null,
    ],
    [
      "High Price Limit",
      inst.priceLimit &&
      inst.priceLimit.highSet &&
      validatePrice(inst.priceLimit.high)
        ? parsePrice(inst.priceLimit.high, inst.scale)
        : null,
    ],
    [
      "Low Order Size Limit",
      inst.orderSizeLimit &&
      inst.orderSizeLimit.lowSet &&
      validatePrice(inst.orderSizeLimit.low)
        ? parsePrice(inst.orderSizeLimit.low, inst.scale)
        : null,
    ],
    [
      "High Order Size Limit",
      inst.orderSizeLimit &&
      inst.orderSizeLimit.highSet &&
      validatePrice(inst.orderSizeLimit.high)
        ? parsePrice(inst.orderSizeLimit.high, inst.scale)
        : null,
    ],
  ];
}

function getSwapDetails(inst) {
  let tenor = null;
  let tenorTimePeriod = null;
  let maturityDate = null;
  let sideConvention = null;
  let effectiveDate = null;
  let rollAndPayHolidays = null;
  let forwardStartingPeriod = null;
  let forwardStartingPeriodTimePeriod = null;

  if (inst.hasIrsAttributes) {
    tenor = inst.irsa.tenor;
    maturityDate = inst.irsa.maturityDate;
    sideConvention = inst.irsa.sideConvention;
    effectiveDate = inst.irsa.swapEffectiveDate;
    rollAndPayHolidays = inst.irsa.rollAndPaymentDateHolidayCalendarsList;
  } else if (inst.hasFraAttributes) {
    tenor = inst.ifra.tenor;
    maturityDate = inst.ifra.maturityDate;
    sideConvention = inst.ifra.sideConvention;
    effectiveDate = inst.ifra.swapEffectiveDate;
    rollAndPayHolidays = inst.ifra.rollAndPaymentDateHolidayCalendarsList;
  } else if (inst.hasForwardStartingInterestRateSwapAttributes) {
    tenor = inst.fsIrs.tenor;
    maturityDate = inst.fsIrs.maturityDate;
    sideConvention = inst.fsIrs.sideConvention;
    effectiveDate = inst.fsIrs.swapEffectiveDate;
    rollAndPayHolidays = inst.fsIrs.rollAndPaymentDateHolidayCalendarsList;
    forwardStartingPeriod = inst.fsIrs.forwardStartingPeriod;
  } else if (inst.hasOvernightIndexSwapAttributes) {
    tenor = inst.ois.tenor;
    tenorTimePeriod = inst.ois.tenorTimePeriod;
    maturityDate = inst.ois.maturityDate;
    sideConvention = inst.ois.sideConvention;
    effectiveDate = inst.ois.swapEffectiveDate;
    rollAndPayHolidays = inst.ois.rollAndPaymentDateHolidayCalendarsList;
    forwardStartingPeriod = inst.ois.forwardStartingPeriod;
    forwardStartingPeriodTimePeriod = inst.ois.forwardStartingPeriodTimePeriod;
  } else if (inst.hasBasisSwapAttributes) {
    tenor = inst.basisSwap.tenor;
    maturityDate = inst.basisSwap.maturityDate;
    sideConvention = inst.basisSwap.sideConvention;
    effectiveDate = inst.basisSwap.swapEffectiveDate;
    rollAndPayHolidays = inst.basisSwap.rollAndPaymentDateHolidayCalendarsList;
  } else if (inst.hasSinglePeriodSwapAttributes) {
    tenor = inst.sps.tenor;
    tenorTimePeriod = inst.sps.tenorTimePeriod;
    maturityDate = inst.sps.maturityDate;
    sideConvention = inst.sps.sideConvention;
    effectiveDate = inst.sps.swapEffectiveDate;
    rollAndPayHolidays = inst.sps.rollAndPaymentDateHolidayCalendarsList;
  } else if (inst.hasZeroCouponInflationSwapAttributes) {
    tenor = inst.zeroCouponInflationSwap.tenor;
    tenorTimePeriod = inst.zeroCouponInflationSwap.tenorTimePeriod;
    maturityDate = inst.zeroCouponInflationSwap.maturityDate;
    sideConvention = inst.zeroCouponInflationSwap.sideConvention;
    effectiveDate = inst.zeroCouponInflationSwap.swapEffectiveDate;
    rollAndPayHolidays = inst.zeroCouponInflationSwap.rollAndPaymentDateHolidayCalendarsList;
    forwardStartingPeriod = inst.zeroCouponInflationSwap.forwardStartingPeriod;
    forwardStartingPeriodTimePeriod = inst.zeroCouponInflationSwap.forwardStartingPeriodTimePeriod;
  }

  return [
    ["Tenor", tenor],
    ["Tenor Time Period", getEnumName(InterestRateSwapAttributesTimePeriod, tenorTimePeriod)],
    ["Roll & Payment Date Holiday Calendar", arrayToString(rollAndPayHolidays)],
    ["Side Convention", getEnumName(InterestRateSwapAttributesSideConvention, sideConvention)],
    ["Effective Date", moment(effectiveDate).local().format("MMMM Do YYYY")],
    ["Maturity Date", moment(maturityDate).local().format("MMMM Do YYYY")],
    ["Forward Starting Period", forwardStartingPeriod],
    ["Forward Starting Period Time Period", getEnumName(InterestRateSwapAttributesTimePeriod, forwardStartingPeriodTimePeriod)]
  ];
}

function getFixedLegDetails(inst) {
  if (inst.hasIrsAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.irsa.fixedPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.irsa.fixedCompoundingMethod
        ),
      ],
      ["Roll Convention", inst.irsa.fixedRollConvention],
      [
        "Fixed Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.irsa.fixedBusinessDayConvention
        ),
      ],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.irsa.fixedPaymentDateRelativeTo
        ),
      ],
    ];
  } else if (inst.hasFraAttributes) {
    return [
      [
        "Fixing Date Holiday Calendars List",
        arrayToString(inst.ifra.fixedFixingDateHolidayCalendarsList),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.ifra.fixedDayCountConvention
        ),
      ],
      [
        "Fixed Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ifra.fixedBusinessDayConvention
        ),
      ],
    ];
  } else if (inst.hasForwardStartingInterestRateSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.fsIrs.fixedPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.fsIrs.fixedCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.fsIrs.fixedDayCountConvention
        ),
      ],
      ["Roll Convention", inst.fsIrs.fixedRollConvention],
      [
        "Fixed Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.fsIrs.fixedBusinessDayConvention
        ),
      ],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.fsIrs.fixedPaymentDateRelativeTo
        ),
      ],
    ];
  } else if (inst.hasOvernightIndexSwapAttributes) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.ois.fixedPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.ois.fixedCompoundingMethod
        ),
      ],
      [
        "Day Count Convention",
        getEnumName(
          InterestRateSwapAttributesDayCountConvention,
          inst.ois.fixedDayCountConvention
        ),
      ],
      ["Roll Convention", inst.ois.fixedRollConvention],
      [
        "Fixed Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.ois.fixedBusinessDayConvention
        ),
      ],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.ois.fixedPaymentDateRelativeTo
        ),
      ],
    ];
  } else if (inst.hasSinglePeriodSwapAttributes) {
    return [
      [ "Payment Frequency", getEnumName(InterestRateSwapAttributesFrequency, inst.sps.fixedPaymentFrequency) ],
      [ "Compounding", getEnumName(InterestRateSwapAttributesCompoundingMethod, inst.sps.fixedCompoundingMethod) ],
      [ "Day Count Convention", getEnumName( InterestRateSwapAttributesDayCountConvention, inst.sps.fixedDayCountConvention) ],
      [ "Roll Convention", inst.ois.fixedRollConvention ],
      [ "Fixed Business Day Convention", getEnumName(InterestRateSwapAttributesBusinessDayConvention, inst.sps.fixedBusinessDayConvention) ],
      [ "Payment Date relative to", getEnumName(InterestRateSwapAttributesDateRelativeTo, inst.sps.fixedPaymentDateRelativeTo) ]
    ];
  } else if (inst.hasZeroCouponInflationSwapAttributes) {
    return [
      [ "Payment Frequency", getEnumName(InterestRateSwapAttributesFrequency, inst.zeroCouponInflationSwap.fixedPaymentFrequency) ],
      [ "Roll Convention", inst.zeroCouponInflationSwap.fixedRollConvention ],
      [ "Payment Date relative to", getEnumName(InterestRateSwapAttributesDateRelativeTo, inst.zeroCouponInflationSwap.fixedPaymentDateRelativeTo) ],
      [ "First Unadjusted Payment Date", moment(inst.zeroCouponInflationSwap.fixedFirstUnadjustedPaymentDate).local().format("MMMM Do YYYY") ]
    ];
  }

  return [];
}

function getDerivativeDetails(inst) {
  if (inst.hasFutureAttributes) {
    return [
      [
        "Maturity Date",
        moment(inst.fut.maturityDate).local().format("MMMM Do YYYY"),
      ],
    ];
  } else if (inst.hasOptionAttributes) {
    return [
      [
        "Maturity Date",
        moment(inst.opt.maturityDate).local().format("MMMM Do YYYY"),
      ],
      ["Put or Call", getEnumName(PutOrCall, inst.opt.putOrCall)],
      [
        "Strike Price",
        validatePrice(inst.opt.strikePrice)
          ? parsePrice(inst.opt.strikePrice, inst.scale)
          : null,
      ],
    ];
  }
  return [];
}

function getFloatLeg1Leg2Details(inst, leg2 = false) {
  if (!leg2) {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.basisSwap.leg1FloatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.basisSwap.leg1FloatCompoundingMethod
        ),
      ],
      ["Roll Conventions", inst.basisSwap.leg1FloatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.basisSwap.leg1FloatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.basisSwap.leg1FloatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.basisSwap.leg1FloatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.basisSwap.leg1FloatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.basisSwap.leg1FloatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.basisSwap.leg1FloatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.basisSwap.leg1FloatRateResetFrequency
        ),
      ],
      [
        "Holiday Calendar",
        arrayToString(inst.basisSwap.leg1FloatFixingDateHolidayCalendarsList),
      ],
    ];
  } else {
    return [
      [
        "Payment Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.basisSwap.leg2FloatPaymentFrequency
        ),
      ],
      [
        "Compounding",
        getEnumName(
          InterestRateSwapAttributesCompoundingMethod,
          inst.basisSwap.leg2FloatCompoundingMethod
        ),
      ],
      ["Roll Conventions", inst.basisSwap.leg2FloatRollConvention],
      [
        "Reset Date Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.basisSwap.leg2FloatResetDateBusinessDayConvention
        ),
      ],
      [
        "Business Day Convention",
        getEnumName(
          InterestRateSwapAttributesBusinessDayConvention,
          inst.basisSwap.leg2FloatBusinessDayConvention
        ),
      ],
      ["Rate Index", inst.basisSwap.leg2FloatingRateIndex],
      [
        "Payment Date relative to",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.basisSwap.leg2FloatPaymentDateRelativeTo
        ),
      ],
      ["Rate Index Tenor", inst.basisSwap.leg2FloatingRateIndexTenor],
      [
        "Reset Date Relative To",
        getEnumName(
          InterestRateSwapAttributesDateRelativeTo,
          inst.basisSwap.leg2FloatResetDateRelativeTo
        ),
      ],
      [
        "Rate Reset Frequency",
        getEnumName(
          InterestRateSwapAttributesFrequency,
          inst.basisSwap.leg2FloatRateResetFrequency
        ),
      ],
      [
        "Holiday Calendar",
        arrayToString(inst.basisSwap.leg2FloatFixingDateHolidayCalendarsList),
      ],
    ];
  }
}

function getForexDetails(inst, metadata) {
  if (inst.hasForexAttributes) {
    return [
      [
        "Base Currency",
        inst.forex.baseCurrency,
      ],
      [
        "Quote Currency",
        inst.forex.quoteCurrency,
      ],
    ];
  }

  return [];
}

function getEventDetails(inst, metadata) {
  let subTypes = [];

  if (!!inst && Array.isArray(inst.eventAttributes)) {
    inst.eventAttributes.forEach((event) => {
      subTypes.push(event.symbol);
    });

    if (subTypes.length > 0) {
      return [["Eligible Sub Types", subTypes.join(", ")]];
    }
  }

  return [];
}

function getNonDeliverableForwardDetails(inst, metadata) {
  if (inst.hasNonDeliverableForwardAttributes) {
    return [["Tenor", inst.nonDeliverableForwardAttributes.tenor],
      ["Side Convention", getEnumName(InterestRateSwapAttributesSideConvention, inst.nonDeliverableForwardAttributes.sideConvention)],
      ["Forward Starting Period", inst.nonDeliverableForwardAttributes.forwardStartingPeriod],
      ["Forward Starting Period Time Period", getEnumName(InterestRateSwapAttributesTimePeriod, inst.nonDeliverableForwardAttributes.forwardStartingPeriodTimePeriod)],
      ["Valuation Date", moment(inst.nonDeliverableForwardAttributes.valuationDate).local().format("MMMM Do YYYY")],
      ["Fixing Date", moment(inst.nonDeliverableForwardAttributes.fixingDate).local().format("MMMM Do YYYY")],
      ["Settlement Currency", inst.nonDeliverableForwardAttributes.settlementCurrency],
      ["Tenor Time Period", getEnumName(InterestRateSwapAttributesTimePeriod, inst.nonDeliverableForwardAttributes.tenorTimePeriod)],
    ];
  }
  return [];
}

function data_set_creator(inst, metadata) {
  let details = {};
  details["contract_details"] = [
    ...getCommonDetails(inst, metadata),
    ...getSwapDetails(inst),
  ];
  details["fixed_leg_details"] = getFixedLegDetails(inst);
  details["float_leg_details"] = getFloatLegDetails(inst);

  if (inst.hasBasisSwapAttributes) {
    details["float_leg_1_details"] = getFloatLeg1Leg2Details(inst);
    details["float_leg_2_details"] = getFloatLeg1Leg2Details(inst, true);
  }

  details["derivative_details"] = getDerivativeDetails(inst);
  details["event_details"] = getEventDetails(inst);
  details["non_deliverable_forward_details"] = getNonDeliverableForwardDetails(inst);
  details["forex_details"] = getForexDetails(inst);

  return details;
}

export function getFormattedInstrumentAttributes(inst, metadata) {
  let dataset = data_set_creator(inst, metadata);

  if (inst.jsonAttributes instanceof Object) {
    for (const property in inst.jsonAttributes) {
      dataset["contract_details"].push([
        property,
        inst.jsonAttributes[property],
      ]);
    }
  }

  dataset["contract_details"] = dataset["contract_details"].filter(
    (data) => data[1] !== null && data[1] !== "" && data[1] !== "Invalid date"
  );
  dataset["fixed_leg_details"] = dataset["fixed_leg_details"].filter(
    (data) => data[1] !== null && data[1] !== "" && data[1] !== "Invalid date"
  );
  dataset["float_leg_details"] = dataset["float_leg_details"].filter(
    (data) => data[1] !== null && data[1] !== "" && data[1] !== "Invalid date"
  );
  dataset["float_leg_1_details"] =
    dataset["float_leg_1_details"] &&
    dataset["float_leg_1_details"].filter(
      (data) => data[1] !== null && data[1] !== "" && data[1] !== "Invalid date"
    );
  dataset["float_leg_2_details"] =
    dataset["float_leg_2_details"] &&
    dataset["float_leg_2_details"].filter(
      (data) => data[1] !== null && data[1] !== "" && data[1] !== "Invalid date"
    );

  let filteredDataset = [];
  if (dataset["contract_details"].length > 0)
    filteredDataset.push(["contract_details", dataset["contract_details"]]);
  if (dataset["fixed_leg_details"].length > 0)
    filteredDataset.push(["fixed_leg_details", dataset["fixed_leg_details"]]);
  if (dataset["float_leg_details"].length > 0)
    filteredDataset.push(["float_leg_details", dataset["float_leg_details"]]);
  if (
    dataset["float_leg_1_details"] &&
    dataset["float_leg_1_details"].length > 0
  )
    filteredDataset.push([
      "float_leg_1_details",
      dataset["float_leg_1_details"],
    ]);
  if (
    dataset["float_leg_2_details"] &&
    dataset["float_leg_2_details"].length > 0
  )
    filteredDataset.push([
      "float_leg_2_details",
      dataset["float_leg_2_details"],
    ]);
  if (dataset["derivative_details"].length > 0)
    filteredDataset.push(["derivative_details", dataset["derivative_details"]]);

  if (dataset["event_details"].length > 0)
    filteredDataset.push(["event_details", dataset["event_details"]]);

  if (dataset["forex_details"].length > 0)
    filteredDataset.push(["forex_details", dataset["forex_details"]]);

  if (dataset["non_deliverable_forward_details"].length > 0)
    filteredDataset.push(["non_deliverable_forward_details", dataset["non_deliverable_forward_details"]]);

  return filteredDataset;
}

class SwapDetails extends Component {
  render() {
    let { symbol, viewSwapDetail, setViewContext, instruments, metadata } =
      this.props;

    const inst = instruments[symbol];
    const filteredDataset = getFormattedInstrumentAttributes(inst, metadata);

    return (
      <div>
        <Dialog
          title={`${symbol} Details`}
          show={viewSwapDetail}
          onClose={() => setViewContext({ viewSwapDetail: false })}
          dialogClassName="swap-details-modal"
        >
          <Card>
            <Card.Body>{inst.description}</Card.Body>
            {inst.nonTradable && (
              <Card.Footer>
                <b>Non-Tradable</b>
              </Card.Footer>
            )}
          </Card>
          {filteredDataset.map((dataset) => {
            return (
              <AccordionMain title={i18n.t(dataset[0])} key={dataset[0]}>
                {dataset[1].map((data, index) => (
                  <ListGroup className="swap-details-list-group" key={index}>
                    <ListGroup.Item
                      key={0}
                      className={`swap-details-list-item swap-details-key ${
                        index % 2 === 0 ? "oddRow" : ""
                      }`}
                    >
                      {data[0]}
                    </ListGroup.Item>
                    <ListGroup.Item
                      key={1}
                      className={`swap-details-list-item ${
                        index % 2 === 0 ? "oddRow" : ""
                      }`}
                    >
                      {validURL(data[1]) ? (
                        <a
                          href={data[1]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      ) : (
                        data[1]
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </AccordionMain>
            );
          })}
        </Dialog>
      </div>
    );
  }
}

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(Object(str).toString());
}

export default connect(mapStateToProps, mapDispatchToProps)(SwapDetails);
