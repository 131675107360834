import ProtobufParser from "../modules/protobufParser";
import { StatusCode } from "grpc-web";
import { v4 } from "uuid";
import { apiUrl, convertScaledPriceToInt, sleep } from "../modules/util";
import Notification from "../modules/notifications";
import { apiCall, refreshTokens } from "../modules/apiCall";
import { DEFAULT_PAGE_SIZE } from "../constants/strings";

const { OrderType, Side, CrossType } = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb");

const { TimeInForce,
  ExecutionType,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb");

const {
  SearchExecutionsRequest,
  DownloadTradesRequest,
  DownloadOrdersRequest,
  DownloadExecutionsRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/order_api_pb");
const {
  OrderAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/order_api_grpc_web_pb");
const {
  ListAccountsRequest,
  ListUsersRequest,
  GetWhoAmIRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/accounts_api_pb");
const {
  AccountsAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/accounts_api_grpc_web_pb");
const {
  InsertOrderRequest,
  InsertOrderCrossRequest,
  CancelOrderRequest,
  CancelReplaceOrderRequest,
  CreateOrderSubscriptionRequest,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/order_entry_api_pb");
const {
  OrderEntryAPIClient,
} = require("@connamara-tech/ep3-domain/web/src/api/connamara/ep3/v1beta1/order_entry_api_grpc_web_pb");

const activeCalls = new Set();

const entryClient = new OrderEntryAPIClient(
  apiUrl(),
  null,
  null
);
const searchClient = new OrderAPIClient(
  apiUrl(),
  null,
  null
);
const accountsClient = new AccountsAPIClient(
  apiUrl(),
  null,
  null
);

export default class OrderService {

  static downloadReport(
    action,
    startDate,
    endDate,
    onSuccess,
    onError
  ) {
    const callName = action === "orders" ? "downloadOrders" : action === "executions" ? "downloadExecutions" : "downloadTrades";
    let request = action === "orders" ? new DownloadOrdersRequest() : action === "executions" ? new DownloadExecutionsRequest() : new DownloadTradesRequest();
    request.setStartTime(startDate);
    request.setEndTime(endDate);

    var chunks = [];
    var subscriptionHooks = {
      data: data => chunks.push(data.getFilechunk()),
      status: sts => {
        if (sts && sts.code === 0) onSuccess(chunks.join(""));
      },
      end: null,
      error: onError
    }

    apiCall(searchClient, callName, request, null, subscriptionHooks)
  }

  static listAccounts(user, cb) {
    var request = new ListAccountsRequest();
    request.setUser(user);
    apiCall(accountsClient, "listAccounts", request, cb);
  }

  static listUsers(cb) {
    var request = new ListUsersRequest();
    apiCall(accountsClient, "listUsers", request, cb);
  }

  static getWhoAmI(cb) {
    var request = new GetWhoAmIRequest();

    apiCall(accountsClient, "getWhoAmI", request, cb);
  }

  static submitCancelOrder(cb, symbol, params) {
    var request = new CancelOrderRequest();
    request.setClordId(v4());
    request.setSymbol(symbol);
    request.setSymbolSubType(params.symbolSubType)
    request.setOrderId(params.id);

    apiCall(entryClient, "cancelOrder", request, cb);
  }

  static submitAmendOrder(cb, symbol, params) {
    const timeInForce = parseInt(params.timeInForce);

    var request = new CancelReplaceOrderRequest();
    request.setClordId(v4());
    request.setSymbol(symbol);
    request.setSymbolSubType(params.subType);
    request.setOrderId(params.id);
    request.setTimeInForce(timeInForce);

    if (timeInForce === TimeInForce.TIME_IN_FORCE_GOOD_TILL_TIME) {
      request.setGoodTillTime(ProtobufParser.toTimestamp(params.goodTillTime));
    }

    request.setOrderQty(convertScaledPriceToInt(params.quantity, params.qtyScale));
    if (params.minQty) {
      request.setMinQty(convertScaledPriceToInt(params.minQty, params.qtyScale));
    }
    request.setPrice(convertScaledPriceToInt(params.price, params.priceScale));
    if (params.stopPrice) {
      request.setStopPrice(
          convertScaledPriceToInt(params.stopPrice, params.priceScale)
      );
    }

    request.setAllOrNone(!!params.allOrNone);

    apiCall(entryClient, "cancelReplaceOrder", request, cb);
  }

  static submitNewCross(cb, symbol, params) {
    const timeInForce = parseInt(params.timeInForce);

    var buySide = new InsertOrderRequest();
    var sellSide = new InsertOrderRequest();

    buySide.setClordId(v4());
    sellSide.setClordId(v4());

    buySide.setSymbol(symbol);
    sellSide.setSymbol(symbol);

    buySide.setAccount(params.account);
    sellSide.setAccount(params.account2);

    buySide.setUser(params.user);
    sellSide.setUser(params.user2);

    buySide.setTimeInForce(timeInForce);
    sellSide.setTimeInForce(timeInForce);

    if (timeInForce === TimeInForce.TIME_IN_FORCE_GOOD_TILL_TIME) {
      buySide.setGoodTillTime(ProtobufParser.toTimestamp(params.goodTillTime));
      sellSide.setGoodTillTime(ProtobufParser.toTimestamp(params.goodTillTime));
    }

    const ordQty = convertScaledPriceToInt(params.quantity, params.qtyScale);
    buySide.setOrderQty(ordQty);
    sellSide.setOrderQty(ordQty);

    if (params.minQty) {
      const minQty = convertScaledPriceToInt(params.minQty, params.qtyScale);
      buySide.setMinQty(minQty);
      sellSide.setMinQty(minQty);
    }

    buySide.setType(OrderType.ORDER_TYPE_LIMIT);
    sellSide.setType(OrderType.ORDER_TYPE_LIMIT);

    buySide.setSide(Side.SIDE_BUY);
    sellSide.setSide(Side.SIDE_SELL);

    const px = convertScaledPriceToInt(params.price, params.priceScale);
    buySide.setPrice(px);
    sellSide.setPrice(px);

    buySide.setAllOrNone(true);
    sellSide.setAllOrNone(true);

    var request = new InsertOrderCrossRequest();
    request.setCrossId(v4());
    request.setCrossType(CrossType.CROSS_TYPE_ALL_OR_NONE);
    request.setCrossPrioritizedSide(parseInt(params.side));
    request.setRequestsList([buySide, sellSide]);
    request.setBlockTradeIndicator(!!params.blockTradeIndicator);
    if (!!params.transactionBookedTime) {
      request.setTransactionBookedTime(ProtobufParser.toTimestamp(params.transactionBookedTime))
    }

    apiCall(entryClient, "insertOrderCross", request, cb);
  }

  static submitNewOrder(cb, symbol, params, sessionId) {
    const timeInForce = parseInt(params.timeInForce);

    var request = new InsertOrderRequest();
    request.setClordId(v4());
    request.setSymbol(symbol);
    request.setAccount(params.account);
    request.setUser(params.user)
    request.setTimeInForce(timeInForce);

    if (timeInForce === TimeInForce.TIME_IN_FORCE_GOOD_TILL_TIME) {
      request.setGoodTillTime(ProtobufParser.toTimestamp(params.goodTillTime));
    }

    request.setSymbolSubType(params.subType)

    if (params.quantity && !isNaN(parseFloat(params.quantity))) {
      request.setOrderQty(convertScaledPriceToInt(params.quantity, params.qtyScale));
    }

    if (params.cashOrderQty && !isNaN(parseFloat(params.cashOrderQty))) {
      let cashScale = params.priceScale;

      if (params.qtyScale > 0) {
        cashScale *= params.qtyScale;
      }

      request.setCashOrderQty(convertScaledPriceToInt(params.cashOrderQty, cashScale));
    }

    if (sessionId && params.cancelOnDisconnect) {
      request.setSessionId(sessionId);
    }
    if (params.minQty) {
      request.setMinQty(convertScaledPriceToInt(params.minQty, params.qtyScale));
    }
    request.setType(parseInt(params.orderType));
    request.setSide(parseInt(params.side));
    if (
      request.getType() === OrderType.ORDER_TYPE_LIMIT ||
      request.getType() === OrderType.ORDER_TYPE_STOP_LIMIT
    ) {
      if (params.quote) {
        request.setPrice(params.price);
      } else {
        request.setPrice(
          convertScaledPriceToInt(params.price, params.priceScale)
        );
      }
    }
    if (
      request.getType() === OrderType.ORDER_TYPE_STOP ||
      request.getType() === OrderType.ORDER_TYPE_STOP_LIMIT
    ) {
      request.setStopPrice(convertScaledPriceToInt(params.stopPrice, params.priceScale));
      request.setTriggerMethod(params.triggerMethod);
    }

    if (params.quote) {
      request.setQuote(params.quote);
    }

    request.setAllOrNone(params.allOrNone);

    apiCall(entryClient, "insertOrder", request, cb);
  }

  static subscribe(onData, onStatus, onError, onEnd) {
    const request = new CreateOrderSubscriptionRequest();

    const onErr = (error) => {
      if (error && error.message === "Http response at 400 or 500 level") {
        console.log({ "Resubscribe Order Data Triggered": error });
        sleep(1000).then(() =>
          this.subscribe(onData, onStatus, onError, onEnd)
        );
      } else if (error && error.code === StatusCode.UNAUTHENTICATED) {
        refreshTokens().then(() => {
          this.subscribe(onData, onStatus, onError, onEnd);
        });
      }
    };

    const call = apiCall(entryClient, "createOrderSubscription", request, null, { data: onData, status: onStatus, end: onEnd, error: onErr });

    for (let entry of activeCalls.values()) {
      entry.cancel();
    }
    activeCalls.clear();
    if (call) {
      activeCalls.add(call);
    }
    return call;
  }

  static searchHistory(cb, symbol, startTime, executionTypes = []) {
    var request = new SearchExecutionsRequest();
    request.setSymbol(symbol);
    request.setStartTime(ProtobufParser.toTimestamp(startTime));
    request.setPageSize(DEFAULT_PAGE_SIZE);
    executionTypes.forEach((executionType) => {
      request.addTypes(executionType);
    });
    const cbLoop = (err, response) => {
      if (response) {
        const executionsList = response.getExecutionsList();
        
        if (!!executionsList) {
          executionsList.forEach((execution) => {
            cb(execution);
          });
        }

        if (response.getNextPageToken()) {
          request.setPageToken(response.getNextPageToken());

          apiCall(searchClient, "searchExecutions", request, cbLoop);
        }
      }

      if (err && err.code !== StatusCode.NOT_FOUND) {
        Notification.error(`Error when searching history: \n ${err.message}`);
      }
    };

    apiCall(searchClient, "searchExecutions", request, cbLoop);
  }

  /**
   *
   * @param {any} cb - Callback function.
   * @param {SearchExecutionsRequest} request - Search executions API request.
   */
  static searchOrderExecutions(cb, request) {
    request.setPageSize(100);

    const cbLoop = (err, response) => {
      if (response) {
        const executions = response.getExecutionsList();
        if (cb) {
          cb(executions);
        }

        if (response.getNextPageToken()) {
          request.setPageToken(response.getNextPageToken());

          apiCall(searchClient, "searchExecutions", request, cbLoop);
        }
      }

      if (err && err.code !== StatusCode.NOT_FOUND) {
        Notification.error(`Error when searching order executions: \n ${err.message}`);
      }
    };

    apiCall(searchClient, "searchExecutions", request, cbLoop);
  }
}
export { ExecutionType };
