import { ADD_INSTRUMENT, UPDATE_INSTRUMENT, ADD_INSTRUMENTS } from '../constants/actions'

const instrument = (state = {}, action) => {
    const { type } = action;

    switch (type) {
        case ADD_INSTRUMENT: {
            const { instrument: inst } = action
            return Object.assign({}, state, inst)
        }
        case UPDATE_INSTRUMENT: {
            const { stats, symbol } = action;
            const { last } = stats

            const { last: oldlast, lastdelta } = state

            if (oldlast === undefined) {
                return Object.assign({}, state, stats, { symbol })
            }

            if (last !== undefined && last !== null) {
                return Object.assign({}, state, stats, {
                    symbol,
                    lastdelta: last - oldlast,
                })
            }

            return Object.assign({}, state, stats, {
                symbol,
                last: oldlast,
                lastdelta: lastdelta,
            })
        }

        default:
            return state
    }
}

export const instruments = (state = {}, action) => {
    const { type } = action;
    switch (type) {
        case ADD_INSTRUMENT: {
            const { instrument: newInst } = action
            const { symbol } = newInst
            return Object.assign({}, state, {
                [symbol]: instrument(state[symbol], action),
            }
            )
        }
        
        case ADD_INSTRUMENTS: {
            state = Object.assign({}, state);
            action.instruments.forEach(element => {
                state[element.symbol] = element
            });
            return state;
        }

        case UPDATE_INSTRUMENT: {
            const { symbol } = action
            return Object.assign({}, state, {
                [symbol]: instrument(state[symbol], action),
            }
            )
        }
        default:
            return state;
    }
}