import React from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import { Button } from "react-bootstrap";

const mapStateToProps = (state) => {
  return {}
}

const ChangePasswordForm = (props) => {
  const {
    handleSubmit,
    handleClose,
    errors,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="currentPassword">
            Current Password
          </label>
          <Field
            id="currentPassword"
            name="currentPassword"
            className="text-input form-control"
            type="password"
          />
          {errors.currentPassword && <div className="form-input-error">{errors.currentPassword}</div>}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="newPassword">
            New Password
          </label>
          <Field
            id="newPassword"
            name="newPassword"
            className="text-input form-control"
            type="password"
          />
          {errors.newPassword && <div className="form-input-error">{errors.newPassword}</div>}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="reEnterPassword">
            Confirm Password
          </label>
          <Field
            id="reEnterPassword"
            name="reEnterPassword"
            className="text-input form-control"
            type="password"
          />
          {errors.reEnterPassword && <div className="form-input-error">{errors.reEnterPassword}</div>}
        </div>
      </div>

      <div className="form-row">
        <div className="col text-right">
          <Button variant={"outline-success"} type="submit" className={"btn btn-primary btn-side btn-buy"}>
            Change
          </Button>
        </div>
        <div className="col-2" />

        <div className="col">
          <Button variant={"outline-danger"} onClick={() => { handleClose() }} className={"btn btn-primary btn-side btn-sell"}>
            Cancel
          </Button>
        </div>

      </div>
    </form>
  );
};

const EnhancedChangePasswordForm = withFormik({
  mapPropsToValues: () => ({
    currentPassword: '',
    newPassword: '',
    reEnterPassword: ''
  }),

  validate: (values) => {
    let errors = {};

    if (!values.currentPassword || !String(values.currentPassword).trim()) {
      errors.currentPassword = "Field is required";
    }

    if (!values.newPassword || !String(values.newPassword).trim()) {
      errors.newPassword = "Field is required";
    }

    if (!values.reEnterPassword || !String(values.reEnterPassword).trim()) {
      errors.reEnterPassword = "Field is required";
    }

    if (values.newPassword !== values.reEnterPassword) {
      errors.reEnterPassword = "Password is not same as new password.";
    }

    return errors;
  },

  handleSubmit: (values, { props }) => {

    props.doSubmit(values.currentPassword, values.newPassword)
  },

  displayName: "ChangePasswordForm",
})(ChangePasswordForm);

export default connect(mapStateToProps, null)(EnhancedChangePasswordForm);
