import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'; // delays rendering of the ui until the app rehydrates
import { connectRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from './App';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import './App.css';

import { unregister } from './registerServiceWorker';
import reducers from './reducers';
import { store, history } from './store';

// save store state in localstorage
let persistor = persistStore(store);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <App history={history} />
        </I18nextProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

// run once
Moment.locale('en')
momentLocalizer();
unregister(); // don't register service workers until we resolve PWA caching issues.
render();

// hot reloading
if (module.hot) {
  // reload components
  module.hot.accept('./App', () => {
    render();
  });

  // reload reducers
  module.hot.accept('./reducers', () => {
    store.replaceReducer(connectRouter(history)(reducers));
  });
}
