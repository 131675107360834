import { StatusCode } from "grpc-web";
import QuoteService from "../services/QuoteService";
import Notification from "../modules/notifications";
import {
  UPDATE_QUOTE_SUBSCRIPTION,
  UPDATE_DEALERS,
  UPDATE_REQUESTERS,
} from "../constants/actions";
import RequestForQuote from "../entities/RequestForQuote";
import Quote from "../entities/Quote";
import QuoteReject from "../entities/QuoteReject";
import { ListInstrumentsForSymbols } from "../actions/instruments";

export function ListDealers() {
  return (dispatch) => {
    QuoteService.listDealers((err, response) => {
      if (response) {
        let dealers = [];
        const dealerFirmsList = response.getFirmsList();
        let dealerFrimsDisplayNameList = response.getDisplayNamesList();

        if (
          !dealerFrimsDisplayNameList ||
          dealerFrimsDisplayNameList.length !== dealerFirmsList.length
        ) {
          dealerFrimsDisplayNameList = dealerFirmsList;
        }

        dealerFirmsList.forEach((dealer, idx) =>
          dealers.push({
            label: dealerFrimsDisplayNameList[idx],
            value: dealer,
          })
        );
        dispatch({
          type: UPDATE_DEALERS,
          payload: dealers,
        });
      }

      if (err) {
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning(
              "Could not find any dealers associated with this participant"
            );
            break;
          default:
            Notification.error(
              `Error when querying dealers: \n ${err.message}`
            );
            break;
        }
      }
    });
  };
}

export function ListRequesters() {
  return (dispatch) => {
    QuoteService.listRequesters((err, response) => {
      if (response) {
        let requesters = [];
        const requesterFirmsList = response.getFirmsList();
        let requesterFirmsDisplayNameList = response.getDisplayNamesList();

        if (
          !requesterFirmsDisplayNameList ||
          requesterFirmsDisplayNameList.length !== requesterFirmsList.length
        ) {
          requesterFirmsDisplayNameList = requesterFirmsList;
        }

        requesterFirmsList.forEach((requester, idx) =>
          requesters.push({
            label: requesterFirmsDisplayNameList[idx],
            value: requester,
          })
        );
        dispatch({
          type: UPDATE_REQUESTERS,
          payload: requesters,
        });
      }

      if (err) {
        switch (err.code) {
          case StatusCode.NOT_FOUND:
            Notification.warning(
              "Could not find any requesters associated with this participant"
            );
            break;
          default:
            Notification.error(
              `Error when querying requesters: \n ${err.message}`
            );
            break;
        }
      }
    });
  };
}

export function SubscribeQuotes() {
  return (dispatch, getState) => {
    const onData = function (response) {
      const state = getState();
      const pendingSymbols = [];
      const collectPendingSymbols = (obj, instruments) => {
        Object.keys(obj).forEach((item) => {
          const symbol = obj[item].symbol;
          if (!instruments[symbol]) {
            pendingSymbols.push(symbol);
          }
        });
      };

      if (response.hasSnapshot()) {
        const rfqList = response
          .getSnapshot()
          .getRequestForQuotesList()
          .map((rfq) => {
            return RequestForQuote.get(rfq);
          });

        const quotesList = response
          .getSnapshot()
          .getQuotesList()
          .map((rfq) => {
            return Quote.get(rfq);
          });

        collectPendingSymbols(
          { ...rfqList, ...quotesList },
          state.trader.instruments
        );

        dispatch({
          type: UPDATE_QUOTE_SUBSCRIPTION,
          payload: {
            rfqList: rfqList,
            quotesList: quotesList,
            quoteRejectsList: [],
          },
        });
      }

      if (response.hasUpdate()) {
        const rfqList = response
          .getUpdate()
          .getRequestForQuotesList()
          .map((rfq) => {
            return RequestForQuote.get(rfq);
          });

        const quotesList = response
          .getUpdate()
          .getQuotesList()
          .map((quote) => {
            return Quote.get(quote);
          });

        const quoteRejectsList = response
          .getUpdate()
          .getRejectsList()
          .map((quoteReject) => {
            return QuoteReject.get(quoteReject);
          });

        collectPendingSymbols(
          { ...rfqList, ...quotesList, ...quoteRejectsList },
          state.trader.instruments
        );

        if (rfqList.length > 0) {
          rfqList.forEach((rfq) => {
            Notification.info(`Request for Quote ${rfq.id} ${rfq.statusName}`);
          });
        }

        if (quotesList.length > 0) {
          quotesList.forEach((quote) => {
            Notification.info(`Quote ${quote.id} ${quote.statusName}`);
          });
        }

        if (quoteRejectsList.length > 0) {
          quoteRejectsList.forEach((reject) => {
            console.error(reject);
            Notification.error(
              `${reject.rejectedItem} rejected: ${reject.text}`
            );
          });
        }

        if (
          rfqList.length > 0 ||
          quotesList.length > 0 ||
          quoteRejectsList.length > 0
        ) {
          dispatch({
            type: UPDATE_QUOTE_SUBSCRIPTION,
            payload: {
              rfqList: rfqList,
              quotesList: quotesList,
              quoteRejectsList: quoteRejectsList,
            },
          });
        }
      }

      if (pendingSymbols && pendingSymbols.length > 0) {
        dispatch(ListInstrumentsForSymbols(pendingSymbols));
      }
    };

    const onStatus = function (status) {
      if (status.details === "context canceled") {
        dispatch(SubscribeQuotes());
        return;
      }

      switch (status.code) {
        case StatusCode.OK:
          break;
        case StatusCode.UNAUTHENTICATED:
          break;
        default:
          Notification.error(`Cannot subscribe to quotes: ${status}`);
          break;
      }
    };

    const onEnd = function (end) {
      dispatch(SubscribeQuotes());
    };

    return QuoteService.subscribe(onData, onStatus, onStatus, onEnd);
  };
}

export function CancelAllQuotes() {
  return (dispatch, getState) => {
    let state = getState();
    let currentUser = state.trader.trader.user;
    currentUser = currentUser ? currentUser.toLowerCase() : currentUser;
    let quotes = Object.values(state.quotes.quotes);

    Notification.info("Cancelling all quotes");
    quotes.forEach((quote) => {
      if (
        Quote.isOpen(quote.statusName) &&
        quote.user.toLowerCase() === currentUser
      )
        QuoteService.deleteQuote(quote);
    });
  };
}

export function SearchRfqs(statuses, startDate) {
  return (dispatch) => {
    const onData = function (response) {
      let rfqList = response.getRequestForQuotesList().map((rfq) => {
        return RequestForQuote.get(rfq);
      });

      dispatch({
        type: UPDATE_QUOTE_SUBSCRIPTION,
        payload: {
          rfqList: rfqList,
          quotesList: [],
          quoteRejectsList: [],
        },
      });
    };

    const onError = function (error) {
      Notification.info("Error while fetching today's RFQs.");
      console.log(error);
    };

    QuoteService.searchRfqs(statuses, startDate, (err, response) => {
      if (response) {
        onData(response);
      } else if (err) {
        onError(err);
      }
    });
  };
}

export function SearchQuotes(statuses, startDate) {
  return (dispatch) => {
    const onData = function (response) {
      let qteList = response.getQuotesList().map((qte) => {
        return Quote.get(qte);
      });

      dispatch({
        type: UPDATE_QUOTE_SUBSCRIPTION,
        payload: {
          rfqList: [],
          quotesList: qteList,
          quoteRejectsList: [],
        },
      });
    };

    const onError = function (error) {
      Notification.info("Error while fetching today's Quotes.");
      console.log(error);
    };

    QuoteService.searchQuotes(statuses, startDate, (err, response) => {
      if (response) {
        onData(response);
      } else if (err) {
        onError(err);
      }
    });
  };
}
