import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "./core/dialog/Dialog";
import {EnhancedOrderTicketForm} from "./book/OrderTicketForm";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import OrderService from "../services/OrderService";
import Notification from '../modules/notifications';

const AmendTicket = ({
    show,
    readonly,
    onClose,
    original,
    onSubmit,
    priceScale,
    qtyScale,
    accounts,
    instruments,
    symbol,
    subType
}) => {
    return (
        <Dialog title={!readonly ? "Amend Order" : "View Order"} show={show} onClose={onClose}>
            <EnhancedOrderTicketForm 
                symbol={symbol}
                subType={subType}
                accounts={accounts}
                instruments={instruments}
                readonly={readonly}
                priceScale={priceScale}
                qtyScale={qtyScale}
                original={original}
                doSubmit={(values) => {
                    const cb = (err, response) => {
                        if (response) {
                            Notification.info('Amend submitted');
                        }

                        if (err) {
                            Notification.error(`Error when submitting order: \n ${err.message}`);
                        }
                    };

                if(values.side === Side.SIDE_SELL) {
                    onClose();
                    return;
                } else  {
                    OrderService.submitAmendOrder(cb, original.symbol, values);
                } 

                onSubmit(values);
                }}
            />
        </Dialog>
    );
};

AmendTicket.propTypes = {
    show: PropTypes.bool,
    readonly: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    symbol: PropTypes.string,
    priceScale: PropTypes.number,
    original: PropTypes.object,
    accounts: PropTypes.object,
    instruments: PropTypes.object,
};

export default AmendTicket;