import { StatusCode } from "grpc-web";
import BookService from "../services/BookService";
import Notification from "../modules/notifications";
import BookSnapshotItem from "../entities/BookSnapshot";
import BookStatusItem from "../entities/BookStatus";
import { UPDATE_BOOK } from "../constants/actions";

export function subscribeBook(symbol, cb) {
  const onData = function (response) {
    if (response.hasHeartbeat() || !response.hasUpdate()) {
      return;
    }

    const bids = response.getUpdate().getBidsList();
    const offers = response.getUpdate().getOffersList();
    const iterations = Math.max(bids.length, offers.length);
    var items = [];

    for (let i = 0; iterations > i; i++) {
      let qtyBid = null;
      let pxBid = null;
      let pxOffer = null;
      let qtyOffer = null;
      let bidSubType = null;
      let offerSubType = null;

      if (bids[i]) {
        pxBid = bids[i].getPx();
        qtyBid = bids[i].getQty();
        bidSubType = bids[i].getSymbolSubType();
      }

      if (offers[i]) {
        pxOffer = offers[i].getPx();
        qtyOffer = offers[i].getQty();
        offerSubType = offers[i].getSymbolSubType();
      }

      items.push(new BookSnapshotItem(qtyBid, pxBid, pxOffer, qtyOffer, bidSubType, offerSubType));
    }

    const status = new BookStatusItem(response.getUpdate());
    cb(response.getUpdate().getSymbol(), items, status);
  };

  const onStatus = function (status) {
    switch (status.code) {
      case StatusCode.OK:
        break;
      default:
        Notification.error("Cannot subscribe to aggregated book");
        break;
    }
  };

  const onEnd = function (end) {
    subscribeBook(symbol, cb);
  };

  return BookService.subscribe(symbol, onData, onStatus, onStatus, onEnd);
}

export function SubscribeBook(symbol) {
  return (dispatch) => {
    const onData = function (response) {

      if (response.hasHeartbeat() || !response.hasUpdate()) {
        return;
      }

      const bids = response.getUpdate().getBidsList();
      const offers = response.getUpdate().getOffersList();
      const iterations = Math.max(bids.length, offers.length);
      var items = [];

      for (let i = 0; iterations > i; i++) {
        let qtyBid = null;
        let pxBid = null;
        let pxOffer = null;
        let qtyOffer = null;
        let bidSubType = null;

        if (bids[i]) {
          pxBid = bids[i].getPx();
          qtyBid = bids[i].getQty();
          bidSubType = bids[i].getSymbolSubType();
        }

        if (offers[i]) {
          pxOffer = offers[i].getPx();
          qtyOffer = offers[i].getQty();
        }

        items.push(new BookSnapshotItem(qtyBid, pxBid, pxOffer, qtyOffer, bidSubType));
      }

      dispatch({
        type: UPDATE_BOOK,
        book: items,
        bookHidden: response.getUpdate().getBookHidden(),
      });
    };

    const onStatus = function (status) {
      switch (status.code) {
        case StatusCode.OK:
          break;
        case StatusCode.UNAUTHENTICATED:
          break;
        default:
          Notification.error(`Cannot subscribe to ${symbol} book: ${status}`);
          break;
      }
    };

    const onEnd = function (end) {
      dispatch(SubscribeBook(symbol));
    };

    return BookService.subscribe(symbol, onData, onStatus, onStatus, onEnd);
  };
}
