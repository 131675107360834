import { UPDATE_COMPLETED_ORDERS } from '../constants/actions';
import OrderService, { ExecutionType } from "../services/OrderService";
import Order from '../entities/Order';

const executionTypes = [
    ExecutionType.EXECUTION_TYPE_FILL,
    ExecutionType.EXECUTION_TYPE_PARTIAL_FILL,
    ExecutionType.EXECUTION_TYPE_CANCELED,
    ExecutionType.EXECUTION_TYPE_REJECTED,
    ExecutionType.EXECUTION_TYPE_EXPIRED,
];

export function updateCompletedOrders(orders) {
  let completedOrders = [];
  orders.forEach((order) => {
    const ordItem = new Order(order);
    const type = ordItem.status.state.id;
    if (executionTypes.includes(type)) {
      completedOrders.push(ordItem);
    }
  });
  return {
    type: UPDATE_COMPLETED_ORDERS,
    orders: completedOrders
  };
}

export function loadCompletedOrders() {
  let time = new Date();
  time.setHours(-24, 0, 0, 0);
  return (dispatch) => {
    return OrderService.searchHistory((order) => {
      const orderItem = order.getOrder();
      dispatch(updateCompletedOrders([orderItem]));
    }, null, time, executionTypes);
  };
}