import React from 'react'
import { connect } from 'react-redux';
import Dialog from "./core/dialog/Dialog";
import PropTypes from 'prop-types';
import { hidePosition } from '../actions/trader';
import { EnhancedPositionDetails } from './book/PositionDetails';

const ViewPosition = ({
    show,
    onClose,
    accounts,
    position,
}) => {
    return (
        <Dialog title="Position Details" show={show} onClose={onClose}>
            <EnhancedPositionDetails position={position} accounts={accounts}/>
        </Dialog>
    )
}

ViewPosition.propTypes = {
    show: PropTypes.bool,
    position: PropTypes.object,
    accounts: PropTypes.object,
    onClose: PropTypes.func,
}

const mapStateToProps = (state) => {
    const {trader} = state
    const {showPosition} = trader

    if (!showPosition) {
        return {
            show: false,
        }
    }

    const {symbol, account} = showPosition

    let position = trader.positions.find( p => p.symbol === symbol && p.account === account)
    return {
        show: position !== undefined,
        position
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(hidePosition())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewPosition)