import React from "react";
import { connect } from "react-redux";
import { Field, ErrorMessage, withFormik } from "formik";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import {
  getEnumName,
  getFirmUserName,
  capitalizeWord,
  validScaledPrice, convertScaledPriceToInt, parsePrice,
} from "../../../modules/util";
import { Button } from "react-bootstrap";
import ProtobufParser from "../../../modules/protobufParser";
import { validateAttributes } from "./RequestForQuoteOrderForm";
import i18n from 'i18next'
import Quote from "../../../../src/entities/Quote";
import Notification from "../../../modules/notifications";
import {createUserMap} from "../../book/OrderTicketForm";
import AccountSelector from "../../core/select/AccountSelector";

const mapStateToProps = (state) => {
  return {
    accounts: state.trader.accounts,
    users: state.trader.users,
    usersToAccounts: state.trader.usersToAccounts,
    firms: state.trader.firms,
    firmsToUsers: state.trader.firmsToUsers,
    trader: state.trader.trader,
    dealers: state.quotes.dealers,
    requestForQuote: state.quotes.appContext.id
      ? state.quotes.requestForQuotes[state.quotes.appContext.id]
      : {},
    instruments: state.trader.instruments,
    symbol: state.quotes.appContext.symbol,
  };
};

const QuoteOrderTicketForm = (props) => {
  const { accounts, users, usersToAccounts, firms, firmsToUsers, errors, handleSubmit, onCancel, requestForQuote } = props;
  const dealerslist = () => {
      return props.dealers.map((x)=> x.label).join(", ")
  }

  const createSideOptions = () => {
    let items = [];
    for (const sideName in Side) {
      const sideValue = Side[sideName];
      if (sideValue !== Side.SIDE_UNDEFINED) {
        const prettySideName = i18n.t(getEnumName(Side, sideValue).toLowerCase(), capitalizeWord(getEnumName(Side, sideValue)));
        items.push(
          <option key={sideValue} value={sideValue} selected={sideValue === props.values.side}>
            {prettySideName}
          </option>
        );
      }
    }
    return items;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="id">
            Request For Quote
          </label>
          <div key="id">
            RFQ ID: <b>{requestForQuote["id"].toString()}</b>
          </div>
          <div key="createdOn">
            RFQ Creation Time: <b>{requestForQuote["createdOn"].toString()}</b>
          </div>
          <div key="expiredOn">
            RFQ Expiration Time:{" "}
            <b>{requestForQuote["expiredOn"].toString()}</b>
          </div>
          <div key="sideName">
            Side: <b>{i18n.t(requestForQuote["sideName"].toString().toLowerCase(), requestForQuote["sideName"].toString())}</b>
          </div>
          <div key="quantity">
            {i18n.t("quantity")}: <b>{parsePrice(requestForQuote["quantity"], props.values.qtyScale)}</b>
          </div>
          <div key="symbol">
            Security Symbol: <b>{requestForQuote["symbol"].toString()}</b>
          </div>
          <div key="account">
            Account: <b>{ accounts.get(requestForQuote["account"].toString())}</b>
          </div>
          <div key="user">
            User: <b>{ getFirmUserName(requestForQuote["user"], "users") }</b>
          </div>
          <div key="firmsList">
            RFQ Dealers: <b>{dealerslist()}</b>
          </div>
          <div key="statusName">
            RFQ Status: <b>{requestForQuote["statusName"].toString()}</b>
          </div>
          <div key="firm">
            Firm: <b>{ getFirmUserName(requestForQuote["firm"], "firms") }</b>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="account">
            {!users || users.size <= 1 ? "Account" : "On Behalf Of"}
          </label>
          <AccountSelector
              id="account"
              name="account"
              users={users}
              usersToAccounts={usersToAccounts}
              firms={firms}
              firmsToUsers={firmsToUsers}
              accounts={accounts}
              account={props.values.account}
              user={props.values.user}
              userAccountCb={(user, account) => {
                props.setFieldValue('account', account);
                props.setFieldValue('user', user);
              }}
          >
          </AccountSelector>
          <ErrorMessage
            name="account"
            component="div"
            className="form-input-error"
          />
        </div>
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="side">
            Side
          </label>
          <Field
            component="select"
            id="side"
            name="side"
            placeholder="Side"
            className={
              errors.side
                ? `text-input error form-control`
                : `text-input form-control`
            }
            disabled={!props.values.bothSides}
          >
            {createSideOptions()}
          </Field>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="quantity">
            {i18n.t("quantity")}
          </label>
          <Field
            id="quantity"
            name="quantity"
            placeholder={i18n.t("quantity")}
            type="text"
            className={
              errors.quantity
                ? `text-input error form-control`
                : `text-input form-control`
            }
          />
          <ErrorMessage
            name="quantity"
            component="div"
            className="form-input-error"
          />
        </div>
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="expirationTime">
            Expiration Time
          </label>
          <Field
            id="expirationTime"
            name="expirationTime"
            placeholder="Seconds"
            type="text"
            className={
              errors.expirationTime
                ? `text-input error form-control`
                : `text-input form-control`
            }
          />
          <ErrorMessage
            name="expirationTime"
            component="div"
            className="form-input-error"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-control-sm" htmlFor="price">
            {i18n.t("price")}
          </label>
          <Field
            id="price"
            name="price"
            placeholder={i18n.t("price")}
            type="text"
            className={
              errors.price
                ? `text-input error form-control`
                : `text-input form-control`
            }
          />
          <ErrorMessage
            name="price"
            component="div"
            className="form-input-error"
          />
        </div>
        <div className="form-group col"></div>
      </div>

      <div className="form-row">
        <div className="col text-right">
          <Button variant={"outline-success"} type="submit">
            Submit
          </Button>
        </div>
        <div className="col-2" />
        <div className="col">
          <Button variant={"outline-danger"} onClick={() => onCancel()}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

const formatValues = (values, props) => {
  let { expirationTime } = values;

  let expirationInDate = new Date();
  expirationInDate.setSeconds(
    expirationInDate.getSeconds() + parseInt(expirationTime)
  );
  return {
    expirationTime: ProtobufParser.toTimestamp(expirationInDate),
    firm: props.trader.firm,
    account: values.account,
    quantity: convertScaledPriceToInt(values.quantity, values.qtyScale),
    price: values.price,
    priceScale: values.priceScale,
    side: values.side,
    user: values.user,
    symbol: props.requestForQuote.symbol,
    name: props.requestForQuote.name,
    clientId: props.requestForQuote.clientId,
  };
};

const EnhancedQuoteOrderTicketForm = withFormik({
  mapPropsToValues: (props) => {
    const selectedAccount = props.accounts ? props.accounts.keys().next().value : "";
    const selectDefaultUser = !!selectedAccount && (!props.users || props.users.size <= 1);
    const qtyScale = props.instruments && props.instruments[props.symbol] ? props.instruments[props.symbol].qtyScale : 1;
    return {
    account: selectedAccount,
    user: selectDefaultUser ? createUserMap(props.users, props.usersToAccounts, selectedAccount).keys().next().value : "",
    side: props.requestForQuote && props.requestForQuote.sideId === 0 ? 1 : props.requestForQuote.sideId,
    quantity: props.requestForQuote ? parsePrice(props.requestForQuote.quantity, qtyScale) : null,
    priceScale: props.instruments && props.instruments[props.symbol] ? props.instruments[props.symbol].scale : 1,
    qtyScale: qtyScale,
    bothSides: props.requestForQuote && props.requestForQuote.sideId === 0,
    minimumTradeQty: props.instruments[props.requestForQuote.symbol].minimumTradeQty,
    requestForQuote: props.requestForQuote,
    price: props.price,
    expirationTime: props.expirationTime
  }},
  handleSubmit: (values, { props }) => {
    if (Quote.isExpiredOrRejectedOrDeleted(props.requestForQuote.statusName)) {
      Notification.error(`This Request for Quote is ${props.requestForQuote.statusName}`)
      return;
    }

    props.doSubmit(formatValues(values, props));
  },
  validate: (values) => {
    let errors = validateAttributes(values);
    if (!values.price) {
      errors.price = `${i18n.t("price")} must be provided for this quote`;
    } else if (!validScaledPrice(values.price, values.priceScale)) {
      errors.price = `${i18n.t("price")} scale is invalid`;
    }

    if (!values.quantity || Number.isNaN(parseFloat(values.quantity)) || !values.requestForQuote) {
      errors.quantity = `${i18n.t("quantity")} is required`;
    } else if (parseFloat(values.quantity) <= 0) {
      errors.quantity = `${i18n.t("quantity")} must be > 0`;
    } else if (!validScaledPrice(values.quantity, values.qtyScale)) {
      errors.quantity = `${i18n.t("quantity")} has too many decimal places`;
    } else if (convertScaledPriceToInt(values.quantity, values.qtyScale) % values.minimumTradeQty > 0) {
      errors.quantity = `${i18n.t("quantity")} must be multiple of ${parsePrice(values.minimumTradeQty, values.qtyScale)}`;
    }
    return errors;
  },
  displayName: "QuoteOrderTicketForm", // helps with React DevTools
})(QuoteOrderTicketForm);

export default connect(mapStateToProps, null)(EnhancedQuoteOrderTicketForm);
