import React from "react";
import { Route, Switch } from "react-router";

import PublicRoute from "./components/shared/PublicRoute";
import PrivateRoute from "./components/shared/PrivateRoute";
import AdminWrapper from "./components/shared/AdminWrapper";
import DefaultWrapper from "./components/shared/DefaultWrapper";

import Login from "./pages/Login";
import Trader from "./pages/Trader";
import NoMatch from "./pages/NoMatch";
import Quotes from "./pages/Quotes";

const wrapComponent = (Component, Wrapper) => {
  return class Wrapped extends React.Component {
    render() {
      return (
        <Wrapper {...this.props}>
          <Component {...this.props} />
        </Wrapper>
      );
    }
  };
};

const loginComponent = wrapComponent(Login, DefaultWrapper);
const traderComponent = wrapComponent(Trader, AdminWrapper);
const quotesComponent = wrapComponent(Quotes, AdminWrapper);
const noMatchComponent = wrapComponent(NoMatch, DefaultWrapper);

const Routes = () => {
  return (
    <Switch>
      {/* non-authenticated routes */}
      <PublicRoute
        exact
        path="/"
        authRedirectTo="/trader"
        component={loginComponent}
      />

      {/* normal user routes */}
      <PrivateRoute path="/trader" exact component={traderComponent} />
      <PrivateRoute path="/quotes" exact component={quotesComponent} />

      {/* Four oh Four */}
      <Route component={noMatchComponent} />
    </Switch>
  );
};

export default Routes;
