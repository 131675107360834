import moment from 'moment';
import _ from "lodash";
import {Env} from "../constants/environment";

export const isValidHttpUrl = (urlStr) => {
  let url;

  try {
    url = new URL(urlStr);
  } catch (_) {
    return false;
  }

  return url.protocol.startsWith("http");
}

export const apiUrl = () => {
  const envUrl = Env.getEnv("REACT_APP_API_URL");
  return isValidHttpUrl(envUrl) ? envUrl : window.location.origin.replace(/\/$/, '') + envUrl;
}

export const isOnline = () => {
  return navigator.onLine;
}

export const showError = error => {
  alert(`WHOOPS! ${error}`);
}

const enumCommonPrefixIndex = new Map();

export const getEnumName = (enumeration, value) => {
  if (!enumCommonPrefixIndex.has(enumeration)) {
    const keys = _.clone(Object.keys(enumeration)).sort();
    const a1 = keys[0], a2 = keys[keys.length-1], L = a1.length;
    let i = 0;
    while(i<L && a1.charAt(i)=== a2.charAt(i)) i++;
    enumCommonPrefixIndex.set(enumeration, i);
  }
  const enumString = Object.keys(enumeration).find(k => enumeration[k] === value);
  if (!enumString) {
    return !!value ? value.toString() : "";
  }
  const idxStart = enumCommonPrefixIndex.get(enumeration);
  return enumString.substring(idxStart).replace(/_/g, ' ');
}

export const capitalizeWord = (word) => {
  word = word.toLowerCase();
  return word.replace(/^\w/, c => c.toUpperCase());
}

export const convertDateToString = (date, format) => {
  return moment(date).format(format);
}

export const parsePrice = (number, scale) => {
  const decimalPlaces = scaleDecimals(scale);
  return (parseFloat(number) / parseFloat(scale && scale !== 0 ? scale : 1)).toFixed(decimalPlaces);
}

export const validScaledPrice = (value, scale) => {
  if (Number.isNaN(parseFloat(value)) || !Number.isFinite(parseFloat(value))) {
    return false;
  }
  const valueDecs = countDecimals(value);
  const scaleDecs = scaleDecimals(scale);
  return valueDecs <= scaleDecs;
}

export const convertScaledPriceToInt = (value, scale) => {
  let scaled = parseFloat(value) * parseFloat(scale > 1 ? scale : 1);
  return Math.round(scaled);
}

export const countDecimals = (value) => {
  if(Math.floor(value) === value) return 0;
  let str = value.toString();
  if (str.includes('e')) {
    str = value.toFixed(10).replace(/\.?0+$/,"")
  }
  return str.includes(".") ? str.split(".")[1].length : 0;
}

export const scaleDecimals = (scale) => {
  let decimalPlaces;
  let temp = scale;
  if (temp < 1) {
    temp = 1;
  }
  for(decimalPlaces = 0; temp % 10 === 0; temp /= 10) {
    decimalPlaces++;
  }
  return decimalPlaces
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function validatePrice(price)
{
   return !isNaN(parseFloat(price));

}
export const isNullOrUndefined = (value) => {
  return (value === null || value === undefined );
}

export const getFirmUserName = (value, name) => {
  let exp = name+ '\\/(.*)';
  let arr = value.match(new RegExp(exp));
  if (arr && arr[1]){
    return arr[1]
  }
  return value;
}
