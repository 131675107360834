import {
    SAVE_NOTIFICATION,
    MOVE_NOTIFICATION,
    CLEAR_ALL_NOTIFICATION,
    SHOW_NOTIFICATIONS,
    HIDE_NOTIFICATIONS
} from '../constants/actions'

export function saveNotification(message, type) {
    return (dispatch) => {
        let notification = {
            message,
            type,
            timestamp: Date.now()
        }
        dispatch({
            type: SAVE_NOTIFICATION,
            payload: notification
        })
    }
}

export function moveNotification() {
    return (dispatch) => {
        dispatch({
            type: MOVE_NOTIFICATION,
            payload: {}
        })
    }
}

export function clearNotification() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_NOTIFICATION
        })
    }
}

export function showNotifications() {
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTIFICATIONS
        })
    }
}

export function hideNotifications() {
    return (dispatch) => {
        dispatch({
            type: HIDE_NOTIFICATIONS
        })
    }
}