import { getEnumName, capitalizeWord } from "../modules/util";

import { QuoteStatus } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/quotes/v1beta1/quotes_pb";
import { Side } from "@connamara-tech/ep3-domain/web/src/api/connamara/ep3/orders/v1beta1/orders_pb";
import ProtobufParser from "../modules/protobufParser";

export default class Quote {
  static get(item) {
    const object = item.toObject();
    return {
      account: object.account,
      clientId: object.clientId,
      clordId: object.clordId,
      expiredOn: ProtobufParser.tsProtoToDate(item.getExpirationTime()),
      firm: object.firm,
      id: object.id,
      createdOn: ProtobufParser.tsProtoToDate(item.getInsertTime()),
      name: object.name,
      orderQty: object.orderQty,
      price: object.price,
      quoteRequestId: object.quoteRequestId,
      sideId: object.side,
      sideName: capitalizeWord(getEnumName(Side, object.side)),
      statusId: object.status,
      statusName: capitalizeWord(getEnumName(QuoteStatus, object.status)),
      symbol: object.symbol,
      user: object.user,
    };
  }

  static isOpen(stateName) {
    stateName = stateName ? stateName.toLowerCase() : stateName;
    let openStates = [
      getEnumName(
        QuoteStatus,
        QuoteStatus.QUOTE_STATUS_UNDEFINED
      ).toLowerCase(),
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_PENDING).toLowerCase(),
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_PASSED).toLowerCase(),
      getEnumName(
        QuoteStatus,
        QuoteStatus.QUOTE_STATUS_PENDING_RISK
      ).toLowerCase(),
    ];

    return openStates.includes(stateName);
  }

  static isExpiredOrRejectedOrDeleted(stateName) {
    stateName = stateName ? stateName.toLowerCase() : stateName;

    let states = [
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_REJECTED).toLowerCase(),
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_DELETED).toLowerCase(),
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_EXPIRED).toLowerCase(),
      getEnumName(QuoteStatus, QuoteStatus.QUOTE_STATUS_ACCEPTED).toLowerCase()
    ];

    return states.includes(stateName);
  }
}
