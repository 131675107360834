import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import reducers from "./reducers";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })
    : compose;

// configure persistance
export const watchListConfig = {
  key: "watchList",
  storage,
  blacklist: ["watchListData"],
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["trader", "quotes", "watchList"],
};
export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, reducers(history));

// configure store
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(ReduxThunk, routerMiddleware(history)))
);
