import React from "react";
import { connect } from "react-redux";
import { convertDateToString, parsePrice, validatePrice } from "../../modules/util";

const OrderExecutionDetails = ({ execution, accounts, scale }) => {
  if (!execution || !accounts) {
    return "No details found.";
  }

  const acct = execution.account;
  const account = accounts && accounts.has(acct) ? accounts.get(acct) : acct
  return (
    <div className="PositionDetail">
      <form>
        <div className="form-row">
          <div className="form-group col">
            <label>Account</label>
            <div>{account}</div>
          </div>

          <div className="form-group col">
            <label>Symbol</label>
            <div>{execution.symbol}</div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label>Type</label>
            <div>{execution.orderType.name}</div>
          </div>

          <div className="form-group col">
            <label>Side</label>
            <div>{execution.side.name}</div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label>Fill Qty</label>
            <div>{execution.lastShares}</div>
          </div>

          <div className="form-group col">
            <label>Fill Px</label>
            <div>{validatePrice(execution.lastPx) ? parsePrice(execution.lastPx, scale[execution.symbol]) : null}</div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group col">
            <label>Order Px</label>
            <div>{validatePrice(execution.orderPx) ? parsePrice(execution.orderPx, scale[execution.symbol]) : null}</div>
          </div>

          <div className="form-group col">
            <label>Order Id</label>
            <div>{execution.orderId}</div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group col">
            <label>Order Status</label>
            <div>{execution.execType.name}</div>
          </div>

          <div className="form-group col">
            <label>Text</label>
            <div>{execution.isReject && execution.text}</div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group col">
            <label>All or None</label>
            <div><input type="checkbox" checked={execution.allOrNone && "checked"} disabled />
            </div>
          </div>

          <div className="form-group col">
            <label>Block Trade</label>
            <div><input type="checkbox" checked={execution.blockTradeIndicator && "checked"} disabled />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label>Transact Time</label>
            <div>{convertDateToString(execution.transactTime, "YYYYMMDD-HH:mm:ss.SSS")}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { trader } = state;
  const { scale } = trader;
  return { scale };
}


export default connect(mapStateToProps, null)(OrderExecutionDetails);
